import React, { useState, useEffect, useRef } from 'react';
import { Info } from 'lucide-react';
import styles from '../styles/EvaluationTooltip.module.css';

const EvaluationTooltip = () => {
  const [isOpen, setIsOpen] = useState(false);
  const tooltipRef = useRef(null);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (tooltipRef.current && 
          !tooltipRef.current.contains(event.target) && 
          isOpen) {
        setIsOpen(false);
      }
    };

    if (isMobile && isOpen) {
      document.addEventListener('mousedown', handleClickOutside, true);
      document.addEventListener('touchstart', handleClickOutside, true);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside, true);
      document.removeEventListener('touchstart', handleClickOutside, true);
    };
  }, [isMobile, isOpen]);

  const handleTooltipToggle = (e) => {
    if (isMobile) {
      e.stopPropagation();
      setIsOpen(!isOpen);
    }
  };

  return (
    <div className={styles.tooltipContainer} ref={tooltipRef}>
      <div 
        className={styles.tooltipTrigger}
        onClick={handleTooltipToggle}
      >
        <Info size={14} />
        <span className={styles.tooltipText}>how does this work?</span>
      </div>
      
      {isMobile && isOpen && (
        <div 
          className={styles.tooltipBackdrop}
          onClick={() => setIsOpen(false)}
        />
      )}
      
      <div 
        className={styles.tooltipContent}
        style={{ 
          display: isMobile ? (isOpen ? 'block' : 'none') : undefined 
        }}
      >
        <h4>Evaluation Criteria</h4>
        <div className={styles.scoreSection}>
          <div className={styles.scoreRange}>
            <span className={styles.scoreLabel}>1-3</span>
            <span className={styles.scoreDesc}>weak argument</span>
          </div>
          <div className={styles.scoreRange}>
            <span className={styles.scoreLabel}>4-6</span>
            <span className={styles.scoreDesc}>solid point</span>
          </div>
          <div className={styles.scoreRange}>
            <span className={styles.scoreLabel}>7-9</span>
            <span className={styles.scoreDesc}>strong argument</span>
          </div>
          <div className={styles.scoreRange}>
            <span className={styles.scoreLabel}>10+</span>
            <span className={styles.scoreDesc}>game-changing point</span>
          </div>
        </div>
        
        <div className={styles.divider} />
        
        <div className={styles.criteriaSection}>
          <p>Arguments are scored on:</p>
          <ul>
            <li>Logical strength</li>
            <li>Evidence quality</li>
            <li>Rebuttal effectiveness</li>
            <li>Overall impact</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default EvaluationTooltip;