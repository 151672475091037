import React, { useState } from 'react';
import styles from '../styles/DeleteAccountSection.module.css';

const DeleteAccountSection = ({ username, onDeleteAccount }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmUsername, setConfirmUsername] = useState('');
  const [error, setError] = useState('');

  const handleDelete = () => {
    if (confirmUsername !== username) {
      setError('Username does not match');
      return;
    }
    onDeleteAccount();
    setIsModalOpen(false);
  };

  const handleClose = () => {
    setIsModalOpen(false);
    setConfirmUsername('');
    setError('');
  };

  return (
    <div className={styles.deleteAccountContainer}>
      <button
        onClick={() => setIsModalOpen(true)}
        className={styles.deleteButton}
      >
        Delete Account
      </button>

      {isModalOpen && (
        <div className={styles.modalOverlay}>
          <div className={styles.modalContent}>
            <div className={styles.modalHeader}>
              <h2>Delete Account</h2>
              <button 
                onClick={handleClose}
                className={styles.closeButton}
              >
                ×
              </button>
            </div>
            
            <div className={styles.modalBody}>
              <p>This action cannot be undone. All data associated with your account will be permanently deleted, including:</p>
              <ul>
                <li>Your profile information</li>
                <li>All your debates and responses</li>
                <li>Your friend connections</li>
                <li>All other account data</li>
              </ul>
              
              <div className={styles.confirmSection}>
                <p>Please type <strong>{username}</strong> to confirm deletion:</p>
                <input
                  type="text"
                  value={confirmUsername}
                  onChange={(e) => {
                    setConfirmUsername(e.target.value);
                    setError('');
                  }}
                  placeholder="Enter your username"
                  className={styles.confirmInput}
                />
                {error && (
                  <p className={styles.error}>{error}</p>
                )}
              </div>
            </div>

            <div className={styles.modalFooter}>
              <button
                onClick={handleClose}
                className={styles.cancelButton}
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                className={styles.confirmDeleteButton}
              >
                Delete Account
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DeleteAccountSection;