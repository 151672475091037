import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { supabase } from "../queries/Queries";
import TopMenuBar from "../components/TopMenuBar";
import RoomShareModal from "../components/RoomShareModal";
import DebateRoomArena from "../components/DebateRoomArena";
import styles from "../styles/DebateRoom.module.css";
import { getCurrentUserAndProfile } from "../queries/Queries";
import {
  joinDebateRoomWithLink,
  updateRoomToInProgress,
  deleteDebateRoom
} from "../queries/roomQueries";

const DebateRoom = () => {
  const { roomId } = useParams();
  const navigate = useNavigate();
  const arenaRef = useRef(null);
  const chatBoxRef = useRef(null);
  const channelRef = useRef(null);

  // State Management
  const [room, setRoom] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isCreator, setIsCreator] = useState(false);
  const [error, setError] = useState(null);
  const [debateStarted, setDebateStarted] = useState(false);
  const [showRoomShareModal, setShowRoomShareModal] = useState(true);

  // Helper function for sides
  const getUserSideInfo = (room, userId) => {
    if (!room || !userId) return {};

    const isCreatorUser = userId === room.creator_id;
    const userSide = isCreatorUser
      ? room.creator_side
      : room.creator_side === "For"
      ? "Against"
      : "For";
    const opponentSide = userSide === "For" ? "Against" : "For";

    const forSidePlayer = {
      username:
        room.creator_side === "For"
          ? room.creator?.username
          : room.opponent?.username,
      isUser: userSide === "For",
      ready:
        room.creator_side === "For" ? room.creator_ready : room.opponent_ready,
      id: room.creator_side === "For" ? room.creator_id : room.opponent_id,
    };

    const againstSidePlayer = {
      username:
        room.creator_side === "Against"
          ? room.creator?.username
          : room.opponent?.username,
      isUser: userSide === "Against",
      ready:
        room.creator_side === "Against"
          ? room.creator_ready
          : room.opponent_ready,
      id: room.creator_side === "Against" ? room.creator_id : room.opponent_id,
    };

    return {
      userSide,
      opponentSide,
      forSidePlayer,
      againstSidePlayer,
    };
  };

  const handleBothPlayersReady = async (currentUserId, creatorId) => {
    console.log("handleBothPlayersReady is called");

    // Check if current user is creator
    const isCurrentUserCreator = currentUserId === creatorId;
    console.log("Current user is creator:", isCurrentUserCreator);

    if (!isCurrentUserCreator) {
      console.log("Not the creator, skipping debate start");
      return;
    }

    const turnDeadline = new Date();
    turnDeadline.setSeconds(turnDeadline.getSeconds() + 60);

    try {
      console.log("Calling start_debate RPC...");
      const { data, error } = await supabase.rpc("start_debate", {
        room_id: roomId,
        turn_deadline: turnDeadline.toISOString(),
      });

      if (error) {
        console.error("Error starting debate:", error);
      } else {
        console.log("Debate started successfully");
      }
    } catch (error) {
      console.error("Exception in handleBothPlayersReady:", error);
    }
  };

  const roomShareModalClose = () => {
    setShowRoomShareModal(false);
    deleteDebateRoom(roomId)
    navigate('/challenge')
  }

  const roomShareModalJoined = () => {
    setShowRoomShareModal(false);
  }

  //we add a mobile web check on launch day and if mobile return before it executes rest of function 

  // Handle debate started state based on room status
  useEffect(() => {
    //room status changes check if mobile first 
    if (room?.status === "in_progress") {
      setDebateStarted(true);
      setShowRoomShareModal(false);
    }
  }, [room?.status]);

  // Initialize room and setup subscriptions
  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      try {
        // Get user profile
        const userResult = await getCurrentUserAndProfile();
        if (!userResult) {
          navigate("/login");
          return;
        }
        setUserAndProfile(userResult);

        // Fetch initial room details
        const { data: roomData, error: roomError } = await supabase
          .from("debate_rooms")
          .select(
            `
            *,
            creator:creator_id(username),
            opponent:opponent_id(username)
          `
          )
          .eq("id", roomId)
          .single();

        if (roomError) throw roomError;
        if (!roomData) throw new Error("Room not found");

        // Join room if user is not creator
        if (userResult.user.id !== roomData.creator_id) {
          const { data: roomLinkData, error: roomLinkError } =
            await joinDebateRoomWithLink(roomId);

          if (roomLinkError) throw roomLinkError;
          if (!roomLinkData) throw new Error("Room not found");

          // Verify user is part of this debate
          if (
            roomLinkData.creator_id !== userResult.user.id &&
            roomLinkData.opponent_id !== userResult.user.id
          ) {
            throw new Error("You are not authorized to view this debate");
          }
          setRoom(roomLinkData);
        } else {
          if (
            roomData.creator_id !== userResult.user.id &&
            roomData.opponent_id !== userResult.user.id
          ) {
            throw new Error("You are not authorized to view this debate");
          }
          setRoom(roomData);
        }

        setIsCreator(userResult.user.id === roomData.creator_id);

        // Initialize debate state
        if (roomData.status === "in_progress") {
          setDebateStarted(true);
          setShowRoomShareModal(false);
        }

        // Set up real-time subscription
        channelRef.current = supabase
          .channel(`room:${roomId}`)
          .on(
            "postgres_changes",
            {
              event: "*",
              schema: "public",
              table: "debate_rooms",
              filter: `id=eq.${roomId}`,
            },
            async (payload) => {
              console.log("Received room update:", payload);

              const {
                data: { session },
              } = await supabase.auth.getSession();
              if (!session) {
                console.log("No active session, skipping update");
                return;
              }

              const { data } = await supabase
                .from("debate_rooms")
                .select(
                  `
          *,
          creator:creator_id(username),
          opponent:opponent_id(username)
        `
                )
                .eq("id", roomId)
                .single();

              if (data) {
                console.log("Updated room data:", data);
                setRoom(data);

                // Split the logic - handle game start separately from state updates
                if (data.status === "ready_phase") {
                  console.log("Room in ready phase");
                  console.log("Creator ready:", data.creator_ready);
                  console.log("Opponent ready:", data.opponent_ready);
                  console.log("Current user ID:", session.user.id);
                  console.log("Creator ID:", data.creator_id);

                  if (data.creator_ready && data.opponent_ready) {
                    console.log("Both players ready, checking if creator...");
                    await handleBothPlayersReady(
                      session.user.id,
                      data.creator_id
                    );
                  }
                }

                // Handle the successful transition to in_progress
                if (data.status === "in_progress") {
                  console.log("Debate is now in progress");
                  setDebateStarted(true);
                  setShowRoomShareModal(false);
                }
              }
            }
          )
          .subscribe();
      } catch (error) {
        console.error("Error initializing debate room:", error);
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();

    return () => {
      if (channelRef.current) {
        console.log("Unsubscribing from room updates");
        channelRef.current.unsubscribe();
      }
    };
  }, [roomId, navigate]);

  // Loading state
  if (isLoading) {
    return (
      <div className={styles.container}>
        <TopMenuBar userAndProfile={userAndProfile} />
        <div className={styles.loading}>Loading debate room...</div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className={styles.container}>
        <TopMenuBar userAndProfile={userAndProfile} />
        <div className={styles.error}>
          <h2>Error</h2>
          <p>{error}</p>
          <button
            className={styles.backButton}
            onClick={() => navigate("/rooms")}
          >
            Back to Rooms
          </button>
        </div>
      </div>
    );
  }

  const playerInfo = getUserSideInfo(room, userAndProfile?.user?.id);

  return (
    <div className={styles.container}>
      <TopMenuBar userAndProfile={userAndProfile} />

      {/* Share Modal */}
      {!debateStarted && room && showRoomShareModal && (
        <RoomShareModal
          isOpen={true}
          room={room}
          onJoin={roomShareModalJoined}
          onClose={roomShareModalClose}
          isCreator={isCreator}
          {...playerInfo}
        />
      )}

      {/* Arena - handles both ready check and debate */}
      {room && room.opponent && (
        <DebateRoomArena
          arenaRef={arenaRef}
          chatBoxRef={chatBoxRef}
          room={room}
          userAndProfile={userAndProfile}
          isCreator={isCreator}
          onDebateStart={() => setDebateStarted(true)}
          {...playerInfo}
        />
      )}
    </div>
  );
};

export default DebateRoom;