import React, { useState, useEffect, useRef } from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import styles from '../styles/PaginationControls.module.css';

const PaginationControls = ({ currentPage, totalPages, onPageChange }) => {
  const [visiblePages, setVisiblePages] = useState(7);
  const containerRef = useRef(null);
  
  useEffect(() => {
    const calculateVisiblePages = () => {
      if (!containerRef.current) return;
      const containerWidth = containerRef.current.offsetWidth;
      const buttonWidth = 56;
      const arrowsWidth = 96;
      const availableWidth = containerWidth - arrowsWidth;
      const possibleButtons = Math.floor(availableWidth / buttonWidth);
      const adjustedButtons = Math.max(3, possibleButtons % 2 === 0 ? possibleButtons - 1 : possibleButtons);
      setVisiblePages(adjustedButtons);
    };

    calculateVisiblePages();
    const resizeObserver = new ResizeObserver(calculateVisiblePages);
    if (containerRef.current) {
      resizeObserver.observe(containerRef.current);
    }

    return () => resizeObserver.disconnect();
  }, []);

  const getPageNumbers = () => {
    const pages = [];
    const halfVisible = Math.floor(visiblePages / 2);
    let startPage = Math.max(1, currentPage - halfVisible);
    let endPage = Math.min(startPage + visiblePages - 1, totalPages);
    
    if (endPage - startPage + 1 < visiblePages && startPage > 1) {
      startPage = Math.max(1, endPage - visiblePages + 1);
    }
    
    for (let i = startPage; i <= endPage; i++) {
      pages.push(i);
    }
    return pages;
  };

  return (
    <div ref={containerRef} className={styles.container}>
      <button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={currentPage === 1}
        className={styles.arrowButton}
      >
        <ChevronLeft size={20} />
      </button>
      
      <div className={styles.pageNumbers}>
        {getPageNumbers().map((pageNum) => (
          <button
            key={pageNum}
            onClick={() => onPageChange(pageNum)}
            className={`${styles.pageButton} ${
              currentPage === pageNum ? styles.activePage : ''
            }`}
          >
            {pageNum}
          </button>
        ))}
      </div>

      <button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        className={styles.arrowButton}
      >
        <ChevronRight size={20} />
      </button>
    </div>
  );
};

export default PaginationControls;