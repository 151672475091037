import React from 'react';
import { useEffect, useState } from 'react';

const DebateConfetti = () => {
  const [pieces] = useState(Array.from({ length: 100 }, (_, i) => i));
  const [isActive, setIsActive] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsActive(false);
    }, 4000);

    return () => clearTimeout(timer);
  }, []);

  if (!isActive) return null;

  return (
    <div className="fixed inset-0 pointer-events-none z-[9999]">
      {pieces.map((piece) => (
        <div
          key={piece}
          className="absolute animate-confetti"
          style={{
            left: `${Math.random() * 100}vw`,
            top: `-20px`,
            backgroundColor: ['#FFD700', '#FF69B4', '#4169E1', '#32CD32', '#FF4500'][
              Math.floor(Math.random() * 5)
            ],
            width: `${Math.random() * 10 + 5}px`,
            height: `${Math.random() * 10 + 5}px`,
            transform: `rotate(${Math.random() * 360}deg)`,
            animationDelay: `${Math.random() * 2}s`,
            animationDuration: `${Math.random() * 3 + 2}s`
          }}
        />
      ))}
      <style>
        {`
          @keyframes confetti {
            0% {
              transform: translateY(0) rotate(0deg);
              opacity: 1;
            }
            100% {
              transform: translateY(100vh) rotate(720deg);
              opacity: 0;
            }
          }
          .animate-confetti {
            animation: confetti 3s ease-in-out forwards;
          }
        `}
      </style>
    </div>
  );
};

export default DebateConfetti;