import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "../../utils/debounce";
import { modes } from "../../constants/debateModes";
import {
  BLITZ_PROMPT,
  PERSONA_BLITZ_PROMPT,
  STANDARD_PROMPT_WITH_SEARCH,
  STANDARD_PROMPT_WITHOUT_SEARCH,
  DEBATER_SYSTEM_PROMPT,
  DEBATER_USER_PROMPT,
  CROSS_EXAMINER_ROLEPLAY_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_USER_PROMPT,
  CROSS_EXAMINER_ROLEPLAY_USER_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_STARTING_PROMPT
} from "../../constants/prompts";
import "../../styles/AI.module.css";
import SelfSetupPanel from "./SelfSetupPanelAI";
import DebateArena from "../../components/DebateArena";
import DebateConfetti from "../../components/DebateConfetti"
import TopMenuBar from "../../components/TopMenuBar";
import {
  streamChatCompletion,
  transcribeAudio as transcribeAudioAPI,
  generateSearchQueries,
  searchExa,
  processSearchResults,
  evaluateArgument,
  generateCxArg,
  extractCoreArguments,
  findDroppedArguments
} from "../../utils/api";
import {
  extractClaim, extractImpact, extractWarrant
} from "../../utils/crossExamUtils";
import BadResponse from "../../components/BadResponse";
import EvaluationReportCard from "../../components/EvaluationReportCard";
import { getCurrentUserAndProfile, uploadDebate } from "../../queries/Queries";

function SelfAI() {
  const [topic, setTopic] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [side, setSide] = useState("For");
  const [examSide, setExamSide] = useState("Examinee");
  const [debateStarted, setDebateStarted] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [mode, setMode] = useState("Blitz");
  const [currentRound, setCurrentRound] = useState(0);
  const [prepTimeLeft, setPrepTimeLeft] = useState(modes[mode].prepTime);
  const [debateTimeLeft, setDebateTimeLeft] = useState(modes[mode].debateTime);
  const [timerActive, setTimerActive] = useState(false);
  const [turnReady, setTurnReady] = useState(false);
  const [debateConcluded, setDebateConcluded] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState(null);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [allSources, setAllSources] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [showEvaluationReport, setShowEvaluationReport] = useState(false);
  const [evaluationSummary, setEvaluationSummary] = useState("");
  const [globalDebateTime, setGlobalDebateTime] = useState(modes.Blitz.globalTime);
  const [previousArguments, setPreviousArguments] = useState([]);
  const [droppedArgumentsMap, setDroppedArgumentsMap] = useState(new Map());
  
  const globalTimerRef = useRef(null);
  const hasRunAIFirstTurn = useRef(false);
  const mediaRecorderRef = useRef(null);
  const arenaRef = useRef(null);
  const timerRef = useRef(null);
  const chatBoxRef = useRef(null);
  
  const [invalidAttempts, setInvalidAttempts] = useState(0);
  const [showBadResponse, setShowBadResponse] = useState(false);
  const [isValidResponse, setIsValidResponse] = useState(true);
  const [isInvalidated, setInvalidated] = useState(false);
  const [examArg, setExamArg] = useState("");
  const [isUnacceptable, setUnacceptable] = useState(false);
  const [currIsInvalid, setCurrIsInvalid] = useState(false);
  const [crossExaminationRole, setCrossExaminationRole] = useState("Examiner");
  const [divArg, setDivArg] = useState({ claim: "", warrant: "", impact: "" });
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [stringMessages, setStringMessages] = useState("[]");
  const messageCountRef = useRef(0);
  const [currentDebateId, setCurrentDebateId] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (debateConcluded) {}
    const messagesString = JSON.stringify(messages.map(msg => ({
      role: msg.role,
      content: msg.content,
      arguments: msg.arguments,
      droppedArguments: msg.droppedArguments
    })));
    console.log("sending debate from messages and ID is " + currentDebateId)
    setStringMessages(messagesString);
    if (debateStarted && messages.length > 0) {
      messageCountRef.current += 1;
      if (messageCountRef.current === 2) {
        const updateDebate = async () => {
          const result = await uploadDebate(
            currentDebateId,
            mode, 
            messagesString, 
            topic, 
            side, 
            mode === "Cross-Examine" ? examSide : null, 
            selectedPersona, 
            evaluationSummary
          );

          if (result === false) {
            console.error('Failed to send debate update');
          } else if (typeof result === 'number' && !currentDebateId) {
            setCurrentDebateId(result);
          }
        };

        updateDebate();
        messageCountRef.current = 0;
      }
    }
  }, [messages, debateStarted, mode, topic, side, examSide, selectedPersona, evaluationSummary, currentDebateId]);

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleSignOut = () => {
    setUserAndProfile(null);
  };

  useEffect(() => {
    if (currentRound >= modes[mode].rounds.length) {
      setDebateConcluded(true);
      setTimerActive(false);
      setTurnReady(false);
    }
  }, [currentRound, mode]);

  useEffect(() => {
    if (debateStarted && arenaRef.current) {
      const handleAnimationEnd = () => {
        setAnimationFinished(true);
      };
      arenaRef.current.addEventListener("animationend", handleAnimationEnd);

      return () => {
        if (arenaRef.current) {
          arenaRef.current.removeEventListener("animationend", handleAnimationEnd);
        }
      };
    }
  }, [debateStarted]);

  useEffect(() => {
    if (timerActive) {
      timerRef.current = setInterval(() => {
        if (mode === "Blitz" || mode === "Cross-Examine") {
          setGlobalDebateTime((prevTime) => {
            if (prevTime <= 0) {
              clearInterval(timerRef.current);
              setDebateConcluded(true);
              return 0;
            }
            return prevTime - 1;
          });
        }

        if (!turnReady) {
          setPrepTimeLeft((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              setTurnReady(true);
              setDebateTimeLeft(modes[mode].debateTime);
              return 0;
            }
          });
        } else {
          setDebateTimeLeft((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              clearInterval(timerRef.current);
              setAutoSubmit(true);
              return 0;
            }
          });
        }
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [timerActive, turnReady, mode]);

  useEffect(() => {
    if (autoSubmit) {
      handleSubmit(null);
      setAutoSubmit(false);
    }
  }, [autoSubmit]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  const processMessage = async (content, previousMessage) => {
    // Extract core arguments from current message
    const currentArguments = await extractCoreArguments(content);
    
    let droppedArguments = null;
    // Only check for dropped arguments if there's a previous message
    if (previousMessage?.arguments) {
      droppedArguments = await findDroppedArguments(previousMessage.arguments, content);
    }
  
    return {
      content,
      arguments: currentArguments,
      droppedArguments
    };
  };

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        setAudioBlob(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  const transcribeAudio = async () => {
    if (!audioBlob) return;

    try {
      const transcription = await transcribeAudioAPI(audioBlob);
      setInput((prev) => prev + " " + transcription);
      setAudioBlob(null);
    } catch (error) {
      console.error("Error transcribing audio:", error);
    }
  };

  useEffect(() => {
    if (audioBlob) {
      transcribeAudio();
    }
  }, [audioBlob]);

  const handleStartDebate = async () => {
    console.log("handleStartDebate called")
    if (!topic.trim()) {
      alert("Please enter a topic to start the debate.");
      return;
    }

    setDebateStarted(true);
    setDebateConcluded(false);
    setAnimationFinished(false);
    setMessages([]);
    setEvaluationSummary('');
    setEvaluations([]);
    
    let crossExamSide = ""
    let crossExamArg = ""
  
    if (mode === "Cross-Examine") {
      let initialTopic = topic;
      let initialExamArg = "";
      let initialUserRole = side;
      let initialAssistantRole = initialUserRole === "for" ? "against" : "for";
      crossExamSide = initialUserRole;
  
      let roleForArg = ""

      if (examSide === "Examiner") {
        roleForArg = initialAssistantRole;
      }
      else {
        roleForArg = initialUserRole;
      }

      console.log("When we get to handleStartDebate, the userRole is " + side + " and the assistantRole is " + initialAssistantRole)
  
      initialExamArg = await generateCxArg(side, initialTopic);
      console.log("Exam Arg has been set which is " + initialExamArg)
      crossExamArg = initialExamArg;

      setExamArg(initialExamArg);
      console.log(extractClaim(initialExamArg) + extractWarrant(initialExamArg) + extractImpact(initialExamArg))
      setDivArg(prevDivArg =>
      {
        return {
          claim: extractClaim(initialExamArg),
          warrant: extractWarrant(initialExamArg),
          impact: extractImpact(initialExamArg)
        }
      })
    }
  
    if (mode === "Cross-Examine") {
      setGlobalDebateTime(modes["Cross-Examine"].globalTime);
      if (examSide === "Examinee") {
        setTimeout(() => {
          handleAIFirstTurn(crossExamArg, crossExamSide);
        }, modes["Cross-Examine"].prepTime * 1000);
      }
    } 

    else if (mode === "Blitz") {
      setGlobalDebateTime(modes.Blitz.globalTime);
      setTurnReady(true);
    } 
  
    setPrepTimeLeft(modes[mode].prepTime);
    setDebateTimeLeft(modes[mode].debateTime);
    setCurrentRound(0);
    setTimerActive(true);
    setDebateConcluded(false);
    setInvalidAttempts(0);
    setInvalidated(false);
    setUnacceptable(false);
  };

  useEffect(() => {
    if (debateStarted && mode === "Cross-Examine" && examSide === "Examinee" && examArg) {
      hasRunAIFirstTurn.current = true;
    }
  }, [debateStarted, mode, examSide, examArg]);
  
  useEffect(() => {
    if (!debateStarted) {
      hasRunAIFirstTurn.current = false;
    }
  }, [debateStarted]);

  const handleNextRound = () => {
    if (mode === "Standard") {
      setCurrentRound((prev) => prev + 1);
    }
    setTurnReady(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setTimerActive(true);
  };

  const handleEndPrep = debounce(() => {
    setTurnReady(true);
    setTimerActive(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setPrepTimeLeft(0);
  }, 250);

  function randomSide() {
    return Math.random() < 0.5 ? "for" : "against";
  }

  const handleAIFirstTurn = async (arg, userRole) => {
    setIsStreaming(true);
    setTurnReady(false);
    
    console.log("handleAIFirstTurn has started")
    console.log("The user role argument is: " + userRole)

    try {
      const systemPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_PROMPT.replace("{argument}", arg);
      const userPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_STARTING_PROMPT;
  
      const stream = await streamChatCompletion(systemPromptWithContext, userPromptWithContext);

      console.log("So the assistant role argument is " + (userRole === 'for' ? 'against' : 'for'))
  
      let fullResponse = "";
      for await (const chunk of stream) {
        const content = chunk.choices[0].delta.content;
        fullResponse += content;
  
        // Process the AI's response with argument tracking
        const processedAIMessage = await processMessage(fullResponse, null); // null since it's first message
  
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage && lastMessage.role === `assistant-${userRole === 'for' ? 'against' : 'for'}`) {
            return [
              ...prev.slice(0, -1),
              { 
                ...lastMessage, 
                content: fullResponse,
                arguments: processedAIMessage.arguments,
                droppedArguments: processedAIMessage.droppedArguments 
              },
            ];
          } else {
            return [
              ...prev,
              { 
                role: `assistant-${userRole === 'for' ? 'against' : 'for'}`, 
                content: fullResponse,
                arguments: processedAIMessage.arguments,
                droppedArguments: processedAIMessage.droppedArguments 
              },
            ];
          }
        });
      }

      console.log("calling evaluateArgument from handleAIFirst and passing mode")

      let currentMode = mode;

      console.log("mode in handleAIFirst is " + currentMode)

      const aiEvaluation = await evaluateArgument(
        topic,
        currentMode,
        [arg],
        examSide,
        fullResponse,
        side === 'for' ? 'against' : 'for'
      );
      setEvaluations((prev) => [...prev, { ...aiEvaluation, side: side === 'for' ? 'against' : 'for' }]);

      console.log(aiEvaluation)
      console.log("mode after calling evaluateArgument is " + mode)
  
    } catch (error) {
      console.error("Error in AI's first turn:", error);
    } finally {
      setIsStreaming(false);
      setTurnReady(true);
      setTimerActive(true);
      setDebateTimeLeft(modes[mode].debateTime);
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (debateConcluded) return;

    const userRole = side.toLowerCase();
    
    // Process user message with argument tracking
    const previousMessage = messages[messages.length - 1];
    const processedUserMessage = await processMessage(input || "(Skipped Turn)", previousMessage);

    const userMessage = {
      role: `user-${userRole}`,
      content: processedUserMessage.content,
      arguments: processedUserMessage.arguments,
      droppedArguments: processedUserMessage.droppedArguments
    };

    setMessages((prev) => [...prev, userMessage]);
    setInput("");
    setIsStreaming(true);
    setTimerActive(false);
    setTurnReady(false);

    // Evaluate user's argument
    const userEvaluation = await evaluateArgument(
      topic,
      mode,
      messages.slice(-10).map((msg) => msg.content),
      examSide,
      userMessage.content,
      userRole
    );
    setEvaluations((prev) => [...prev, { ...userEvaluation, side: userRole }]);

    // Reset state for next attempt
    setTurnReady(true);
    setTimerActive(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setIsStreaming(false);
    setSide(prevSide => prevSide === "For" ? "Against" : "For");
  };

  const handleEndDebate = () => {
    setDebateConcluded(true);
    if (invalidAttempts === 3) {
      setInvalidated(true);
    }
    setShowConfetti(true);
    setTurnReady(false);
    setTimerActive(false);
    if (globalTimerRef.current) {
      clearInterval(globalTimerRef.current);
    }
    setGlobalDebateTime(0);
    setInput("");
    setPrepTimeLeft(0);
    setDebateTimeLeft(0);
    setShowEvaluationReport(true);
    setCurrentDebateId((prev) => {
      console.log("sending debate from endDebate and ID is " + currentDebateId)
      uploadDebate(
        currentDebateId,
        mode, 
        stringMessages, 
        topic, 
        side, 
        mode === "Cross-Examine" ? examSide : null, 
        selectedPersona, 
        evaluationSummary
      );
      return null;
    });
  };

  return (
    <div className="App">
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      <div className="main-content">
        {!debateStarted && (
          <SelfSetupPanel
            mode={mode}
            setMode={setMode}
            topic={topic}
            setTopic={setTopic}
            side={side}
            setSide={setSide}
            debateStarted={debateStarted}
            handleStartDebate={handleStartDebate}
            setSelectedPersona={setSelectedPersona}
            crossExaminationRole={crossExaminationRole}
            setCrossExaminationRole={setCrossExaminationRole}
            examSide={examSide}
            setExamSide={setExamSide} 
          />
        )}
        {debateStarted && (
          <DebateArena
            arenaRef={arenaRef}
            animationFinished={animationFinished}
            examSide={examSide}
            side={side}
            examArg={examArg}
            topic={topic}
            mode={mode}
            currentRound={currentRound}
            messages={messages}
            chatBoxRef={chatBoxRef}
            turnReady={turnReady}
            debateConcluded={debateConcluded}
            prepTimeLeft={prepTimeLeft}
            debateTimeLeft={debateTimeLeft}
            input={input}
            divArg={divArg}
            setInput={setInput}
            isStreaming={isStreaming}
            isRecording={isRecording}
            handleSubmit={handleSubmit}
            handleEndPrep={handleEndPrep}
            startRecording={startRecording}
            stopRecording={stopRecording}
            handleEndDebate={handleEndDebate}
            allSources={allSources}
            globalDebateTime={globalDebateTime}
            evaluations={evaluations}
            showEvaluationReport={showEvaluationReport}
          />
        )}
        {showBadResponse && (
          <BadResponse
            unacceptable={isUnacceptable}
            invalidated={isInvalidated}
            attemptsLeft={invalidAttempts}
            onClose={() => setShowBadResponse(false)}
          />
        )}
        {showEvaluationReport && (
          <EvaluationReportCard
            evaluations={evaluations}
            topic={topic}
            personaName={selectedPersona ? selectedPersona.name : "AI"}
            onClose={() => {
              setShowEvaluationReport(false);
              setEvaluations([]);
              setEvaluationSummary("");
              setMessages([]);
              setSelectedPersona("");
              setTopic("");
              setDebateStarted(false);
              setAnimationFinished(false);
              setAllSources("");
            }}
            onHide={() => setShowEvaluationReport(false)}
            messages={messages}
            userSide={side}
            summary={evaluationSummary}
            setSummary={setEvaluationSummary}
            id={currentDebateId}
          />
        )}
      </div>
      {showConfetti && <DebateConfetti />}
    </div>
  );
}

export default SelfAI;