import Together from "together-ai";
import axios from "axios";
import {
  GENERATE_DEBATE_TOPIC_PROMPT,
  GENERATE_SEARCH_QUERIES_PROMPT,
  EVALUATOR_SYSTEM_PROMPT,
  EVALUATOR_USER_PROMPT,
  CROSS_EXAM_EVALUATOR_USER_PROMPT,
  DEBATE_SUMMARY_PROMPT,
  CROSS_EXAM_EVALUATOR_SYSTEM_PROMPT,
  CROSS_EXAMINATION_STARTING_ARGUMENT_PROMPT,
  GENERATE_MOTION_SYSTEM_PROMPT,
  GENERATE_MOTION_USER_PROMPT,
  GENERATE_RANDOM_MOTION_USER_PROMPT,
  GENERATE_ACTOR_MOTION_SYSTEM_PROMPT,
  GENERATE_POLICY_MOTION_SYSTEM_PROMPT,
  GENERATE_ANALYSIS_MOTION_SYSTEM_PROMPT,
  GENERATE_MOTION_INFO_USER_PROMPT,
  GENERATE_MOTION_INFO_SYSTEM_PROMPT,
  GENERATE_BP_MOTION,
  GENERATE_SYSTEM_BP_OA,
  GENERATE_USER_BP_OA,
  DEBATE_ROOM_SUMMARY_PROMPT,
  DEBATE_ROOM_SUMMARY_PROMPT_TIE
} from "../constants/prompts";
import {supabase} from "../queries/supabaseClient.js";

const together = new Together({
  apiKey: process.env.REACT_APP_TOGETHER_API_KEY,
});

const GROQ_API_KEY = process.env.REACT_APP_GROQ_API_KEY;

// Track and evaluate dropped arguments between debate exchanges
export const extractCoreArguments = async (text) => {
  const response = await fetch(
    "https://api.groq.com/openai/v1/chat/completions",
    {
      method: "POST",
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_GROQ_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "llama3-70b-8192",
        messages: [
          {
            role: "system",
            content: `Extract the core arguments from the given text. List each argument as a separate line starting with a dash (-).
            Each argument should be a concise phrase representing a distinct point.
            
            Example input: "Free healthcare would improve outcomes and reduce inequality. The costs would be offset by savings."
            Example output:
            - Free healthcare improves health outcomes
            - Free healthcare reduces inequality
            - Costs offset by savings`
          },
          {
            role: "user", 
            content: text
          }
        ],
        temperature: 0.3,
        max_tokens: 500
      })
    }
  );

  const data = await response.json();
  const content = data.choices[0].message.content;
  
  // Extract lines starting with dash and clean them up
  return content
    .split('\n')
    .filter(line => line.trim().startsWith('-'))
    .map(line => line.trim().substring(1).trim());
};

export const findDroppedArguments = async (previousArguments, currentResponse) => {
  const response = await fetch(
    "https://api.groq.com/openai/v1/chat/completions",
    {
      method: "POST", 
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_GROQ_API_KEY}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        model: "llama3-70b-8192",
        messages: [
          {
            role: "system",
            content: `Given a list of previous arguments and a current response, list which arguments have not been addressed.
            An argument is "addressed" if it was directly responded to or acknowledged.
            List each unaddressed argument on a new line starting with a dash (-).
            If all arguments were addressed, respond with "NONE".`
          },
          {
            role: "user",
            content: `Previous arguments:
${previousArguments.map(arg => `- ${arg}`).join('\n')}

Current response:
${currentResponse}`
          }
        ],
        temperature: 0.3,
        max_tokens: 500
      })
    }
  );

  const data = await response.json();
  const content = data.choices[0].message.content;

  // If NONE, return empty array
  if (content.trim().toUpperCase() === 'NONE') {
    return {
      dropped: [],
      count: 0
    };
  }

  // Extract dropped arguments
  const dropped = content
    .split('\n')
    .filter(line => line.trim().startsWith('-'))
    .map(line => line.trim().substring(1).trim());

  return {
    dropped,
    count: dropped.length
  };
};

export const generateDebateSummary = async (
  topic,
  roundByRoundData,
  debateTranscript,
  userSide
) => {
  const prompt = DEBATE_SUMMARY_PROMPT.replace("{topic}", topic)
    .replace("{roundByRoundData}", roundByRoundData)
    .replace("{debateTranscript}", debateTranscript)
    .replace("{userSide}", userSide);

  try {
    const response = await axios.post(
      "https://api.groq.com/openai/v1/chat/completions",
      {
        model: "llama3-70b-8192",
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
        temperature: 0.4,
        max_tokens: 500,
        top_p: 0.9,
      },
      {
        headers: {
          Authorization: `Bearer ${GROQ_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const summary = response.data.choices[0]?.message?.content || "";
    console.log("Generated debate summary:", summary);
    return summary;
  } catch (error) {
    console.error("Error generating debate summary:", error);
    throw error;
  }
}; 

export const generateDebateBpSummary = async (motion, debateTranscript, userSide) => {
  const systemPrompt = GENERATE_SYSTEM_BP_OA
    .replace("{motion}", motion)
    .replace("{debateTranscript}", debateTranscript)
    .replace("{userSide}", userSide);

  const userPrompt = GENERATE_USER_BP_OA;

  try {
    const response = await fetch(
      `${supabase.functions.url}/bpSummary?systemPrompt=${encodeURIComponent(systemPrompt)}&userPrompt=${encodeURIComponent(userPrompt)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabase.auth.getSession()?.access_token}`
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    
    // Extract winner declaration and content
    let formattedContent = data.content;
    let winner = '';
    
    // Extract winner from the first line
    const winnerMatch = formattedContent.match(/^WINNER:\s*(OPENING \w+)/i);
    if (winnerMatch) {
      winner = winnerMatch[1];
      // Remove the WINNER line from the content
      formattedContent = formattedContent.replace(/^WINNER:.*\n/, '');
    }

    // Format the response to ensure it matches the expected markdown format
    formattedContent = formattedContent
      .replace(/^/gm, '')  // Remove any leading whitespace
      .replace(/\n{3,}/g, '\n\n')  // Replace multiple newlines with double newlines
      .trim();

    // Ensure headers are properly formatted
    formattedContent = formattedContent
      .replace(/^(Winner|Ranking the Teams|Evaluation of Differing Claims|Valuing Each Teams Contributions|Clashes\/Contradiction|Conclusion)$/gm, 
               '### __$1__');

    // Add winner section at the top if found
    if (winner) {
      formattedContent = `### __Winner__\n- ${winner}\n\n${formattedContent}`;
    }

    return formattedContent;
  } catch (error) {
    console.error("Error generating BP summary:", error);
    throw error;
  }
};

export const generateDebateRoomSummary = async (topic, roundByRoundData, debateTranscript, winningSide) => {

  let prompt;
  
  if (winningSide === 'Tie') {
    prompt = DEBATE_ROOM_SUMMARY_PROMPT_TIE.replace("{topic}", topic)
    .replace("{roundByRoundData}", roundByRoundData)
    .replace("{debateTranscript}", debateTranscript)    
  }

  else { 
    prompt = DEBATE_ROOM_SUMMARY_PROMPT.replace("{topic}", topic)
    .replace("{roundByRoundData}", roundByRoundData)
    .replace("{debateTranscript}", debateTranscript)
    .replace("{winner}", winningSide)
  }

  try {
    const response = await axios.post(
      "https://api.groq.com/openai/v1/chat/completions",
      {
        model: "llama3-70b-8192",
        messages: [
          {
            role: "user",
            content: prompt,
          },
        ],
        temperature: 0.4,
        max_tokens: 500,
        top_p: 0.9,
      },
      {
        headers: {
          Authorization: `Bearer ${GROQ_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    const summary = response.data.choices[0]?.message?.content || "";
    console.log("Generated debate summary:", summary);
    return summary;
  } catch (error) {
    console.error("Error generating debate summary:", error);
    throw error;
  }
}

export const evaluateArgument = async (
  topic,
  mode,
  previousArguments,
  examSide,
  currentArgument,
  side
) => {
  let systemPrompt = "";
  let userPrompt = "";

  console.log("Mode in evaluateArgument function is " + mode);

  switch (mode) {
    case "Cross-Examine":
      systemPrompt = CROSS_EXAM_EVALUATOR_SYSTEM_PROMPT;
      userPrompt = CROSS_EXAM_EVALUATOR_USER_PROMPT.replace("{topic}", topic)
        .replace("{initialArgument}", previousArguments[0] || "")
        .replace("{previousExchanges}", previousArguments.slice(1).join("\n"))
        .replace("{currentContribution}", currentArgument)
        .replace("{role}", examSide);
      break;
    case "Blitz":
    case "Standard":
      systemPrompt = EVALUATOR_SYSTEM_PROMPT;
      userPrompt = EVALUATOR_USER_PROMPT.replace("{topic}", topic)
        .replace("{previousArguments}", previousArguments.join("\n"))
        .replace("{currentArgument}", currentArgument)
        .replace("{side}", side);
      break;
    default:
      throw new Error("Invalid debate mode");
  }

  try {
    const response = await together.chat.completions.create({
      messages: [
        { role: "system", content: systemPrompt },
        { role: "user", content: userPrompt }
      ],
      model: "meta-llama/Llama-3-70b-chat-hf",
      temperature: 0.5,
      max_tokens: 50,
      top_p: 0.9,
    });

    console.log("Evaluation is ");
    console.log(response);

    let evaluation = response.choices[0].message.content || "";

    // Log raw response for debugging
    console.log(`Raw evaluation for ${side} (${mode}):`, evaluation);

    // Clean up the evaluation text
    evaluation = evaluation.replace(/```/g, "").trim();

    console.log("Mid range eval is " + evaluation);

    if (evaluation) {
      try {
        // Split the evaluation into score and summary
        const [scoreString, summaryString] = evaluation.split(/\n+/);

        // Extract score
        const scoreMatch = scoreString.match(/Score:\s*([-+]?\d+(\.\d+)?)/i);
        let score = scoreMatch ? parseFloat(scoreMatch[1]) : NaN;

        console.log("Score is " + score);

        // Extract summary
        const summaryMatch = summaryString.match(/Summary:\s*(.+)/i);
        const summary = summaryMatch ? summaryMatch[1].trim() : "";

        if (!isNaN(score) && summary) {
          console.log(`Processed evaluation for ${side} (${mode}):`, {
            score,
            summary,
          });
          return { score, summary, side };
        }
        throw new Error("Invalid score or summary");
      } catch (error) {
        console.log("Error parsing evaluation:", error.message);
      }
    }
    console.log(`Default evaluation for ${side} (${mode}):`, {
      score: 0,
      summary: "",
    });
    return { score: 0, summary: "", side };
  } catch (error) {
    console.error("Error evaluating argument:", error);
    throw error;
  }
};

export const generateSearchQueries = async (
  topic,
  currentRound,
  userArgument
) => {
  console.log(
    `Generating search queries for topic: "${topic}", round: "${currentRound}"`
  );
  const prompt = GENERATE_SEARCH_QUERIES_PROMPT.replace(
    "{topic}",
    topic
  ).replace("{userArgument}", userArgument);

  try {
    const response = await together.chat.completions.create({
      messages: [{ role: "user", content: prompt }],
      model: "meta-llama/Llama-3-70b-chat-hf",
      max_tokens: 100,
    });

    const queries = response.choices[0].message.content
      .split("\n")
      .filter((q) => q.trim() !== "")
      .filter((q) => q.trim().startsWith("Q:"));

    console.log("Generated search queries:", queries);
    return queries;
  } catch (error) {
    console.error("Error generating search queries:", error);
    throw error;
  }
};

export const searchExa = async (query) => {
  console.log(`Searching Exa API with query: "${query}"`);

  // if (!EXA_API_KEY) {
  //   console.error(
  //     "Exa API key is not set. Please check your environment variables."
  //   );
  //   return [];
  // }

  try {
    const response = await axios.post(
      "https://api.exa.ai/search",
      {
        query,
        useAutoprompt: true,
        type: "magic",
        numResults: 3,
        contents: {
          text: {
            maxCharacters: 1000,
            includeHtmlTags: false,
          },
          highlights: {
            numSentences: 3,
            highlightsPerUrl: 3,
          },
        },
      },
      // {
      //   headers: {
      //     "x-api-key": EXA_API_KEY,
      //     "Content-Type": "application/json",
      //   },
      // }
    );

    if (response.data && Array.isArray(response.data.results)) {
      console.log(
        `Exa API returned ${response.data.results.length} results for query: "${query}"`
      );
      return response.data.results;
    } else {
      console.warn("Unexpected response format from Exa API:", response.data);
      return [];
    }
  } catch (error) {
    if (error.response) {
      console.error("Error response from Exa API:", error.response.data);
      console.error("Status code:", error.response.status);
    } else if (error.request) {
      console.error("No response received from Exa API:", error.request);
    } else {
      console.error("Error setting up the request:", error.message);
    }
    return [];
  }
};

export const generateMotionInfo = async (motion) => {

  let systemPrompt = GENERATE_MOTION_INFO_SYSTEM_PROMPT.replace("{motion}", motion);

  let userPrompt = GENERATE_MOTION_INFO_USER_PROMPT;

  try {
    const response = await fetch(
      `${supabase.functions.url}/motionResponse?param1=${encodeURIComponent(systemPrompt)}&param2=${encodeURIComponent(userPrompt)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabase.auth.getSession()?.access_token}`
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    let unformattedContent = data.content;
    return unformattedContent.split('////').map(item => item.trim(1));
  } catch (error) {
    console.error("Error generating random motion:", error);
    throw error;
  }
}

export const generateMotion = async (customOption) => {
  if (customOption === "choose your own motion") {
    return "";
  }
  console.log("custom option: " + customOption)
  let systemPrompt;
  let userPrompt;
  if (customOption === "" || customOption === "completely random" || customOption === "customize motion") { //need to ba
    console.log("Here we restarted!")
    let rand1 = Math.random()
    let rand2 = Math.random()
    console.log("Random number 1 is" + rand1 + " and random numnber 2 is " + rand2)
    systemPrompt = rand1 < 0.25 ? GENERATE_POLICY_MOTION_SYSTEM_PROMPT : (rand2 < 0.333 ? GENERATE_ACTOR_MOTION_SYSTEM_PROMPT : GENERATE_ACTOR_MOTION_SYSTEM_PROMPT);
    userPrompt = GENERATE_RANDOM_MOTION_USER_PROMPT;
  }
  else if(customOption === "policy"){
    console.log("entered policy")
    systemPrompt = GENERATE_POLICY_MOTION_SYSTEM_PROMPT
    userPrompt = GENERATE_RANDOM_MOTION_USER_PROMPT;
  }
  else if(customOption === "actor motion"){
    systemPrompt = GENERATE_ACTOR_MOTION_SYSTEM_PROMPT
    userPrompt = GENERATE_RANDOM_MOTION_USER_PROMPT;
  }
  else if(customOption === "analysis"){
    systemPrompt = GENERATE_ANALYSIS_MOTION_SYSTEM_PROMPT
    userPrompt = GENERATE_RANDOM_MOTION_USER_PROMPT;
  }
  else{
    systemPrompt = GENERATE_MOTION_SYSTEM_PROMPT;
    userPrompt = GENERATE_MOTION_USER_PROMPT.replace("{type}", customOption);
  }


  for(let i = 0; i < 300; i++){
    const randomIndex = Math.floor(Math.random() * systemPrompt.length);
    systemPrompt = systemPrompt.slice(0, randomIndex) + systemPrompt.slice(randomIndex + 1);
  }
  

  try {
    const response = await fetch(
      `${supabase.functions.url}/motionResponse?param1=${encodeURIComponent("")}&param2=${encodeURIComponent(systemPrompt + "\n\n\n\n" + userPrompt)}`,
      {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${supabase.auth.getSession()?.access_token}`
        },
      }
    );

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data.content.replace(/^motion:\s*/i, '');
  } catch (error) {
    console.error("Error generating random motion:", error);
    throw error;
  }
}

export const processSearchResults = (results) => {
  console.log(`Processing ${results.length} search results`);
  if (!Array.isArray(results) || results.length === 0) {
    console.warn("No results to process");
    return [];
  }

  const processedResults = results.map((result) => ({
    score: result.score || 0,
    title: result.title || "Untitled",
    url: result.url || "#",
    highlight:
      result.highlights && result.highlights[0]
        ? result.highlights[0].text
        : "No highlight available",
  }));

  console.log(`Processed ${processedResults.length} search results`);
  return processedResults;
};

export const streamChatCompletion = async (SYSTEM_PROMPT, USER_PROMPT) => {

  try {
    const response = await fetch(`${supabase.functions.url}/streamChatCompletion?param1=${encodeURIComponent(SYSTEM_PROMPT)}&param2=${encodeURIComponent(USER_PROMPT)}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${supabase.auth.getSession()?.access_token}`
      },
    });

    if (!response.ok) throw new Error('Network response was not ok');
    return response.body
  } catch (err) {
    console.error("Error:", err);
    throw err;
  }
};

export const transcribeAudio = async (audioBlob) => {
  console.log("Transcribing audio");
  if (!audioBlob) {
    console.warn("No audio blob provided for transcription");
    return null;
  }

  try {
    const formData = new FormData();
    formData.append("file", audioBlob, "audio.webm");
    formData.append("model", "whisper-large-v3");
    formData.append("language", "en");

    const response = await fetch(
      "https://api.groq.com/openai/v1/audio/transcriptions",
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${GROQ_API_KEY}`,
        },
        body: formData,
      }
    );

    const data = await response.json();
    console.log("Audio transcription completed");
    return data.text;
  } catch (error) {
    console.error("Error transcribing audio:", error);
    throw error;
  }
};

export const generateCxArg = async (role, topic) => {
  let answer = role === "for" ? "yes" : "no";

  const userPrompt = CROSS_EXAMINATION_STARTING_ARGUMENT_PROMPT.replace(
    "{role}",
    role
  )
    .replace("{topic}", topic)
    .replace("{answer}", answer);

  try {
    const response = await axios.post(
      "https://api.groq.com/openai/v1/chat/completions",
      {
        model: "llama3-70b-8192",
        messages: [
          {
            role: "user",
            content: userPrompt,
          },
        ],
        temperature: 0.7,
        max_tokens: 400,
        top_p: 0.9,
      },
      {
        headers: {
          Authorization: `Bearer ${GROQ_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    let argument = response.data.choices[0]?.message?.content || "";
    argument = argument.trim();

    const wordCount = argument.split(/\s+/).length;
    console.log(`Generated ${role} argument (${wordCount} words):`, argument);
    return argument;
  } catch (error) {
    console.error("Error generating cross-examination argument:", error);
    throw error;
  }
};

// Simple cache to store recent topics
const recentTopics = [];
const MAX_RECENT_TOPICS = 5;

export const generateDebateTopic = async () => {
  const systemMessage =
    "Generate a unique debate topic. Avoid topics similar to these recent ones: " +
    recentTopics.join(", ");

  try {
    const response = await axios.post(
      "https://api.groq.com/openai/v1/chat/completions",
      {
        model: "llama3-70b-8192",
        messages: [
          {
            role: "system",
            content: systemMessage,
          },
          {
            role: "user",
            content: GENERATE_DEBATE_TOPIC_PROMPT,
          },
        ],
        temperature: 0.9,
        max_tokens: 50,
        top_p: 0.95,
      },
      {
        headers: {
          Authorization: `Bearer ${GROQ_API_KEY}`,
          "Content-Type": "application/json",
        },
      }
    );

    let topic = response.data.choices[0]?.message?.content || "";
    topic = topic.trim();

    // check if the topic is too similar to recent ones
    if (
      recentTopics.some(
        (recentTopic) =>
          recentTopic.toLowerCase().includes(topic.toLowerCase()) ||
          topic.toLowerCase().includes(recentTopic.toLowerCase())
      )
    ) {
      console.log("Generated topic too similar to recent ones. Retrying...");
      return generateDebateTopic(); // recursive call to try again
    }

    // add the new topic to recent topics
    recentTopics.unshift(topic);
    if (recentTopics.length > MAX_RECENT_TOPICS) {
      recentTopics.pop();
    }

    console.log("Generated debate topic:", topic);
    return topic;
  } catch (error) {
    console.error("Error generating debate topic:", error);
    throw error;
  }
};

const API_BASE_URL = 'https://debate-arena-backend.vercel.app';

export const preliminaryCheck = async (claim) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/preliminary-check`, { claim });
    return response.data;
  } catch (error) {
    console.error("Error in preliminary check:", error);
    throw error;
  }
};

export const searchExaForFactCheck = async (claim, preliminaryAnswer = null) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/search-exa`, { claim, preliminaryAnswer });
    return response.data;
  } catch (error) {
    console.error("Error searching Exa for fact check:", error);
    throw error;
  }
};

export const evaluateSourcesWithLLM = async (claim, sources) => {
  try {
    const response = await axios.post(`${API_BASE_URL}/api/evaluate-sources`, { claim, sources });
    return response.data;
  } catch (error) {
    console.error("Error evaluating sources with LLM:", error);
    throw error;
  }
};