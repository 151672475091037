import { useState, useEffect } from 'react';
import styles from '../styles/SmartBanner.module.css';
import { X } from 'lucide-react'; // Assuming you're using lucide-react for icons

const SmartBanner = () => {
  const [isVisible, setIsVisible] = useState(false);
  
  const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
  const isAndroid = /Android/.test(navigator.userAgent);
  
  useEffect(() => {
    // Check if banner was previously dismissed
    const isDismissed = localStorage.getItem('smartBannerDismissed');
    // Show banner only if it wasn't dismissed and user is on mobile
    if (!isDismissed && (isIOS || isAndroid)) {
      setIsVisible(true);
    }
  }, [isIOS, isAndroid]);

  const handleDismiss = () => {
    setIsVisible(false);
    // Save dismissal to localStorage
    localStorage.setItem('smartBannerDismissed', 'true');
  };

  if (!isVisible) return null;
  
  return (
    <>
      <div className={styles.smartBanner}>
        <div className={styles.content}>
          <p className={styles.text}>Get the DebateArena app</p>
          <a 
            href={isIOS 
              ? "https://apps.apple.com/app/6738328773" 
              : "https://play.google.com/store/apps/details?id=net.debatearena.app"
            }
            className={styles.downloadButton}
            target="_blank"
            rel="noopener noreferrer"
          >
            Download
          </a>
        </div>
        <button 
          onClick={handleDismiss}
          className={styles.closeButton}
          aria-label="Close banner"
        >
          <X size={20} />
        </button>
      </div>
      {/* Spacer div to prevent content from being hidden behind banner */}
      <div className={styles.bannerSpacer} />
    </>
  );
};

export default SmartBanner;