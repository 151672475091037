import React, { useState, useEffect, useRef } from "react";
import {
  Check,
  Loader,
  Swords,
  Mic,
  Square,
  Flag,
  LogOut,
  MoreHorizontal,
  FileText,
} from "lucide-react";

import PvPMessage from "./PvPMessageComponent";
import styles from "../styles/DebateArena.module.css";
import { transcribeAudio as transcribeAudioAPI } from "../utils/api";
import {
  calculateCumulativeScores,
  getDisplayScore,
} from "../utils/scoreUtils";
import {
  updateReadyState,
  submitDebateMessage,
  endDebateEarly,
  updateDebateRoomSummaryAndWinner,
} from "../queries/roomQueries";
import { generateDebateRoomSummary } from "../utils/api";
import { supabase } from "../queries/Queries";
import DebateRoomEvaluationCard from "./DebateRoomEvaluationCard";
import EvaluationTooltip from "./EvaluationTooltip";
import ReportOpponentModal from "./ReportOpponentModal";

const DebateRoomArena = ({
  arenaRef,
  room,
  userAndProfile,
  isCreator,
  onDebateStart,
  chatBoxRef,
  forSidePlayer,
  againstSidePlayer,
  userSide,
}) => {
  // Refs for managing input state
  const mediaRecorderRef = useRef(null);
  const latestInputRef = useRef("");
  const inputQueueRef = useRef([]);
  const isProcessingQueueRef = useRef(false);
  const textareaRef = useRef(null);

  // State management
  const [audioBlob, setAudioBlob] = useState(null);
  const [isReady, setIsReady] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [cumulativeScore, setCumulativeScore] = useState(0);
  const [currentSummary, setCurrentSummary] = useState("");
  const [isRecording, setIsRecording] = useState(false);
  const [input, setInput] = useState("");
  const [timeLeft, setTimeLeft] = useState(60);
  const [showEvaluation, setShowEvaluation] = useState(false);
  const [summary, setSummary] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showOptionsMenu, setShowOptionsMenu] = useState(false);
  const [isPendingTranscription, setIsPendingTranscription] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Additional refs
  const evaluationSummaryRef = useRef(null);
  const evaluationTextRef = useRef(null);
  const timerRef = useRef(null);
  const lastTickRef = useRef(Date.now());
  const deadlineRef = useRef(null);

  // Input Queue Processing
  const processInputQueue = async () => {
    if (isProcessingQueueRef.current || inputQueueRef.current.length === 0)
      return;

    isProcessingQueueRef.current = true;
    try {
      const updates = inputQueueRef.current;
      inputQueueRef.current = [];

      const newInput = updates.reduce((acc, update) => {
        if (typeof update === "function") {
          return update(acc);
        }
        return update;
      }, latestInputRef.current);

      latestInputRef.current = newInput;
      setInput(newInput);
    } finally {
      isProcessingQueueRef.current = false;
    }
  };

  // Add to input queue
  const queueInputUpdate = (update) => {
    inputQueueRef.current.push(update);
    processInputQueue();
  };

  // Synchronize ref with state
  useEffect(() => {
    latestInputRef.current = input;
  }, [input]);

  // Scroll chat into view
  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [room.messages]);

  // Handle summary updates
  useEffect(() => {
    if (!summary && room.messages.length !== 0) {
      setSummary(room.summary);
      setShowEvaluation(true);
    }
  }, [room.summary]);

  // Handle room completion
  useEffect(() => {
    if (room.status === "completed") {
      handleEndDebate();
    }
  }, [room.status]);

  // Recording functionality
  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);
      const chunks = [];

      mediaRecorderRef.current.ondataavailable = (e) => chunks.push(e.data);
      mediaRecorderRef.current.onstop = () => {
        const blob = new Blob(chunks, { type: "audio/webm" });
        setAudioBlob(blob);
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error starting recording:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current && isRecording) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
    }
  };

  // Transcription handling
  const transcribeAudio = async () => {
    if (!audioBlob) return;

    setIsPendingTranscription(true);
    try {
      const transcription = await transcribeAudioAPI(audioBlob);
      queueInputUpdate((prev) => `${prev} ${transcription}`);
      setAudioBlob(null);
    } catch (error) {
      console.error("Error transcribing audio:", error);
    } finally {
      setIsPendingTranscription(false);
    }
  };

  useEffect(() => {
    if (audioBlob) {
      transcribeAudio();
    }
  }, [audioBlob]);

  // Font size adjustment
  useEffect(() => {
    const adjustFontSize = () => {
      if (evaluationSummaryRef.current && evaluationTextRef.current) {
        let fontSize = 1;
        evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        const maxHeight = evaluationSummaryRef.current.clientHeight;

        while (
          evaluationTextRef.current.scrollHeight > maxHeight &&
          fontSize > 0.1
        ) {
          fontSize -= 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        }

        while (
          fontSize < 1 &&
          evaluationTextRef.current.scrollHeight <= maxHeight
        ) {
          fontSize += 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;

          if (evaluationTextRef.current.scrollHeight > maxHeight) {
            fontSize -= 0.1;
            evaluationTextRef.current.style.fontSize = `${fontSize}em`;
            break;
          }
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);
    return () => window.removeEventListener("resize", adjustFontSize);
  }, [currentSummary]);

  // Timer management
  useEffect(() => {
    if (room.status === "in_progress" && room.current_turn_deadline) {
      const deadline = new Date(room.current_turn_deadline).getTime();
      deadlineRef.current = deadline;

      const updateTimer = () => {
        const now = Date.now();
        const timeSinceLastTick = now - lastTickRef.current;
        lastTickRef.current = now;

        // If more than 2 seconds have passed since last tick,
        // we were probably in a background tab
        if (timeSinceLastTick > 2000 && deadlineRef.current) {
          // Check if we missed the deadline while in background
          if (
            now >= deadlineRef.current &&
            room.current_turn_side === userSide
          ) {
            clearInterval(timerRef.current);
            handleAutoSubmit();
            return;
          }
        }

        const remaining = Math.max(0, Math.floor((deadline - now) / 1000));
        setTimeLeft(remaining);

        if (remaining === 0 && room.current_turn_side === userSide) {
          clearInterval(timerRef.current);

          // Store current turn information before any async operations
          const currentTurnSide = room.current_turn_side;
          const currentTurnNumber = room.current_turn;

          if (isRecording) {
            stopRecording();
          }

          const checkAndSubmit = async () => {
            // Only submit if the turn hasn't changed
            if (
              room.current_turn_side === currentTurnSide &&
              room.current_turn === currentTurnNumber
            ) {
              while (isRecording || isPendingTranscription) {
                await new Promise((resolve) => setTimeout(resolve, 100));
              }

              // Final check before submission
              if (
                room.current_turn_side === currentTurnSide &&
                room.current_turn === currentTurnNumber
              ) {
                handleAutoSubmit();
              }
            }
          };

          checkAndSubmit();
        }
      };

      // Also add a visibility change listener
      const handleVisibilityChange = () => {
        if (!document.hidden && deadlineRef.current) {
          const now = Date.now();
          if (
            now >= deadlineRef.current &&
            room.current_turn_side === userSide
          ) {
            clearInterval(timerRef.current);
            handleAutoSubmit();
          }
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      updateTimer();
      timerRef.current = setInterval(updateTimer, 100); // Keep the 100ms interval

      return () => {
        if (timerRef.current) clearInterval(timerRef.current);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange
        );
      };
    }
  }, [
    room.current_turn_deadline,
    room.current_turn_side,
    room.current_turn,
    userSide,
  ]);
  // Score calculation
  useEffect(() => {
    if (room.evaluations?.length > 0) {
      const scores = calculateCumulativeScores(room.evaluations);
      setCumulativeScore(scores[scores.length - 1]);
      const latestEval = room.evaluations[room.evaluations.length - 1];
      setCurrentSummary(latestEval.summary || "");
    }
  }, [room.evaluations]);

  // Ready state handling
  const handleReadyClick = async () => {
    setIsLoading(true);
    try {
      const result = await updateReadyState(room.id, isCreator);
      if (result.error) {
        console.error("Error updating ready state:", result.error);
        return;
      }
      setIsReady(true);
    } catch (error) {
      console.error("Error in ready click:", error);
    } finally {
      setIsLoading(false);
    }
  };

  // Debate end handling
  const handleEndDebate = async () => {
    if (userSide === "For") {
      const roundByRoundData = room.evaluations
        .map((e, index) => {
          const cumulativeScores = calculateCumulativeScores(
            room.evaluations.slice(0, index + 1)
          );
          const roundScore = getDisplayScore(
            cumulativeScores[cumulativeScores.length - 1]
          );
          return `round ${index + 1}: ${e.side} - score: ${roundScore.toFixed(
            3
          )} - ${e.summary.toLowerCase()}`;
        })
        .join("\n");
  
      const debateTranscript = room.messages
        .filter((msg) => msg.role !== "system")
        .map((msg) => {
          const speaker = (msg.side || msg.role).toUpperCase();
          return `${speaker}: ${msg.content.toLowerCase()}`;
        })
        .join("\n\n");
  
      // Determine winner
      const finalScore = calculateCumulativeScores(room.evaluations)[room.evaluations.length - 1];
      let winner = null;
      let winningSide = 'Tie';
      
      if (finalScore > 0) {
        winner = forSidePlayer.id;
        winningSide = 'For';
      } else if (finalScore < 0) {
        winner = againstSidePlayer.id;
        winningSide = 'Against';
      }
      // If finalScore = 0, winner remains null to indicate a tie
  
      try {
        const generatedSummary = await generateDebateRoomSummary(
          room.topic.toLowerCase(),
          roundByRoundData,
          debateTranscript,
          winningSide
        );
  
        const { data, error } = await updateDebateRoomSummaryAndWinner(
          room.id,
          generatedSummary.toLowerCase(),
          winner
        );
        if (error) {
          console.error("Failed to update debate room summary:", error);
        }
  
        setShowEvaluation(true);
      } catch (error) {
        console.error("Failed to generate and update debate summary:", error);
      }
    }
  };

  // Message submission
  const handleSubmitMessage = async (content) => {
    if (room.current_turn_side !== userSide || isSubmitting) return;
    if (room.status === "completed") return;

    try {
      setIsSubmitting(true);

      const nextDeadline = new Date();
      nextDeadline.setSeconds(nextDeadline.getSeconds() + 64);

      const messageContent =
        content.length === 0
          ? `[Time expired - no argument submitted]`
          : content;

      const { data, error } = await submitDebateMessage(
        room.id,
        messageContent,
        nextDeadline.toISOString()
      );

      if (error) {
        if (error.message === "Turn has already changed") {
          console.log("Turn changed before submission could complete");
          return;
        }
        console.error("Error submitting message:", error);
        return;
      }

      if (data.status === "completed") {
        handleEndDebate();
      }
    } catch (error) {
      console.error("Exception in submit message:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  // Auto-submit handling
  const handleAutoSubmit = async () => {
    if (isSubmitting) return;
    console.log(room.status)
    if (room.status === "completed") {
      return;
    }

    // Store turn information at the start
    const currentTurnSide = room.current_turn_side;
    const currentTurnNumber = room.current_turn;

    while (isPendingTranscription) {
      if (
        room.current_turn_side !== currentTurnSide ||
        room.current_turn !== currentTurnNumber
      ) {
        console.log("Turn changed while waiting for transcription");
        return;
      }
      await new Promise((resolve) => setTimeout(resolve, 100));
    }

    await new Promise((resolve) => setTimeout(resolve, 200));

    if (
      room.current_turn_side !== currentTurnSide ||
      room.current_turn !== currentTurnNumber
    ) {
      console.log("Turn changed before submission");
      return;
    }

    const textareaValue = textareaRef.current?.value;
    const finalInput = textareaValue || latestInputRef.current || input;

    await handleSubmitMessage(finalInput);
    queueInputUpdate("");
  };

  const handleSubmit = async (e) => {
    e?.preventDefault();
    if (!input.trim() || room.current_turn_side !== userSide || isSubmitting)
      return;

    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    await handleSubmitMessage(input);
    queueInputUpdate("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && !e.shiftKey && !isSubmitting) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const forfeitDebate = async () => {
    await endDebateEarly(
      room.id,
      userSide === "For" ? forSidePlayer.id : againstSidePlayer.id
    );
  };

  // UI helper calculations
  const isUserReady = isCreator ? room.creator_ready : room.opponent_ready;
  const isOpponentReady = isCreator ? room.opponent_ready : room.creator_ready;
  const amplifiedPercentage =
    (Math.sign(cumulativeScore) *
      Math.pow(Math.abs(cumulativeScore) / 100, 0.7) +
      1) *
    50;
  const isForWinning = cumulativeScore >= 0;
  const displayScore = getDisplayScore(cumulativeScore);

  return (
    <div>
      {showEvaluation && (
        <DebateRoomEvaluationCard
          isDbRoom={true}
          evaluations={room.evaluations}
          topic={room.topic}
          onHide={() => setShowEvaluation(false)}
          messages={room.messages}
          summary={summary}
          winner={room.winner === forSidePlayer.id ? forSidePlayer.username : (room.winner === againstSidePlayer.id ? againstSidePlayer.username : 'Tie')}
          forSidePlayer={forSidePlayer.username}
          againstSidePlayer={againstSidePlayer.username}
          userSide={userSide}
        />
      )}
      <div className={styles["debate-arena"]} ref={arenaRef}>
        <div className={styles["cross-exam-arena"]}>
          <div className={styles["evaluation-bar-container"]}>
            <EvaluationTooltip />
            <div className={styles["evaluation-bar"]}>
              <div
                className={styles["evaluation-bar-fill"]}
                style={{ width: `${amplifiedPercentage}%` }}
              >
                {isForWinning && (
                  <span className={styles["score-display-for"]}>
                    {displayScore.toFixed(1)}
                  </span>
                )}
              </div>
              {!isForWinning && (
                <span className={styles["score-display-against"]}>
                  {displayScore.toFixed(1)}
                </span>
              )}
            </div>
          </div>
          <div className={styles["dynamic-boxes"]}>
            <div
              className={styles["evaluation-summary"]}
              ref={evaluationSummaryRef}
            >
              <div
                className={styles["evaluation-summary-text"]}
                ref={evaluationTextRef}
              >
                {currentSummary}
              </div>
            </div>

            <div className={styles["arena-labels"]}>
              <span className={`${styles["side-labels"]} ${styles.for}`}>
                For ({forSidePlayer.username})
              </span>
              <span className={`${styles["side-labels"]} ${styles.against}`}>
                Against ({againstSidePlayer.username})
              </span>
            </div>
            <div className={styles["chat-box"]} ref={chatBoxRef}>
              <div className={styles["topic-message"]}>{room.topic}</div>
              {room.status === "ready_phase" ? (
                <div className={styles.vsContainer}>
                  <div className={`${styles.debater} ${styles.debaterFor}`}>
                    <div className={styles.debaterHeader}>
                      <span className={`${styles.side} ${styles.sideFor}`}>
                        For
                      </span>
                    </div>
                    <div className={styles.username}>
                      {forSidePlayer.username}
                    </div>
                    {forSidePlayer.isUser ? (
                      forSidePlayer.ready ? (
                        <div className={styles.readyStatus}>
                          <Check size={20} />
                          Ready
                        </div>
                      ) : (
                        <button
                          className={styles.readyButton}
                          onClick={handleReadyClick}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Loader className={styles.spinner} />
                          ) : (
                            "Ready Up"
                          )}
                        </button>
                      )
                    ) : forSidePlayer.ready ? (
                      <div className={styles.readyStatus}>
                        <Check size={20} />
                        Ready
                      </div>
                    ) : (
                      <div className={styles.waitingText}>Waiting...</div>
                    )}
                  </div>

                  <div className={styles.vsText}>
                    <Swords size={24} />
                  </div>

                  <div className={`${styles.debater} ${styles.debaterAgainst}`}>
                    <div className={styles.debaterHeader}>
                      <span className={`${styles.side} ${styles.sideAgainst}`}>
                        Against
                      </span>
                    </div>
                    <div className={styles.username}>
                      {againstSidePlayer.username}
                    </div>
                    {againstSidePlayer.isUser ? (
                      againstSidePlayer.ready ? (
                        <div className={styles.readyStatus}>
                          <Check size={20} />
                          Ready
                        </div>
                      ) : (
                        <button
                          className={styles.readyButton}
                          onClick={handleReadyClick}
                          disabled={isLoading}
                        >
                          {isLoading ? (
                            <Loader className={styles.spinner} />
                          ) : (
                            "Ready Up"
                          )}
                        </button>
                      )
                    ) : againstSidePlayer.ready ? (
                      <div className={styles.readyStatus}>
                        <Check size={20} />
                        Ready
                      </div>
                    ) : (
                      <div className={styles.waitingText}>Waiting...</div>
                    )}
                  </div>
                </div>
              ) : (
                <div className={styles.messagesContainer}>
                  {room.messages?.map((msg, index) => (
                    <PvPMessage key={index} msg={msg} />
                  ))}
                  {room.status === "completed" && (
                    <div className={styles.debateEndedMessage}>
                      Debate has ended!
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
          <form className={styles["argument-form"]} onSubmit={handleSubmit}>
            {room.status === "in_progress" && (
              <div className={styles["timer-display"]}>
                <div>
                  Turn {room.current_turn || 1} / 10:{" "}
                  <span className={timeLeft <= 3 ? styles["timer-blink"] : ""}>
                    {timeLeft}s
                  </span>
                  {room.current_turn_side && (
                    <span>
                      {room.current_turn_side === userSide
                        ? " (Your turn)"
                        : ` (${room.current_turn_side}'s turn)`}
                    </span>
                  )}
                </div>
              </div>
            )}

            <textarea
              id={styles["argument-input"]}
              ref={textareaRef}
              value={input}
              onChange={(e) => queueInputUpdate(e.target.value)}
              onKeyDown={handleKeyPress}
              disabled={
                room.status !== "in_progress" ||
                room.current_turn_side !== userSide ||
                isRecording
              }
              placeholder={
                room.status === "ready_phase"
                  ? "The debate will begin once both players are ready..."
                  : room.current_turn_side === userSide
                  ? "Type your argument OR use Voice input..."
                  : `Waiting for ${room.current_turn_side}'s argument...`
              }
              rows="4"
            />

            <div className={styles["controls-container"]}>
              {room.status === "in_progress" &&
                room.current_turn_side === userSide && (
                  <div className={styles["recording-controls"]}>
                    {!isRecording ? (
                      <button
                        type="button"
                        onClick={startRecording}
                        disabled={
                          room.status !== "in_progress" ||
                          room.current_turn_side !== userSide
                        }
                        className={styles["record-button"]}
                      >
                        <Mic size={24} />
                      </button>
                    ) : (
                      <button
                        type="button"
                        onClick={stopRecording}
                        className={styles["stop-record-button"]}
                      >
                        <Square size={24} />
                      </button>
                    )}
                  </div>
                )}

              <button
                type="submit"
                disabled={
                  room.status !== "in_progress" ||
                  room.current_turn_side !== userSide ||
                  isRecording ||
                  isSubmitting // Add this condition
                }
              >
                {isSubmitting ? "..." : "Send"}
              </button>
            </div>
          </form>
          <div className={styles["bottom-controls"]}>
            {room.status === "completed" && !showEvaluation ? (
              <button
                className={styles["more-options-button"]}
                onClick={() => setShowEvaluation(true)}
              >
                <FileText size={16} />
                Show Evaluation Report
              </button>
            ) : (
              <div className={styles["more-options-container"]}>
                <button
                  className={styles["more-options-button"]}
                  onClick={() => setShowOptionsMenu((prev) => !prev)}
                >
                  <MoreHorizontal size={16} />
                  Actions
                </button>
                {showOptionsMenu && (
                  <div className={styles["options-menu"]}>
                    {room.status !== "completed" && (
                      <button
                        className={styles["options-menu-item"]}
                        onClick={() => {
                          setShowOptionsMenu(false);
                          forfeitDebate();
                        }}
                      >
                        <LogOut size={16} />
                        Forfeit
                      </button>
                    )}
                    <button
                      className={styles["options-menu-item"]}
                      onClick={() => {
                        setShowOptionsMenu(false);
                        setShowReportModal(true);
                      }}
                    >
                      <Flag size={16} />
                      Report Opponent
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <ReportOpponentModal
        isOpen={showReportModal}
        onClose={() => setShowReportModal(false)}
        opponentName={
          isCreator ? room.opponent?.username : room.creator?.username
        }
        opponentId={isCreator ? room.opponent_id : room.creator_id}
        debateRoomId={room.id}
      />
    </div>
  );
};

export default DebateRoomArena;