import React, { useState, useEffect } from "react";
import { Share2, X, Copy, Swords } from "lucide-react";
import { Share } from "@capacitor/share";
import styles from "../styles/RoomShareModal.module.css";

const RoomShareModal = ({
  isOpen,
  room,
  onJoin,
  onClose,
  isCreator,
  forSidePlayer,
  againstSidePlayer,
}) => {
  const [showCopyTooltip, setShowCopyTooltip] = useState(false);

  useEffect(() => {
    // If opponent has joined, set a timeout to close the modal
    if (room?.opponent?.username) {
      const timer = setTimeout(() => {
        onJoin();
      }, 2500);

      // Cleanup timeout if component unmounts
      return () => clearTimeout(timer);
    }
  }, [room?.opponent?.username, onJoin]);

  if (!isOpen || !room) return null;

  const shareUrl = `https://www.debatearena.app/debate/${room.id}`;
  const hasOpponent = Boolean(room.opponent?.username);

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setShowCopyTooltip(true);
      setTimeout(() => setShowCopyTooltip(false), 2000);
    } catch (err) {
      console.error("Failed to copy:", err);
    }
  };

  const handleShare = async () => {
    try {
      await Share.share({
        title: "Join my debate room!",
        text: `Join my debate on: ${room.topic}`,
        url: shareUrl,
        dialogTitle: "Share with friends",
      });
    } catch (err) {
      if (err.name !== "ShareCanceled") {
        console.error("Share failed:", err);
        // Fallback to copy link if share fails
        handleCopyLink();
      }
    }
  };

  return (
    <div className={styles.overlay}>
      <div className={styles.modal}>
        <h2 className={styles.title}>
          {hasOpponent ? "Opponent Joined!" : "Challenge a friend"}
        </h2>

        <div className={styles.previewContainer}>
          <div className={styles.topicContainer}>
            <h3 className={styles.topicLabel}>Debate Topic</h3>
            <div className={styles.topic}>{room.topic}</div>
          </div>

          <div className={styles.vsContainer}>
            {/* For Side */}
            <div className={`${styles.debater} ${styles.debaterFor}`}>
              <div className={styles.debaterHeader}>
                <span className={`${styles.side} ${styles.sideFor}`}>For</span>
              </div>
              <div className={styles.username}>
                {forSidePlayer.username || "Waiting..."}
              </div>
            </div>

            <div className={styles.vsText}>
              <Swords size={16} />
            </div>

            {/* Against Side */}
            <div className={`${styles.debater} ${styles.debaterAgainst}`}>
              <div className={styles.debaterHeader}>
                <span className={`${styles.side} ${styles.sideAgainst}`}>
                  Against
                </span>
              </div>
              <div className={styles.username}>
                {againstSidePlayer.username || "Waiting..."}
              </div>
            </div>
          </div>
        </div>

        {!hasOpponent && isCreator && (
          <>
            <div className={styles.urlContainer}>
              <p className={styles.urlLabel}>
                To invite someone to play, give this URL
              </p>
              <div className={styles.urlInputContainer}>
                <input
                  type="text"
                  value={shareUrl}
                  readOnly
                  className={styles.urlInput}
                />
                <button
                  onClick={handleCopyLink}
                  className={styles.copyButton}
                  onMouseEnter={() => setShowCopyTooltip(true)}
                  onMouseLeave={() => setShowCopyTooltip(false)}
                >
                  <Copy className={styles.copyIcon} />
                  {showCopyTooltip && (
                    <span className={styles.tooltip}>Copy</span>
                  )}
                </button>
              </div>
              <p className={styles.urlHelper}>
                The first person to come to this URL will join as your opponent.
              </p>
            </div>

            <div className={styles.qrLabel}>
              Or let your opponent scan this QR code
            </div>

            <div className={styles.qrContainer}>
              <img
                src={`https://api.qrserver.com/v1/create-qr-code/?size=200x200&data=${encodeURIComponent(
                  shareUrl
                )}`}
                alt="Room QR Code"
                className={styles.qrCode}
              />
            </div>

            <button onClick={onClose} className={styles.desktopCancelButton}>
              <X className={styles.cancelIcon} />
              <span>CANCEL</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default RoomShareModal;
