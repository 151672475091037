import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "lucide-react";
import styles from "../styles/StoredDebates.module.css";
import TopMenuBar from "../components/TopMenuBar.js"
import EvaluationReportCard from "../components/EvaluationReportCard";
import { calculateCumulativeScores, getDisplayScore } from "../utils/scoreUtils";
import { getDebateById, getCurrentUserAndProfile, handleSignOut } from "../queries/Queries"

function StoredDebate() {

  const navigate = useNavigate();

  const { debateId } = useParams();
  const [debateData, setDebateData] = useState(null);
  const [examSide, setExamSide] = useState(null);
  const [side, setSide] = useState(null);
  const [topic, setTopic] = useState("");
  const [mode, setMode] = useState("");
  const [divArg, setDivArg] = useState(null);
  const [messages, setMessages] = useState([]);
  const [allSources, setAllSources] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null)
  const isBlitzMode = mode === "Blitz";
  const isCrossExamMode = mode === "Cross-Examine";
  const [showAdditionalSources, setShowAdditionalSources] = useState(false);
  const [cumulativeScore, setCumulativeScore] = useState(0);
  const [evaluationSummary, setEvaluationSummary] = useState("");
  const [currentSummary, setCurrentSummary] = useState("");
  const evaluationSummaryRef = useRef(null);
  const evaluationTextRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [error, setError] = useState(null);
  const [showEvaluationReport, setShowEvaluationReport] = useState(true);



  useEffect(() => {
    const fetchDebateData = async () => {
      if(debateId){
        try{
            const debateInfo = await getDebateById(debateId);
            console.log(debateInfo)
            setDebateData(debateInfo)
        }
        catch(error){
          console.log(error)
        }
      }
    }
    fetchUserAndProfile();
    fetchDebateData();
  }, [debateId]);

  useEffect(() => {
    if(debateData){
      setExamSide(debateData.role);
      setSide(debateData.position);
      setTopic(debateData.topic);
      setMode(debateData.mode);
      setDivArg(JSON.parse(debateData.examArg));
      setMessages(JSON.parse(debateData.messages));
      setAllSources(JSON.parse(debateData.sources));
      setEvaluations((prev) => prev ? prev : JSON.parse(debateData.evaluations));
      setSelectedPersona(debateData.personaName)
    }
  }, [debateData]);


  const fetchUserAndProfile = async () => {
    try {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    } catch (error) {
      console.error('Error fetching user profile:', error);
      setError('Failed to fetch user profile. Please try again.');
    }
  };

  const signOut = async () => {
    try {
      await handleSignOut();
      setUserAndProfile(null);
      navigate('/'); // Redirect to login page after signing out
    } catch (error) {
      console.error('Error signing out:', error);
      setError('Failed to sign out. Please try again.');
    }
  };
  
  useEffect(() => {
    const adjustFontSize = () => {
      if (evaluationSummaryRef.current && evaluationTextRef.current) {
        let fontSize = 1;
        const maxHeight = evaluationSummaryRef.current.clientHeight;
        evaluationTextRef.current.style.fontSize = `${fontSize}em`;

        while (evaluationTextRef.current.scrollHeight > maxHeight && fontSize > 0.1) {
          fontSize -= 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        }
      }
    };

    adjustFontSize();
    window.addEventListener('resize', adjustFontSize);

    return () => window.removeEventListener('resize', adjustFontSize);
  }, [evaluationSummary]);

  useEffect(() => {
    if (evaluations.length > 0) {
      const scores = calculateCumulativeScores(evaluations);
      setCumulativeScore(scores[scores.length - 1]);
      setEvaluationSummary(evaluations[evaluations.length - 1].summary);
    }
  }, [evaluations]);

  const amplifiedPercentage =
    (Math.sign(cumulativeScore) *
      Math.pow(Math.abs(cumulativeScore) / 100, 0.7) +
      1) *
    50;

  const isForWinning = cumulativeScore >= 0;
  const displayScore = getDisplayScore(cumulativeScore);

  const toggleAdditionalSources = () => {
    setShowAdditionalSources(!showAdditionalSources);
  };

  if (!debateData) {
    return <div>Loading...</div>;
  }

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  return (
    <div className="App">
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={signOut} />
      {showEvaluationReport && 
      <EvaluationReportCard 
        fromStoredDebate={true}
        evaluations={evaluations}
        topic={topic}
        personaName={selectedPersona ? selectedPersona.name : "AI"}
        onClose={() => {
          setShowEvaluationReport(false);
          setEvaluations([]);
          setEvaluationSummary("");
          setMessages([]);
          setSelectedPersona("");
          setTopic("");
          setAllSources("");
        }}
        onHide={() => setShowEvaluationReport(false)}
        messages={messages}
        userSide={side}
        summary={evaluationSummary}
        setSummary={setEvaluationSummary}
        id={debateId}
      />}
      {error && <p className={styles.error}>{error}</p>}
      <div className={styles["debate-arena"]}>
        {isCrossExamMode && (
          <>
            <div className={`${styles["cross-exam-sidebar"]} ${sidebarOpen ? styles["sidebar-open"] : ""}`}>
              <p className={`${styles["side-labels"]} ${styles[examSide.toLowerCase()]}`}>
                {examSide === "Examinee"
                  ? "Defend the argument given below"
                  : "Question the argument given below"}
              </p>
              <h3>{examSide === "Examinee" ? "Your" : "Opponent's"} Claim</h3>
              <p>{divArg?.claim}</p>
              <h3>Warrant</h3>
              <p>{divArg?.warrant}</p>
              <h3>Impact</h3>
              <p>{divArg?.impact}</p>
            </div>
            <button className={styles["sidebar-toggle"]} onClick={toggleSidebar}>
              {sidebarOpen ? <ChevronLeft size={20} /> : <ChevronRight size={20} />}
            </button>
          </>
        )}
        <div className={styles["cross-exam-arena"]}>
          <div className={styles["evaluation-bar-container"]}>
            <div className={styles["evaluation-bar"]}>
              <div
                className={styles["evaluation-bar-fill"]}
                style={{ width: `${amplifiedPercentage}%` }}
              >
                {isForWinning && (
                  <span className={styles["score-display-for"]}>
                    {displayScore.toFixed(1)}
                  </span>
                )}
              </div>
              {!isForWinning && (
                <span className={styles["score-display-against"]}>
                  {displayScore.toFixed(1)}
                </span>
              )}
            </div>
          </div>
          {true && (
            <>
              <div className={styles["dynamic-boxes"]}>
                <div className={styles["evaluation-summary"]} ref={evaluationSummaryRef}>
                  <div className={styles["evaluation-summary-text"]} ref={evaluationTextRef}>
                    {currentSummary}
                  </div>
                </div>
                <div className={styles["arena-labels"]}>
                  <span
                    className={`${styles["side-labels"]} ${styles.for} ${
                      side === "For" ? styles.active : ""
                    }`}
                  >
                    For
                  </span>
                  <span
                    className={`${styles["side-labels"]} ${styles.against} ${
                      side === "Against" ? styles.active : ""
                    }`}
                  >
                    Against
                  </span>
                </div>
                <div className={styles["chat-box"]}>
                  <div className={styles["topic-message"]}>{topic}</div>
                  {messages
                    .filter((msg) => msg.role !== "system")
                    .map((msg, index) => (
                      <div
                        key={index}
                        className={`${styles.message} ${styles[msg.role]}`}
                      >
                        {msg.content}
                      </div>
                    ))}
                </div> 
              </div>
              {mode === "Standard" && allSources.length > 0 && (
                <div className={styles["all-sources-container"]}>
                  <h3>Sources:</h3>
                  <div className={styles["sources-grid"]}>
                    {allSources.slice(0, 5).map((roundSources, index) => (
                      <div key={index} className={styles["round-sources"]}>
                        <h4>Round {roundSources.round}</h4>
                        <ul>
                          {roundSources.sources.map((source, sourceIndex) => (
                            <li
                              key={sourceIndex}
                              className={styles["source-item"]}
                            >
                              <a
                                href={source.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                title={source.title}
                              >
                                {source.title.length > 30
                                  ? source.title.substring(0, 30) + "..."
                                  : source.title}
                              </a>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                  {allSources.length > 5 && (
                    <div className={styles["additional-sources"]}>
                      <button
                        className={styles["additional-sources-button"]}
                        onClick={toggleAdditionalSources}
                      >
                        {showAdditionalSources ? "Hide" : "Show"} Additional
                        Sources
                      </button>
                      <div
                        className={`${styles["sources-grid"]} ${
                          showAdditionalSources ? "" : styles.hidden
                        }`}
                      >
                        {allSources.slice(5).map((roundSources, index) => (
                          <div
                            key={index + 5}
                            className={styles["round-sources"]}
                          >
                            <h4>Round {roundSources.round}</h4>
                            <ul>
                              {roundSources.sources.map((source, sourceIndex) => (
                                <li
                                  key={sourceIndex}
                                  className={styles["source-item"]}
                                >
                                  <a
                                    href={source.url}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    title={source.title}
                                  >
                                    {source.title.length > 30
                                      ? source.title.substring(0, 30) + "..."
                                      : source.title}
                                  </a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </div>
        <div 
          className={styles.showEvaluation} 
          onClick={() => setShowEvaluationReport(true)}
        >
          Show Evaluation
        </div>
      </div>
    </div>
  );
};

export default StoredDebate;
