import React, { useState, useEffect, useRef, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { debounce } from "../utils/debounce";
import { modes } from "../constants/debateModes";
import {
  BLITZ_PROMPT,
  PERSONA_BLITZ_PROMPT,
  STANDARD_PROMPT_WITH_SEARCH,
  STANDARD_PROMPT_WITHOUT_SEARCH,
  DEBATER_SYSTEM_PROMPT,
  DEBATER_USER_PROMPT,
  CROSS_EXAMINER_ROLEPLAY_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_USER_PROMPT,
  CROSS_EXAMINER_ROLEPLAY_USER_PROMPT,
  CROSS_EXAMINEE_ROLEPLAY_STARTING_PROMPT,
  BP_PROMPT,
  BP_STARTING_PROMPT
} from "../constants/prompts";
import "../styles/AI.module.css";
import BpDebateArena from "../components/BPDebateArena"
import BpSetupPanel from './BpSetupPanel'
import BpPrep from '../components/BPPrep'
import TopMenuBar from "../components/TopMenuBar";
import {
  streamChatCompletion,
  transcribeAudio as transcribeAudioAPI,
  generateSearchQueries,
  searchExa,
  processSearchResults,
  evaluateArgument,
  generateCxArg,
} from "../utils/api";
import {
  extractClaim, extractImpact, extractWarrant
} from "../utils/crossExamUtils"
import BadResponse from "../components/BadResponse";
import BPEvaluationReportCard from "../components/BPEvaluationReportCard";
import { getCurrentUserAndProfile, sendDebate } from "../queries/Queries"; 
import { DeepgramTranscription } from '../utils/deepgramTranscription';
import { TtsHandler } from "../utils/ttsHandler";

const useSynchronizedMessages = (initialMessages = []) => {
  const [messages, setMessages] = useState(initialMessages);
  const messagesRef = useRef(messages);

  const updateMessages = useCallback(async (updater) => {
    return new Promise(resolve => {
      setMessages(prev => {
        const newMessages = typeof updater === 'function' ? updater(prev) : updater;
        messagesRef.current = newMessages;
        resolve(newMessages);
        return newMessages;
      });
    });
  }, []);

  return [messages, updateMessages, messagesRef];
};

function BPAi() {
  const [topic, setTopic] = useState("");
  const [selectedPersona, setSelectedPersona] = useState("");
  const [side, setSide] = useState("random");
  const [examSide, setExamSide] = useState(null);
  const [debateStarted, setDebateStarted] = useState(false);
  const [animationFinished, setAnimationFinished] = useState(false);
  const [messages, setMessages, messagesRef] = useSynchronizedMessages([]);
  const [input, setInput] = useState("");
  const [isStreaming, setIsStreaming] = useState(false);
  const [mode, setMode] = useState("BP");
  const [currentRound, setCurrentRound] = useState(0);
  const [prepTimeLeft, setPrepTimeLeft] = useState(modes[mode].prepTime);
  const [debateTimeLeft, setDebateTimeLeft] = useState(modes[mode].debateTime);
  const [timerActive, setTimerActive] = useState(false);
  const [turnReady, setTurnReady] = useState(false);
  const [debateConcluded, setDebateConcluded] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const deepgramTranscriptionRef = useRef(null);
  const [autoSubmit, setAutoSubmit] = useState(false);
  const [allSources, setAllSources] = useState([]);
  const [evaluations, setEvaluations] = useState([]);
  const [showEvaluationReport, setShowEvaluationReport] = useState(false);
  const [evaluationSummary, setEvaluationSummary] = useState("");
  const [globalDebateTime, setGlobalDebateTime] = useState(
    modes.Blitz.globalTime
  );
  const globalTimerRef = useRef(null);
  const hasRunAIFirstTurn = useRef(false);


  const mediaRecorderRef = useRef(null);
  // const streamRef = useRef(null);
  const arenaRef = useRef(null);
  const timerRef = useRef(null);
  const chatBoxRef = useRef(null);
  const [invalidAttempts, setInvalidAttempts] = useState(0);
  const [showBadResponse, setShowBadResponse] = useState(false);
  const [isValidResponse, setIsValidResponse] = useState(true);
  const [isInvalidated, setInvalidated] = useState(false);
  const [examArg, setExamArg] = useState("");
  const [isUnacceptable, setUnacceptable] = useState(false);
  const [currIsInvalid, setCurrIsInvalid] = useState(false); // boolean to check if invalid debate prompt, so that we dont update evaluation
  const [crossExaminationRole, setCrossExaminationRole] = useState("Examiner");
  const [divArg, setDivArg] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [stringMessages, setStringMessages] = useState("[]");
  const messageCountRef = useRef(0);
  const [currentDebateId, setCurrentDebateId] = useState(null);
  const [bpInfo, setBpInfo] = useState([])
  const [bpNotes, setBpNotes] = useState("")
  const [motion, setMotion] = useState("")
  const [showPrep, setShowPrep] = useState(false);
  const [prep, setPrep] = useState("")
  const [prepStartTime, setPrepStartTime] = useState(null);


  const navigate = useNavigate();
  const location = useLocation();


  const ttsHandlerRef = useRef(null);

  const sendDebate = async (debateId, mode, messages, topic, position, role, persona, summary, examArg, sources, evaluations) => {
    // Upload debate and get the returned ID
    const newDebateId = await sendDebate(
      debateId || currentDebateId, // Use existing ID if available
      mode,
      messagesRef.current, // Use ref instead of state
      topic,
      position,
      role,
      persona,
      summary,
      examArg,
      sources,
      evaluations
    );
  
    // If this is a new debate (no existing ID), update the currentDebateId
    if (!debateId && !currentDebateId) {
      setCurrentDebateId(newDebateId);
    }
  
    // Create formatted strings for logging
    const formattedMessages = JSON.stringify(messagesRef.current, null, 2);
    const formattedSources = JSON.stringify(sources, null, 2);
    const formattedEvaluations = JSON.stringify(evaluations, null, 2);
        
    console.log(
      "Uploading...\n" +
      `Debate ID: ${newDebateId}\n` +
      `Mode: ${mode}\n` +
      `Topic: ${topic}\n` +
      `Position: ${position}\n` +
      `Role: ${role}\n` +
      `Persona: ${persona}\n` +
      `Summary: ${summary}\n` +
      `Exam Argument: ${examArg}\n` +
      "\nMessages:\n" + formattedMessages + 
      "\nSources:\n" + formattedSources +
      "\nEvaluations:\n" + formattedEvaluations
    );
  
    return newDebateId;
  };

  // Add initialization of TTS handler
  useEffect(() => {
    ttsHandlerRef.current = new TtsHandler(process.env.REACT_APP_DEEPGRAM_API_KEY);
    
    // Cleanup
    return () => {
      if (ttsHandlerRef.current) {
        ttsHandlerRef.current.stop();
      }
    };
  }, []);

  useEffect(() => { 
    if(evaluationSummary !== ""){
      sendDebate(currentDebateId, mode, messages, topic, side, examSide, selectedPersona ? selectedPersona.name : "AI" , evaluationSummary, divArg, allSources, evaluations)
    }
  }, [evaluationSummary]); 

  useEffect(() => {
    if(messages.length == 2){
      setDebateConcluded(true);
      setTimerActive(false);
      setTurnReady(false);
    };
  }, [messages]);


  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleSignOut = () => {
    setUserAndProfile(null);
  };

  useEffect(() => {
    if (currentRound >= modes[mode].rounds.length) {
      setDebateConcluded(true);
      setTimerActive(false);
      setTurnReady(false);
    }
  }, [currentRound, mode]);

  useEffect(() => {
    if ((debateStarted && showPrep) && arenaRef.current) {
      const handleAnimationEnd = () => {
        setAnimationFinished(true);
      };
      arenaRef.current.addEventListener("animationend", handleAnimationEnd);

      return () => {
        if (arenaRef.current) {
          arenaRef.current.removeEventListener(
            "animationend",
            handleAnimationEnd
          );
        }
      };
    }
  }, [debateStarted, showPrep]);

  useEffect(() => {
    if (timerActive) {
      timerRef.current = setInterval(() => {
        // Global timer for Blitz and Cross-Examine
        if (mode === "Blitz" || mode === "Cross-Examine") {
          setGlobalDebateTime((prevTime) => {
            if (prevTime <= 0) {
              clearInterval(timerRef.current);
              setDebateConcluded(true);
              return 0;
            }
            return prevTime - 1;
          });
        }
  
        // Handle prep time and debate time for all modes
        if (!turnReady) {
          const currentTime = Date.now();
          const elapsedSeconds = Math.floor((currentTime - prepStartTime) / 1000);
          const remainingPrepTime = modes[mode].prepTime - elapsedSeconds; // 900 seconds = 15 minutes

          if (remainingPrepTime <= 0) {
            setShowPrep(false);
            handleEndPrep();
            setPrepTimeLeft(0);
            clearInterval(timerRef.current);
            return;
          }

          setPrepTimeLeft(remainingPrepTime);
        }  else {
          setDebateTimeLeft((prev) => {
            if (prev > 0) {
              return prev - 1;
            } else {
              clearInterval(timerRef.current);
              setAutoSubmit(true);
              return 0;
            }
          });
        }
      }, 1000);
    }
    return () => clearInterval(timerRef.current);
  }, [timerActive, turnReady, mode]);

  useEffect(() => {
    if (autoSubmit) {
      handleSubmit(null);
      setAutoSubmit(false);
    }
  }, [autoSubmit]);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight;
    }
  }, [messages]);

  useEffect(() => {
    deepgramTranscriptionRef.current = new DeepgramTranscription(process.env.REACT_APP_DEEPGRAM_API_KEY);
    
    return () => {
      if (isRecording) {
        deepgramTranscriptionRef.current?.disconnect();
      }
    };
  }, []);

  const startRecording = async () => {
    try {
      const connected = await deepgramTranscriptionRef.current.connect((transcript) => {
        setInput(prev => prev + ' ' + transcript);
      });

      if (!connected) {
        console.error('Failed to connect to Deepgram');
        return;
      }

      setIsRecording(true);
    } catch (error) {
      console.error('Error starting recording:', error);
    }
  };

  const stopRecording = () => {
    if (isRecording) {
      deepgramTranscriptionRef.current?.disconnect();
      setIsRecording(false);
    }
  };

  const handleStartDebate = async () => {

    if(side === "random"){
      setSide(prev => (Math.random() < 0.5 ? "opp" : "gov")) 
    }

    setPrepStartTime(Date.now());
    setShowPrep(true);

    setCurrentDebateId(null);
    
    if (!motion.trim()) {
      alert("Please enter a motion to start the debate.");
      return;
    }

    setDebateStarted(true);
    setDebateConcluded(false);
    setAnimationFinished(false);
    setMessages([]);
    setEvaluationSummary('');
    setEvaluations([]);

    const debateId = await sendDebate(null, mode, messages, topic, side, examSide, selectedPersona ? selectedPersona.name : "AI" , null, divArg, allSources, evaluations);
    setCurrentDebateId((prev) => {
      return debateId;
    })
    console.log("DEBATE SENT")
  
    if (mode === "Cross-Examine") {
      setGlobalDebateTime(modes["Cross-Examine"].globalTime);
      if (examSide === "Examinee") {
        // Set up a timer for AI's first turn
        setTimeout(() => {
          //handleAIFirstTurn(crossExamArg, crossExamSide);
        }, modes["Cross-Examine"].prepTime * 1000);
      }
    }
    
    else if(mode === "BP"){
      if(side === "opp"){
        handleAIFirstTurn(null, side);
      }
    }

    else if (mode === "Blitz") {
      setGlobalDebateTime(modes.Blitz.globalTime);
      setTurnReady(true);
    } 
  
    setPrepTimeLeft(modes[mode].prepTime);
    setDebateTimeLeft(modes[mode].debateTime);
    setCurrentRound(0);
    setTimerActive(true);
    setDebateConcluded(false);
    setInvalidAttempts(0);
    setInvalidated(false);
    setUnacceptable(false);
   };

  useEffect(() => {
    if ((debateStarted && mode === "Cross-Examine" && examSide === "Examinee" && examArg) || (debateStarted && mode === "BP" && side === "opp")) {
      hasRunAIFirstTurn.current = true;
      // The AI's first turn is now handled by the timer in handleStartDebate
    }
  }, [debateStarted, mode, examSide, examArg]);
  
  // Reset the ref when the debate is reset or concluded
  useEffect(() => {
    if (!debateStarted) {
      hasRunAIFirstTurn.current = false;
    }
  }, [debateStarted]);

  const handleNextRound = () => {
    if (mode === "Standard") {
      setCurrentRound((prev) => prev + 1);
    }
    setTurnReady(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setTimerActive(true);
  };

  const handleEndPrep = debounce(() => {
    setTurnReady(true);
    setTimerActive(true);
    setDebateTimeLeft(modes[mode].debateTime);
    setPrepTimeLeft(0);
  }, 250);
 
  const handleAIFirstTurn = async (arg, userRole) => {

    setIsStreaming(true);
    setTurnReady(false);
    
    console.log("handleAIFirstTurn has started")

    console.log("The user role argument is: " + userRole)

    let assistantRole = side === "opp" ? "gov" : "opp";

    try {
      const systemPromptWithContext = DEBATER_SYSTEM_PROMPT.replace("{topic}", motion).replace("{modeSpecificGuidelines}", BP_PROMPT.replace("{motion}", motion).replace("{assistantRole}", assistantRole)).replace("{assistantRole}", assistantRole);
      const userPromptWithContext = BP_STARTING_PROMPT;
  
      const stream = await streamChatCompletion(systemPromptWithContext, userPromptWithContext);
  
      const reader = stream.getReader();
      const decoder = new TextDecoder();
  
      let fullResponse = ""
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        let chunk = decoder.decode(value).toString();
        if(chunk.endsWith("[DONE]")){
          chunk = chunk.slice(0,-6)
        }
        fullResponse += chunk;
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage && lastMessage.role === `assistant-${userRole === 'gov' ? 'opp' : 'gov'}`) {
            return [
              ...prev.slice(0, -1),
              { ...lastMessage, content: fullResponse },
            ];
          } else {
            return [
              ...prev,
              { role: `assistant-${userRole === 'gov' ? 'opp' : 'gov'}`, content: fullResponse },
            ];
          }
        });
        if(chunk.endsWith("[DONE]")){
          break
        }
      }

      // Evaluate AI's first turn

      console.log("calling evaluateArgument from handleAIFirst and passing mode")

      let currentMode = mode;

      console.log("mode in handleAIFirst is " + currentMode)

      /** const aiEvaluation = await evaluateArgument(
        topic,
        currentMode,
        [arg],  // Only the initial argument is available for context
        examSide,
        fullResponse,
        side === 'for' ? 'against' : 'for'
      );
      setEvaluations((prev) => [...prev, { ...aiEvaluation, side: side === 'for' ? 'against' : 'for' }]); **/


      console.log("mode after calling evaluateArgument is " + mode)
  
    } catch (error) {
      console.error("Error in AI's first turn:", error);
    } finally {
      setIsStreaming(false);
      setTurnReady(true);
      setTimerActive(true);
      setDebateTimeLeft(modes[mode].debateTime);
    }
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();
    if (debateConcluded) return;


    const userRole = side;

    console.log("WE ARE AT THE BEGINNING OF HANDLE SUBMIT AND userRole is " + userRole)

    const assistantRole = userRole === "opp" ? "gov" : "opp";
    console.log("When we get to handleSubmit, the userRole is " + userRole + " and the assistantRole is " + assistantRole)
    const userMessage = {
      role: `user-${userRole.toLowerCase()}`, // used for css chat styling in DebateArena
      content: input || "(Skipped Turn)",
    };

    console.log(userMessage)

    setMessages((prev) => [...prev, userMessage]);
    console.log("New messages are ")
    console.log(messages)
    setInput("");
    setIsStreaming(true);
    setTimerActive(false);
    setTurnReady(false); //reset validity for new submission

    // if (mode === "Cross-Examine" && userMessage.content === "(Skipped Turn)") {
    //   // Generate a default question for skipped examiner turn
    //   const defaultQuestion = "Can you elaborate on your argument further?";
    //   setMessages((prev) => [...prev, { role: `assistant-${assistantRole}`, content: defaultQuestion }]);
    //   setIsStreaming(false);
    //   setDebateTimeLeft(modes[mode].debateTime);
    //   handleNextRound();
    // }

    // Evaluate user's argument
    // console.log("User Eval");
    /** const userEvaluation = await evaluateArgument(
      topic,
      mode,
      messages.slice(-10).map((msg) => msg.content),
      examSide,
      userMessage.content,
      userRole
    );
    setEvaluations((prev) => [...prev, { ...userEvaluation, side: userRole }]); **/

    try {
      let systemPromptWithContext;
      let userPromptWithContext;
      let modeSpecificGuidelines;
      //   setTimeout(function(){
      //     //do what you need here
      // }, 2000);
      const conversationHistory = messages
        .filter((msg) => msg.role !== "system")
        .map((msg) => `${msg.role.split("-")[0].toUpperCase()}: ${msg.content}`)
        .join("\n\n");

      if (mode === "Blitz") {
        if (selectedPersona) {
          modeSpecificGuidelines = PERSONA_BLITZ_PROMPT.replace(
            "{persona}",
            selectedPersona.name
          ).replace("{personaGuidelines}", selectedPersona.guidelines);
        } else {
          modeSpecificGuidelines = BLITZ_PROMPT;
        }
      } else if (mode === "Standard") {
        // console.log("Generating search queries...");
        const searchQueries = await generateSearchQueries(
          topic,
          modes[mode].rounds[currentRound].name,
          userMessage.content
        );
        // console.log("Generated search queries:", searchQueries);

        // console.log("Performing searches...");
        const searchResults = await Promise.all(
          searchQueries.map((query) => searchExa(query))
        );
        // console.log("Search results received");

        // console.log("Processing search results...");
        const processedResults = searchResults.flatMap(processSearchResults);
        // console.log(`Processed ${processedResults.length} search results`);

        if (processedResults.length > 0) {
          // console.log("Preparing prompt with search results...");
          const topResults = processedResults
            .sort((a, b) => b.score - a.score)
            .slice(0, 3); // Get 3 sources with top scores returned from Exa
          const searchContext = topResults
            .map(
              (result, index) =>
                `[${index + 1}] ${result.title}: ${result.highlight}`
            )
            .join("\n\n");

          setAllSources((prev) => [
            ...prev,
            {
              round: currentRound + 1,
              sources: topResults.map((source) => ({
                title: source.title,
                url: source.url,
              })),
            },
          ]);

          let roundDetails = modes[mode].rounds[currentRound].name;

          modeSpecificGuidelines = STANDARD_PROMPT_WITH_SEARCH.replace(
            "{searchContext}",
            searchContext
          ).replace("{round}", roundDetails);
        } else {
          modeSpecificGuidelines = STANDARD_PROMPT_WITHOUT_SEARCH;
        }
      }

      //At this point the correct prompt has been chosen
      if (mode === "Cross-Examine") {
        const conversationHistory = messages
        .filter((msg) => msg.role !== "system")
        .map((msg) => `${msg.role.split("-")[0].toUpperCase()}: ${msg.content}`)
        .join("\n\n");

        if (examSide === "Examinee") {
          systemPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_PROMPT.replace("{argument}", examArg);
          userPromptWithContext = CROSS_EXAMINEE_ROLEPLAY_USER_PROMPT
            .replace("{conversationHistory}", conversationHistory)
            .replace("{userMessage}", userMessage.content);
        } else {
          systemPromptWithContext = CROSS_EXAMINER_ROLEPLAY_PROMPT.replace("{argument}", examArg);
          userPromptWithContext = CROSS_EXAMINER_ROLEPLAY_USER_PROMPT
            .replace("{conversationHistory}", conversationHistory)
            .replace("{userMessage}", userMessage.content);
        }
      }
      else if(mode === "BP"){
        systemPromptWithContext = DEBATER_SYSTEM_PROMPT.replace("{topic}", motion).replace("{modeSpecificGuidelines}", BP_PROMPT.replace("{motion}", motion).replace("{assistantRole}", assistantRole)).replace("{assistantRole}", assistantRole);
        userPromptWithContext = BP_STARTING_PROMPT;
      }
      else {
        systemPromptWithContext = DEBATER_SYSTEM_PROMPT.replace("{topic}", topic)
        .replace("{assistantRole}", assistantRole)
        .replace("{modeSpecificGuidelines", modeSpecificGuidelines);
        userPromptWithContext = DEBATER_USER_PROMPT.replace("{conversationHistory}", conversationHistory).replace("{userMessage}", userMessage.content);
      }

      // console.log("Streaming chat completion...");
      // console.log("Here?")
      const stream = await streamChatCompletion(systemPromptWithContext, userPromptWithContext);

      let isInvalid = false;
      let fullResponse = "";
      const reader = stream.getReader();
      const decoder = new TextDecoder();
  
      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        let chunk = decoder.decode(value).toString();
        if(chunk.endsWith("[DONE]")){
          chunk = chunk.slice(0,-6)
        }
        fullResponse += chunk;
        setMessages((prev) => {
          const lastMessage = prev[prev.length - 1];
          if (lastMessage && lastMessage.role === `assistant-${userRole === 'gov' ? 'opp' : 'gov'}`) {
            return [
              ...prev.slice(0, -1),
              { ...lastMessage, content: fullResponse },
            ];
          } else {
            return [
              ...prev,
              { role: `assistant-${userRole === 'gov' ? 'opp' : 'gov'}`, content: fullResponse },
            ];
          }
        });
        if(chunk.endsWith("[DONE]")){
          break
        }

        if (fullResponse.startsWith('INVALID DEBATE PROMPT') || fullResponse.startsWith('"INVALID DEBATE PROMPT')) {
          isInvalid = true;
          setIsValidResponse(false);
          setInvalidAttempts((prev) => {
            const newAttempts = prev + 1;
            if (newAttempts >= 3) {
              handleEndDebate();
            }
            return newAttempts;
          });
          setMessages((prev) => prev.slice(0, -2));
          setCurrIsInvalid(true);
          console.log(currIsInvalid)
          setEvaluations((prev) => prev.slice(0, -1));
          // console.log(evaluations);
          setEvaluationSummary("");
          // console.log(evaluationSummary);          
          setShowBadResponse(true);
          break;
        }

        if (fullResponse.startsWith("UNACCEPTABLE DEBATE PROMPT")) {
          isInvalid = true;
          setIsValidResponse(false);
          setMessages((prev) => prev.slice(0, -2));
          setEvaluations((prev) => prev.slice(0, -1));
          setCurrIsInvalid(true);
          setShowBadResponse(true);
          setUnacceptable(true);
          handleEndDebate();
          break;
        }

        if(!isInvalid){
          setMessages((prev) => {
            const lastMessage = prev[prev.length - 1];
            if (lastMessage.role === `assistant-${assistantRole}`) {
              return [
                ...prev.slice(0, -1),
                { ...lastMessage, content: fullResponse },
              ];
            } else {
              return [
                ...prev,
                { role: `assistant-${assistantRole}`, content: fullResponse },
              ];
            }
          });
        }
      }


      if (!isInvalid) {
        // evaluate LLM's argument
        // console.log("LLM Eval");
        console.log(currIsInvalid);
        const llmEvaluation = await evaluateArgument(
          topic,
          mode,
          messages.slice(-11, -1).map((msg) => msg.content),
          examSide,
          fullResponse,
          assistantRole
        );
        // console.log(messages.slice(-11, -1).map((msg) => msg.content));
        setEvaluations((prev) => [
          ...prev,
          { ...llmEvaluation, side: assistantRole },
        ]);
      }
    } catch (error) {
      console.error("Error in debate process:", error);
    } finally {
      setIsStreaming(false);
      if (isValidResponse) {
        if (mode === "Blitz" || mode === "Cross-Examine") {
          setDebateTimeLeft(modes[mode].debateTime);
          handleNextRound();
        } else if (currentRound < modes[mode].rounds.length - 1) {
          handleNextRound();
        } else {
          setDebateConcluded(true);
          setTimerActive(false);
          setTurnReady(false);
        }
      } else {
        // Reset state for next attempt
        setTurnReady(true);
        setTimerActive(true);
        setDebateTimeLeft(modes[mode].debateTime);
      }
    }
    sendDebate(currentDebateId, mode, messages, topic, side, examSide, selectedPersona ? selectedPersona.name : "AI" , null, divArg)
  };

  const handleEndDebate = () => {
    setDebateConcluded(true);
    if (invalidAttempts === 3) {
      setInvalidated(true);
    }
    setTurnReady(false);
    setTimerActive(false);
    if (globalTimerRef.current) {
      clearInterval(globalTimerRef.current);
    }
    setGlobalDebateTime(0);
    setInput("");
    setPrepTimeLeft(0);
    setDebateTimeLeft(0);
    setShowEvaluationReport(true);
  };

  return (
    <div className="App">
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={handleSignOut} />
      <div className="main-content">
        {!debateStarted && (
          <BpSetupPanel
            mode={mode}
            motion={motion}
            setMotion={setMotion}
            setMode={setMode}
            topic={topic}
            bpInfo={bpInfo}
            setBpInfo={setBpInfo}
            setTopic={setTopic}
            side={side}
            setSide={setSide}
            debateStarted={debateStarted}
            handleStartDebate={handleStartDebate}
            setSelectedPersona={setSelectedPersona}
            crossExaminationRole={crossExaminationRole}
            setCrossExaminationRole={setCrossExaminationRole}
            examSide={examSide}
            setExamSide={setExamSide} 
          />
        )}
        {(debateStarted && showPrep) && 
        (<BpPrep
            prepTimeLeft={prepTimeLeft}
            motion={motion}
            side={side}
            bpInfo={bpInfo}
            setShowPrep={setShowPrep}
            handleEndPrep={handleEndPrep}
            prep={prep}
            setPrep={setPrep} />) }
        {(debateStarted && !showPrep) && (
          <BpDebateArena
            bpInfo={bpInfo}
            arenaRef={arenaRef}
            animationFinished={animationFinished}
            examSide={examSide}
            side={side}
            examArg={examArg}
            topic={motion}
            mode={mode}
            currentRound={currentRound}
            messages={messages}
            chatBoxRef={chatBoxRef}
            turnReady={turnReady}
            debateConcluded={debateConcluded}
            prepTimeLeft={prepTimeLeft}
            debateTimeLeft={debateTimeLeft}
            input={input}
            divArg={divArg}
            setInput={setInput}
            isStreaming={isStreaming}
            isRecording={isRecording}
            handleSubmit={handleSubmit}
            handleEndPrep={handleEndPrep}
            startRecording={startRecording}
            stopRecording={stopRecording}
            handleEndDebate={handleEndDebate}
            allSources={allSources}
            globalDebateTime={globalDebateTime}
            evaluations={evaluations}
            showEvaluationReport={showEvaluationReport}
            prep={prep}
            ssetPrep={setPrep}
            ttsHandler={ttsHandlerRef.current}
          />
        )}
        {showBadResponse && (
          <BadResponse
            unacceptable={isUnacceptable}
            invalidated={isInvalidated}
            attemptsLeft={invalidAttempts}
            onClose={() => setShowBadResponse(false)}
          />
        )}
        {showEvaluationReport && (
          <BPEvaluationReportCard
            fromStoredDebate={false}
            evaluations={evaluations}
            topic={topic}
            personaName={selectedPersona ? selectedPersona.name : "AI"}
            onClose={() => {
              setShowEvaluationReport(false);
              setEvaluations([]);
              setEvaluationSummary("");
              setMessages([]);
              setSelectedPersona("");
              setTopic("");
              setDebateStarted(false);
              setAnimationFinished(false);
              setAllSources("");
            }}
            onHide={() => setShowEvaluationReport(false)}
            messages={messages}
            userSide={side}
            summary={evaluationSummary}
            setSummary={setEvaluationSummary}
          />
        )}
      </div>
    </div>
  );
}

export default BPAi;