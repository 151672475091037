import React, { useState, useEffect, useRef } from 'react';
import styles from '../styles/TopicCard.module.css';
import { deleteDebate } from '../queries/Queries';

const TopicCard = ({ id, onClick, didUserWin, opponent, topic, date, onDelete }) => {
  const [showMenu, setShowMenu] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [error, setError] = useState('');
  const menuRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showMenu && 
        menuRef.current && 
        !menuRef.current.contains(event.target) &&
        !buttonRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showMenu]);

  const handleDelete = async () => {
    try {
      if (!id) {
        throw new Error('Debate ID is missing');
      }

      const result = await deleteDebate(id);
      if (result.success) {
        onDelete && onDelete();
        setShowDeleteModal(false);
        setShowMenu(false);
        setError('');
      } else {
        throw new Error(result.error || 'Failed to delete debate');
      }
    } catch (err) {
      console.error('Error deleting debate:', err);
      setError('Failed to delete debate. Please try again.');
    }
  };

  const toggleMenu = (e) => {
    e.stopPropagation();
    setShowMenu(!showMenu);
  };

  const openDeleteModal = (e) => {
    e.stopPropagation();
    setError('');
    setShowDeleteModal(true);
    setShowMenu(false);
  };

  return (
    <>
      <div
        className={`${styles.card} ${
          didUserWin ? styles.winningCard : styles.losingCard
        }`}
      >
        <div className={styles.cardContent}>
          <div className={styles.header}>
            <span className={styles.date}>{date}</span>
            <div className={styles.headerRight}>
              <span className={`${styles.status} ${didUserWin ? styles.won : styles.lost}`}>
                {didUserWin ? 'Won' : 'Lost'}
              </span>
              <div className={styles.menuContainer}>
                <button 
                  ref={buttonRef}
                  onClick={toggleMenu} 
                  className={styles.menuButton}
                >
                  <span className={styles.dots}>•••</span>
                </button>
                {showMenu && (
                  <div ref={menuRef} className={styles.menu}>
                    <button onClick={openDeleteModal} className={styles.menuItem}>
                      Delete
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          <h3 className={styles.topic}>{topic}</h3>
          <div className={styles.footer}>
            <p className={styles.opponent}>
              Opponent: <span className={styles.opponentName}>{opponent}</span>
            </p>
            <a onClick={onClick} className={styles.viewLink}>
              View
            </a>
          </div>
        </div>
      </div>

      {showDeleteModal && (
        <div 
          className={styles.modalOverlay} 
          onClick={(e) => {
            if (e.target === e.currentTarget) {
              setShowDeleteModal(false);
            }
          }}
        >
          <div className={styles.modal}>
            <div className={styles.modalContent}>
              <h3 className={styles.modalTitle}>Delete this debate?</h3>
              <p className={styles.modalText}>
                This action cannot be reversed. All debate data will be permanently deleted.
              </p>
              {error && <p className={styles.modalError}>{error}</p>}
              <div className={styles.modalButtons}>
                <button
                  onClick={() => {
                    setShowDeleteModal(false);
                    setError('');
                  }}
                  className={styles.modalCancelButton}
                >
                  Cancel
                </button>
                <button
                  onClick={handleDelete}
                  className={styles.modalDeleteButton}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default TopicCard;