import React, { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import TopMenuBar from "../components/TopMenuBar";
import { getCurrentUserAndProfile, handleSignOut } from "../queries/Queries.js";
import { Bot, Swords, Landmark, Users, ArrowRight, LogIn } from "lucide-react";
import styles from "../styles/LandingPage.module.css";
import DiscordPopup from "../components/DiscordPopup";
import einsteinAvatar from '../assets/einstein.jpeg';
import nietzscheAvatar from '../assets/nietzsche.jpeg';
import philosopherAvatar from '../assets/philosopher.jpeg';

const PersonaCarousel = () => {
  return (
    <div className={styles.personaCarousel}>
      <img 
        src={einsteinAvatar} 
        alt="Einstein" 
        className={`${styles.personaIcon} ${styles.sidePersona} ${styles.leftPersona}`}
      />
      <img 
        src={nietzscheAvatar} 
        alt="Nietzsche" 
        className={`${styles.personaIcon} ${styles.centerPersona}`}
      />
      <img 
        src={philosopherAvatar} 
        alt="Philosopher" 
        className={`${styles.personaIcon} ${styles.sidePersona} ${styles.rightPersona}`}
      />
    </div>
  );
}

const LandingPage = () => {
  const navigate = useNavigate();
  const [userAndProfile, setUserAndProfile] = useState(null);

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  const handleSignOutClick = async () => {
    const success = await handleSignOut();
    if (success) {
      setUserAndProfile(null);
      navigate("/");
    }
  };

  const handleSignUpSubmit = () => {
    navigate("/signup");
  };

  const handlePlayOnline = () => {
    if (userAndProfile) {
      navigate("/challenge");
    } else {
      navigate("/signup");
    }
  };

  const handlePracticeAI = () => {
    if (userAndProfile) {
      navigate("/ai");
    } else {
      navigate("/signup");
    }
  };

  const handleBP = () => {
    if (userAndProfile) {
      navigate("/bpai");
    } else {
      navigate("/signup");
    }
  };

  return (
    <div className={styles.landingPage}>
      <TopMenuBar
        userAndProfile={userAndProfile}
        onSignOut={handleSignOutClick}
      />
      <DiscordPopup />
      
      <main className={styles.mainContent}>
        <section className={styles.heroSection}>
          <div className={styles.heroContent}>
            <h1>chess.com, but for debate</h1>
            <p className={styles.subtitle}>
              Practice, improve, and compete in online debate with an AI judge
            </p>

            {userAndProfile ? (
              // Logged in state - show both options
              <div className={styles.buttonGroup}>
                <button
                  className={styles.greenButton}
                  onClick={handlePlayOnline}
                >
                  <Swords size={20} className={styles.buttonIcon} />
                  Challenge Online
                </button>
                <button
                  className={styles.playButton}
                  onClick={handlePracticeAI}
                >
                  <Bot size={20} className={styles.buttonIcon} />
                  Practice vs AI
                </button>
              </div>
            ) : (
              // Logged out state - emphasize AI practice
              <div className={styles.buttonGroup}>
                <button
                  className={styles.greenButton}
                  onClick={handleSignUpSubmit}
                >
                  <LogIn size={20} className={styles.buttonIcon} />
                  Sign up to try!
                </button>
                <button
                  className={styles.playButton}
                  onClick={() => navigate("/login")}
                >
                  Login
                </button>
              </div>
            )}
          </div>

          <div className={styles.featuresGrid}>
            <div
              className={`${styles.featureCard} ${styles.onlineFeature}`}
              onClick={handlePlayOnline}
            >
              <div className={styles.featureIcon}>
                <Swords size={24} />
                <div className={styles.newBadge}>Live</div>
              </div>
              <h3>Debate Online</h3>
              <p>Challenge opponents in live debates</p>
            </div>

            <div
              className={styles.featureCard}
              onClick={handlePracticeAI}
            >
              <div className={styles.featureIcon}>
                <PersonaCarousel />
              </div>
              <h3>Practice vs AI</h3>
              <p>Debate AI personas on any topic</p>
            </div>

            <div
              className={styles.featureCard}
              onClick={handleBP}
            >
              <div className={styles.featureIcon}>
                <Landmark size={24} />
              </div>
              <h3>British Parliamentary</h3>
              <p>Verse the AI in a mini BP debate on any motion</p>
            </div>
          </div>
        </section>
      </main>

      <footer className={styles.footer}>
        <Link to="/terms-of-use" className={styles.footerLink}>
          terms of use
        </Link>
        <span className={styles.footerSeparator}>|</span>
        <Link to="/privacy-policy" className={styles.footerLink}>
          privacy policy
        </Link>
      </footer>
    </div>
  );
};

export default LandingPage;