import React, { useState, useEffect, useRef } from "react";
import {
  Mic,
  Square,
  ChevronLeft,
  ChevronRight,
  FileText,
  Trophy,
} from "lucide-react";
import { modes } from "../constants/debateModes";
import styles from "../styles/DebateArena.module.css";
import {
  calculateCumulativeScores,
  getDisplayScore,
} from "../utils/scoreUtils";
import Message from "./AIMessageComponent";
import EvaluationTooltip from "./EvaluationTooltip";
import { personas } from "../constants/personas";

function DebateArena({
  arenaRef,
  animationFinished,
  examSide,
  side,
  topic,
  mode,
  currentRound,
  divArg,
  examArg,
  messages,
  chatBoxRef,
  turnReady,
  debateConcluded,
  prepTimeLeft,
  debateTimeLeft,
  input,
  setInput,
  isStreaming,
  isRecording,
  handleSubmit,
  handleEndPrep,
  startRecording,
  stopRecording,
  handleEndDebate,
  allSources,
  globalDebateTime,
  evaluations,
  showEvaluationReport,
  handleAIFirstTurn,
  showAIFirstTurn,
  setShowAIFirstTurn,
  persona,
  isSubmitting,
}) {
  const isBlitzMode = mode === "Blitz";
  const isCrossExamMode = mode === "Cross-Examine";

  const [showAdditionalSources, setShowAdditionalSources] = useState(false);
  const [cumulativeScore, setCumulativeScore] = useState(0);
  const [currentSummary, setCurrentSummary] = useState("");
  const evaluationSummaryRef = useRef(null);
  const evaluationTextRef = useRef(null);
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    const adjustFontSize = () => {
      if (evaluationSummaryRef.current && evaluationTextRef.current) {
        let fontSize = 1; // Start fresh at 1em each time
        evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        const maxHeight = evaluationSummaryRef.current.clientHeight;

        // If text is too big, reduce size until it fits
        while (
          evaluationTextRef.current.scrollHeight > maxHeight &&
          fontSize > 0.1
        ) {
          fontSize -= 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;
        }

        // If text is smaller than necessary, increase size until it just fits
        while (
          fontSize < 1 &&
          evaluationTextRef.current.scrollHeight <= maxHeight
        ) {
          fontSize += 0.1;
          evaluationTextRef.current.style.fontSize = `${fontSize}em`;

          // Check if increasing once more would overflow
          if (evaluationTextRef.current.scrollHeight > maxHeight) {
            fontSize -= 0.1; // Go back one step
            evaluationTextRef.current.style.fontSize = `${fontSize}em`;
            break;
          }
        }
      }
    };

    adjustFontSize();
    window.addEventListener("resize", adjustFontSize);

    return () => window.removeEventListener("resize", adjustFontSize);
  }, [currentSummary]);

  useEffect(() => {
    if (evaluations.length > 0) {
      const scores = calculateCumulativeScores(evaluations);
      setCumulativeScore(scores[scores.length - 1]);
      setCurrentSummary(evaluations[evaluations.length - 1].summary);
    }
  }, [evaluations]);

  const amplifiedPercentage =
    (Math.sign(cumulativeScore) *
      Math.pow(Math.abs(cumulativeScore) / 100, 0.7) +
      1) *
    50;

  const isForWinning = cumulativeScore >= 0;
  const displayScore = getDisplayScore(cumulativeScore);

  const toggleAdditionalSources = () => {
    setShowAdditionalSources(!showAdditionalSources);
  };

  const isBlinking = (time) => time <= 3;

  const handleKeyDown = (e) => {
    // Prevent any submission if already submitting or streaming
    if (
      e.key === "Enter" &&
      !e.shiftKey &&
      !isSubmitting &&
      !isStreaming &&
      turnReady
    ) {
      e.preventDefault();
      // Add local debounce as backup
      if (
        lastSubmitTime.current &&
        Date.now() - lastSubmitTime.current < 1000
      ) {
        return;
      }
      lastSubmitTime.current = Date.now();
      handleSubmit(e);
    }
  };

  // Add ref for tracking last submit time
  const lastSubmitTime = useRef(null);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const UserAvatar = () => (
    <svg
      viewBox="0 0 24 24"
      className={styles["user-avatar"]}
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
    >
      <circle cx="12" cy="8" r="5" />
      <path d="M20 21a8 8 0 1 0-16 0" />
    </svg>
  );

  return (
    <div className={styles["debate-arena"]} ref={arenaRef}>
      {isCrossExamMode && (
        <>
          <div
            className={`${styles["cross-exam-sidebar"]} ${
              sidebarOpen ? styles["sidebar-open"] : ""
            }`}
          >
            <p
              className={`${styles["side-labels"]} ${
                styles[examSide.toLowerCase()]
              }`}
            >
              {examSide === "Examinee"
                ? "Defend the argument given below"
                : "Question the argument given below"}
            </p>
            <h3>{examSide === "Examinee" ? "Your" : "Opponent's"} Claim</h3>
            <p>{divArg?.claim}</p>
            <h3>Warrant</h3>
            <p>{divArg?.warrant}</p>
            <h3>Impact</h3>
            <p>{divArg?.impact}</p>
          </div>
          <button className={styles["sidebar-toggle"]} onClick={toggleSidebar}>
            {sidebarOpen ? (
              <ChevronLeft size={20} />
            ) : (
              <ChevronRight size={20} />
            )}
          </button>
        </>
      )}
      <div className={styles["cross-exam-arena"]}>
        <div className={styles["evaluation-bar-container"]}>
          <EvaluationTooltip />
          <div className={styles["evaluation-bar"]}>
            <div
              className={styles["evaluation-bar-fill"]}
              style={{ width: `${amplifiedPercentage}%` }}
            >
              {isForWinning && (
                <span className={styles["score-display-for"]}>
                  {displayScore.toFixed(1)}
                </span>
              )}
            </div>
            {!isForWinning && (
              <span className={styles["score-display-against"]}>
                {displayScore.toFixed(1)}
              </span>
            )}
          </div>
        </div>
        {true && (
          <>
            <div className={styles["dynamic-boxes"]}>
              <div
                className={styles["evaluation-summary"]}
                ref={evaluationSummaryRef}
              >
                <div
                  className={styles["evaluation-summary-text"]}
                  ref={evaluationTextRef}
                >
                  {currentSummary}
                </div>
              </div>

              <div className={styles["chat-box"]} ref={chatBoxRef}>
                <div className={styles["topic-row"]}>
                  <span
                    className={`${styles["side-labels"]} ${styles.for} ${
                      side.toLowerCase() === "for" ? styles.active : ""
                    }`}
                  >
                    <div className={styles["label-content"]}>
                      {side.toLowerCase() === "for" ? (
                        <UserAvatar />
                      ) : (
                        <img
                          src={
                            persona
                              ? personas[persona.id - 1].avatar
                              : personas[0].avatar
                          }
                          className={styles["persona-avatar"]}
                        />
                      )}
                      <span>For</span>
                    </div>
                  </span>

                  <div className={styles["topic-message"]}>{topic}</div>

                  <span
                    className={`${styles["side-labels"]} ${styles.against} ${
                      side.toLowerCase() === "against" ? styles.active : ""
                    }`}
                  >
                    <div className={styles["label-content"]}>
                      <span>Opp</span>
                      {side.toLowerCase() === "against" ? (
                        <UserAvatar />
                      ) : (
                        <img
                          src={
                            persona
                              ? personas[persona.id - 1].avatar
                              : personas[0].avatar
                          }
                          className={styles["persona-avatar"]}
                        />
                      )}
                    </div>
                  </span>
                </div>

                {mode === "Standard" && (
                  <div className={styles["round-message"]}>
                    {modes[mode].rounds[currentRound].name}
                  </div>
                )}

                {mode === "Blitz" && showAIFirstTurn && (
                  <button
                    className={styles["ai-assist-button"]}
                    onClick={handleAIFirstTurn}
                    disabled={isStreaming || !turnReady || debateConcluded}
                  >
                    Don't know what to say? Click here to let AI go first
                  </button>
                )}

                {messages
                  .filter((msg) => msg.role !== "system")
                  .map((msg, index) => (
                    <Message key={index} msg={msg} />
                  ))}
              </div>
            </div>

            <form
              onSubmit={(e) => {
                e.preventDefault(); // Add this to prevent form submission
                handleSubmit(e);
              }}
              className={styles["argument-form"]}
            >
              {isBlitzMode || (isCrossExamMode && turnReady) ? (
                <div className={styles["timer-display"]}>
                  Debate:{" "}
                  <span
                    className={
                      isBlinking(globalDebateTime) ? styles["timer-blink"] : ""
                    }
                  >
                    {globalDebateTime}s
                  </span>
                  {turnReady && globalDebateTime > debateTimeLeft && (
                    <div className={styles["turn-time"]}>
                      Turn:{" "}
                      <span
                        className={
                          isBlinking(debateTimeLeft)
                            ? styles["timer-blink"]
                            : ""
                        }
                      >
                        {debateTimeLeft}s
                      </span>
                    </div>
                  )}
                </div>
              ) : (
                <>
                  {!turnReady && !debateConcluded && !isStreaming && (
                    <div className={styles["timer-display"]}>
                      Prep:{" "}
                      <span
                        className={
                          isBlinking(prepTimeLeft) ? styles["timer-blink"] : ""
                        }
                      >
                        {prepTimeLeft}s
                      </span>
                    </div>
                  )}
                  {turnReady && !debateConcluded && (
                    <div className={styles["timer-display"]}>
                      Debate:{" "}
                      <span
                        className={
                          isBlinking(debateTimeLeft)
                            ? styles["timer-blink"]
                            : ""
                        }
                      >
                        {debateTimeLeft}s
                      </span>
                    </div>
                  )}
                </>
              )}

              <textarea
                id={styles["argument-input"]}
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
                disabled={
                  isStreaming || !turnReady || debateConcluded || isRecording
                }
                placeholder={
                  isStreaming
                    ? ""
                    : turnReady
                    ? "Type your argument OR use Voice input."
                    : "Use this time to prepare your arguments"
                }
                rows="4"
              />

              {turnReady && !debateConcluded && (
                <div className={styles["recording-controls"]}>
                  {!isRecording ? (
                    <button
                      type="button"
                      onClick={startRecording}
                      disabled={isStreaming || !turnReady || debateConcluded}
                      className={styles["record-button"]}
                    >
                      <Mic size={24} />
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={stopRecording}
                      className={styles["stop-record-button"]}
                    >
                      <Square size={24} />
                    </button>
                  )}
                </div>
              )}

              {isStreaming ? (
                <button disabled>Waiting for Opponent</button>
              ) : (mode === "Standard" || mode === "Cross-Examine") &&
                !turnReady &&
                !debateConcluded ? (
                <button
                  type="button"
                  onClick={handleEndPrep}
                  disabled={debateConcluded}
                >
                  End Prep
                </button>
              ) : (
                <button
                  type="submit"
                  disabled={
                    !turnReady || debateConcluded || isRecording || isSubmitting
                  }
                >
                  {isSubmitting ? "..." : "Send"}
                </button>
              )}
            </form>

            {!isStreaming && (
              <div className={styles["bottom-controls"]}>
                {debateConcluded && !showEvaluationReport ? (
                  <button
                    className={styles["more-options-button"]}
                    onClick={handleEndDebate}
                  >
                    <FileText size={16} />
                    Show Evaluation Report
                  </button>
                ) : (
                  !debateConcluded && (
                    <button
                      className={styles["more-options-button"]}
                      onClick={handleEndDebate}
                    >
                      <Trophy size={16} />
                      End & Reveal Winner
                    </button>
                  )
                )}
              </div>
            )}

            {mode === "Standard" && allSources.length > 0 && (
              <div className={styles["all-sources-container"]}>
                <h3>Sources:</h3>
                <div className={styles["sources-grid"]}>
                  {allSources.slice(0, 5).map((roundSources, index) => (
                    <div key={index} className={styles["round-sources"]}>
                      <h4>Round {roundSources.round}</h4>
                      <ul>
                        {roundSources.sources.map((source, sourceIndex) => (
                          <li
                            key={sourceIndex}
                            className={styles["source-item"]}
                          >
                            <a
                              href={source.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              title={source.title}
                            >
                              {source.title.length > 30
                                ? source.title.substring(0, 30) + "..."
                                : source.title}
                            </a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {allSources.length > 5 && (
                  <div className={styles["additional-sources"]}>
                    <button
                      className={styles["additional-sources-button"]}
                      onClick={toggleAdditionalSources}
                    >
                      {showAdditionalSources ? "Hide" : "Show"} Additional
                      Sources
                    </button>
                    <div
                      className={`${styles["sources-grid"]} ${
                        showAdditionalSources ? "" : styles.hidden
                      }`}
                    >
                      {allSources.slice(5).map((roundSources, index) => (
                        <div
                          key={index + 5}
                          className={styles["round-sources"]}
                        >
                          <h4>Round {roundSources.round}</h4>
                          <ul>
                            {roundSources.sources.map((source, sourceIndex) => (
                              <li
                                key={sourceIndex}
                                className={styles["source-item"]}
                              >
                                <a
                                  href={source.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  title={source.title}
                                >
                                  {source.title.length > 30
                                    ? source.title.substring(0, 30) + "..."
                                    : source.title}
                                </a>
                              </li>
                            ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default DebateArena;
