import { useEffect } from 'react';
import styles from '../styles/CustomRedirect.module.css';

const CustomRedirect = () => {
  useEffect(() => {
    window.location.href = 'https://www.debatearena.app/home';
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <h1 className={styles.heading}>Redirecting...</h1>
        <p className={styles.text}>Please wait while we redirect you to Debate Arena.</p>
      </div>
    </div>
  );
};

export default CustomRedirect;