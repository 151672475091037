import React from 'react';
import styles from '../styles/DebateArena.module.css';

const PvPMessage = ({ msg }) => {
  // Map PvP messages to match AI debate classes
  const className = `${styles.message} ${styles[`user-${msg.side.toLowerCase()}`]}`;

  return (
    <div className={`${styles['message-container']} ${styles[msg.side.toLowerCase()]}`}>
      <div className={className}>
        {msg.content}
      </div>
    </div>
  );
};

export default PvPMessage;