import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { SortAsc, Filter, Calendar, MessageSquare, Users, Eye } from 'lucide-react';
import { getUserStoredDebates, getProfileData, getCurrentUserAndProfile, updateBio, handleSignOut } from '../queries/Queries';
import TopMenuBar from '../components/TopMenuBar';
import TopicCard from '../components/TopicCard';
import FriendsTab from '../components/FriendsTab';
import SearchBar from '../components/SearchBar';
import PaginationControls from '../components/PaginationControls';
import styles from '../styles/ProfilePage.module.css';

const StatItem = ({ icon, label, value }) => (
  <div className={styles.statItem}>
    <div className={styles.statIcon}>
      {icon}
    </div>
    <span className={styles.statLabel}>{label}</span>
    <span className={styles.statValue}>{value}</span>
  </div>
);

const formatDateForSearch = (dateString) => {
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return '';
  
  const month = date.toLocaleString('en-US', { month: 'short' }).toLowerCase();
  const fullMonth = date.toLocaleString('en-US', { month: 'long' }).toLowerCase();
  const day = date.getDate();
  const year = date.getFullYear();
  const numericMonth = date.getMonth() + 1;

  return [
    `${month} ${day}`,
    `${month} ${day} ${year}`,
    `${fullMonth} ${day}`,
    `${fullMonth} ${day} ${year}`,
    `${numericMonth}/${day}`,
    `${numericMonth}-${day}`,
    `${numericMonth}/${day}/${year}`,
    `${numericMonth}-${day}-${year}`,
  ].join('|').toLowerCase();
};

const isDateMatch = (dateString, searchText) => {
  const dateFormats = formatDateForSearch(dateString);
  const searchLower = searchText.toLowerCase();
  const potentialDateFormats = formatDateForSearch(searchText);
  return dateFormats.includes(searchLower) || potentialDateFormats.includes(dateFormats);
};

const TabButton = ({ active, onClick, children }) => (
  <button
    onClick={onClick}
    className={`${styles.tabButton} ${active ? styles.activeTab : ''}`}
  >
    {children}
    {active && <div className={styles.tabIndicator}></div>}
  </button>
);

const SearchAndFilter = ({ searchQuery, setSearchQuery, sortOption, setSortOption, filterOption, setFilterOption }) => (
  <div className={styles.filterContainer}>
    <div className={styles.filterControls}>
      <SearchBar onSearch={setSearchQuery} initialValue={searchQuery} />

      <div className={styles.selectWrapper}>
        <SortAsc className={styles.selectIcon} size={20} />
        <select
          value={sortOption}
          onChange={(e) => setSortOption(e.target.value)}
          className={styles.select}
        >
          <option value="newest">Newest First</option>
          <option value="oldest">Oldest First</option>
        </select>
      </div>

      <div className={styles.selectWrapper}>
        <Filter className={styles.selectIcon} size={20} />
        <select
          value={filterOption}
          onChange={(e) => setFilterOption(e.target.value)}
          className={styles.select}
        >
          <option value="all">All Debates</option>
          <option value="won">Won</option>
          <option value="lost">Lost</option>
        </select>
      </div>
    </div>
  </div>
);

const ProfilePage = () => {
  const [profileData, setProfileData] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [storedDebates, setStoredDebates] = useState([]);
  const [searchParams] = useSearchParams();
  const initialTab = searchParams.get('tab') || 'debates';
  const [activeTab, setActiveTab] = useState(initialTab);
  const [searchQuery, setSearchQuery] = useState('');
  const [sortOption, setSortOption] = useState('newest');
  const [filterOption, setFilterOption] = useState('all');
  const [currentPage, setCurrentPage] = useState(1);
  const ITEMS_PER_PAGE = 10;
  const [isEditingStatus, setIsEditingStatus] = useState(false);
  const [statusText, setStatusText] = useState('');
  const [reloadTrigger, setReloadTrigger] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProfileData();
    fetchUserAndProfile();
  }, [reloadTrigger]);

  const fetchProfileData = async () => {
    const data = await getProfileData();
    const debates = await getUserStoredDebates();
    if (data) {
      setProfileData(data);
      setStoredDebates(debates);
      setStatusText(data.bio || '');
    }
  };

  const fetchUserAndProfile = async () => {
    const result = await getCurrentUserAndProfile();
    setUserAndProfile(result);
  };

  const handleDebateDeleted = () => {
    setReloadTrigger(prev => prev + 1);
  };

  const handleSaveStatus = async () => {
    try {
      const result = await updateBio(statusText);
      if (result) {
        await fetchProfileData();
        setIsEditingStatus(false);
      }
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    return date.toLocaleDateString('en-US', options);
  };

  const getFilteredAndSortedDebates = () => {
    let filtered = [...storedDebates];

    if (searchQuery) {
      filtered = filtered.filter(debate => {
        const searchLower = searchQuery.toLowerCase();
        return (
          debate.topic.toLowerCase().includes(searchLower) ||
          (debate.persona && debate.persona.toLowerCase().includes(searchLower)) ||
          isDateMatch(debate.created_at, searchQuery)
        );
      });
    }

    if (filterOption === 'won') {
      filtered = filtered.filter(debate => debate.debate_result_id === 1);
    } else if (filterOption === 'lost') {
      filtered = filtered.filter(debate => debate.debate_result_id !== 1);
    }

    filtered.sort((a, b) => {
      switch (sortOption) {
        case 'oldest':
          return new Date(a.created_at) - new Date(b.created_at);
        case 'newest':
          return new Date(b.created_at) - new Date(a.created_at);
        default:
          return 0;
      }
    });

    return filtered;
  };

  const getPaginatedDebates = () => {
    const filtered = getFilteredAndSortedDebates();
    const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    return filtered.slice(startIndex, endIndex);
  };

  const getTotalPages = () => {
    return Math.ceil(getFilteredAndSortedDebates().length / ITEMS_PER_PAGE);
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchQuery, sortOption, filterOption]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
    document.getElementById('debates-section')?.scrollIntoView({ behavior: 'smooth' });
  };

  const signOut = () => {
    handleSignOut();
    setUserAndProfile(null);
  };

  const TabContent = () => {
    switch (activeTab) {
      case 'debates':
        return (
          <div className={styles.tabContent}>
            <SearchAndFilter
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              sortOption={sortOption}
              setSortOption={setSortOption}
              filterOption={filterOption}
              setFilterOption={setFilterOption}
            />
            <div id="debates-section" className={styles.debatesSection}>
              {getPaginatedDebates().map(debate => (
                <TopicCard
                  id={debate.id}
                  key={debate.id}
                  onClick={() => navigate(`/stored-debates/${debate.id}`)}
                  didUserWin={debate.debate_result_id === 1}
                  opponent={debate.opp_user_id == null ? debate.persona : debate.opp_user_id}
                  topic={debate.topic}
                  date={formatDate(debate.created_at)}
                  onDelete={handleDebateDeleted}
                />
              ))}
              {getFilteredAndSortedDebates().length > ITEMS_PER_PAGE && (
                <PaginationControls
                  currentPage={currentPage}
                  totalPages={getTotalPages()}
                  onPageChange={handlePageChange}
                />
              )}
              {getFilteredAndSortedDebates().length === 0 && (
                <p className={styles.noResults}>No debates found matching your criteria.</p>
              )}
            </div>
          </div>
        );
      case 'stats':
        return (
          <div className={styles.tabContent}>
            <p className={styles.comingSoon}>Statistics coming soon...</p>
          </div>
        );
      case 'friends':
        return <FriendsTab />;
      default:
        return null;
    }
  };

  if (!profileData) {
    return <div className={styles.loading}>Loading...</div>;
  }

  return (
    <div className={styles.container}>
      <TopMenuBar userAndProfile={userAndProfile} onSignOut={signOut} />
      
      <div className={styles.mainContent}>
        <div className={styles.profileCard}>
          <div className={styles.profileLayout}>
            <div className={styles.avatarSection}>
              <div className={styles.avatarWrapper}>
                <img
                  src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"
                  alt="Profile"
                  className={styles.avatar}
                />
              </div>
              <div className={styles.onlineStatus}>
                <div className={styles.onlineDot}></div>
                <span>Online Now</span>
              </div>
            </div>

            <div className={styles.infoSection}>
              <div className={styles.nameRow}>
                <h1>{profileData.username || profileData.email}</h1>
                <img
                  src="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMjM1IDY1MCIgeG1sbnM6dj0iaHR0cHM6Ly92ZWN0YS5pby9uYW5vIj48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMCAwaDEyMzV2NjUwSDB6Ii8+PGcgZmlsbD0iI2JmMDEwNCI+PHBhdGggZD0iTTAgMGgxMjM1djUwSDB6TTAgMTAwaDEyMzV2NTBIMHpNMCAyMDBoMTIzNXY1MEgwek0wIDMwMGgxMjM1djUwSDB6TTAgNDAwaDEyMzV2NTBIMHpNMCA1MDBoMTIzNXY1MEgwek0wIDYwMGgxMjM1djUwSDB6Ii8+PC9nPjxyZWN0IGZpbGw9IiMyODJhNWQiIHdpZHRoPSI0OTQiIGhlaWdodD0iMzUwIi8+PC9zdmc+"
                  alt="USA"
                  className={styles.flag}
                />
              </div>

              <div className={styles.statusSection}>
                {isEditingStatus ? (
                  <div className={styles.editStatus}>
                    <textarea
                      value={statusText}
                      onChange={(e) => setStatusText(e.target.value)}
                      placeholder="Enter your status..."
                      className={styles.statusInput}
                      rows={3}
                    />
                    <div className={styles.statusButtons}>
                      <button onClick={handleSaveStatus} className={styles.saveButton}>
                        Save
                      </button>
                      <button
                        onClick={() => {
                          setIsEditingStatus(false);
                          setStatusText(profileData.bio || '');
                        }}
                        className={styles.cancelButton}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                ) : (
                  <div onClick={() => setIsEditingStatus(true)} className={styles.displayStatus}>
                    <span>{profileData.bio || 'Enter a status here'}</span>
                    <svg className={styles.editIcon} viewBox="0 0 24 24">
                      <path d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                    </svg>
                  </div>
                )}
              </div>

              <div className={styles.statsGrid}>
                <StatItem
                  icon={<Calendar className={styles.icon} size={20} />}
                  label="Joined"
                  value={formatDate(profileData.created_at)}
                />
                <StatItem
                  icon={<MessageSquare className={styles.icon} size={20} />}
                  label="Debates"
                  value={storedDebates.length}
                />
                <StatItem
                  icon={<Users className={styles.icon} size={20} />}
                  label="Friends"
                  value="0"
                />
                <StatItem
                  icon={<Eye className={styles.icon} size={20} />}
                  label="Views"
                  value="0"
                />
              </div>
            </div>
          </div>
        </div>

        <div className={styles.tabsSection}>
          <div className={styles.tabsHeader}>
            <div className={styles.tabButtons}>
              <TabButton 
                active={activeTab === 'debates'} 
                onClick={() => setActiveTab('debates')}
              >
                Past Debates
              </TabButton>
              <TabButton 
                active={activeTab === 'stats'} 
                onClick={() => setActiveTab('stats')}
              >
                Stats
              </TabButton>
              <TabButton 
                active={activeTab === 'friends'} 
                onClick={() => setActiveTab('friends')}
              >
                Friends
              </TabButton>
            </div>
          </div>
          <div className={styles.tabContentWrapper}>
            <TabContent />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;