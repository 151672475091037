import React, { useState } from 'react';
import { Search } from 'lucide-react';
import styles from '../styles/SearchBar.module.css';

const SearchBar = ({ onSearch, initialValue }) => {
  const [localSearchInput, setLocalSearchInput] = useState(initialValue || '');

  const handleSubmit = () => {
    onSearch(localSearchInput);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSubmit();
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles.inputContainer}>
        <Search className={styles.searchIcon} size={20} />
        <input
          type="text"
          placeholder="Search debates..."
          value={localSearchInput}
          onChange={(e) => {
            setLocalSearchInput(e.target.value);
            onSearch(e.target.value);
          }}
          onKeyPress={handleKeyPress}
          className={styles.input}
        />
      </div>
      <button onClick={handleSubmit} className={styles.button}>
        Search
      </button>
    </div>
  );
};

export default SearchBar;