import React, { useState } from 'react';
import { MessageSquare, Send, ExternalLink } from 'lucide-react';
import { sendFeedbackMsg } from '../queries/Queries.js';
import styles from '../styles/SupportPage.module.css';
import TopMenuBar from '../components/TopMenuBar'

const SupportPage = () => {
  const [message, setMessage] = useState('');
  const [status, setStatus] = useState('');
  const [sending, setSending] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!message.trim()) return;
    
    setSending(true);
    setStatus('');
    
    try {
      const success = await sendFeedbackMsg(message);
      if (success) {
        setMessage('');
        setStatus('success');
      } else {
        setStatus('error');
      }
    } catch (error) {
      setStatus('error');
    } finally {
      setSending(false);
    }
  };

  return (
    <div>
        <TopMenuBar />
        <div className={styles.supportPage}>
        <div className={styles.container}>
            <div className={styles.headerSection}>
            <h1 className={styles.headerTitle}>We're Here to Help</h1>
            <p className={styles.headerSubtitle}>
                Get support, share feedback, or join our community
            </p>
            </div>

            <div className={styles.gridContainer}>
            {/* Contact Form */}
            <div className={styles.card}>
                <h2 className={styles.cardTitle}>
                <MessageSquare size={20} />
                Send us a message
                </h2>
                <form onSubmit={handleSubmit} className={styles.form}>
                <textarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    placeholder="Tell us how we can help, and we'll be in touch you via email from support@debatearena.app!"
                    className={styles.textarea}
                    required
                />
                <button
                    type="submit"
                    disabled={sending || !message.trim()}
                    className={styles.primaryButton}
                >
                    <Send size={16} />
                    {sending ? 'Sending...' : 'Send Message'}
                </button>
                {status === 'success' && (
                    <p className={styles.successMessage}>
                    Message sent successfully!
                    </p>
                )}
                {status === 'error' && (
                    <p className={styles.errorMessage}>
                    Failed to send message. Please try again.
                    </p>
                )}
                </form>
            </div>

            {/* Community Card */}
            <div className={styles.card}>
                <h2 className={styles.cardTitle}>Join our community</h2>
                <p className={styles.cardText}>
                Connect with other debaters, share strategies, and get help from our 
                community members on Discord.
                </p>
                <a
                href="https://discord.gg/f5uN7rkRw3"
                target="_blank"
                rel="noopener noreferrer"
                className={styles.discordButton}
                >
                <img src="https://uxwing.com/wp-content/themes/uxwing/download/brands-and-social-media/discord-white-icon.png" alt="Discord" width="24" height="24" />
                Join our Discord
                <ExternalLink size={16} />
                </a>
            </div>
            </div>

        </div>
        </div>
    </div>
  );
};

export default SupportPage;