import { createClient } from '@supabase/supabase-js'

export const supabase = createClient(
  process.env.REACT_APP_SUPABASE_URL,
  process.env.REACT_APP_SUPABASE_API_KEY,
)

let currentSession = null;

export const getUserStoredDebates = async () => {
  const sessionResult = await supabase.auth.getSession()
  const { data: { session } } = sessionResult
  if (!session) {
    // console.log("No session found")
    return null
  }

  const { user } = session

  try {
    const { data, error } = await supabase
      .from('new_stored_debates')
      .select('*')
      .eq('user_id', user.id);

    if (error) {
      console.error('Error fetching user debates:', error);
      return null;
    }

    return data;
  } catch (error) {
    console.error('Unexpected error in getUserStoredDebates:', error);
    return null;
  }
};


export const updateSessionData = async () => {
  const { data: { session } } = await supabase.auth.getSession()
  currentSession = session
  return session
}

export const getUserId = async () => {
  updateSessionData();
  return currentSession?.user?.id || null
}

export const sendFeedbackMsg = async (message) => {
  const userId = getUserId()
  const { data, error } = await supabase
    .from('feedback')
    .insert({ fdbk_msg: message })
  
  if (error) {
    console.error('Error sending feedback:', error)
    return false
  }
  return true
}

export const getDebateById = async (debateId) => {
  let userId = null;
  
  const { data: { session } } = await supabase.auth.getSession();
  if (session?.user) {
    userId = session.user.id;
  }

  try {
    const { data, error } = await supabase
      .from('new_stored_debates')
      .select('*')
      .eq('id', debateId)
      .single();

    if (error) throw error;

    // Check if the debate belongs to the current user or is public
    if (data.user_id && data.user_id !== userId) {
      throw new Error('Unauthorized access to debate');
    }

    return data;
  } catch (error) {
    console.error('Error fetching debate:', error);
    return null;
  }
};

export const uploadDebate = async (debateId, mode, messages, topic, position, role, persona, summary, examArg, sources, evaluations) => {
  let userId = null;
  
  const { data: { session } } = await supabase.auth.getSession();
  if (session?.user) {
    userId = session.user.id;
  }

  const debateData = {
    mode,
    messages,
    topic,
    position,
    role,
    persona,
    summary,
    examArg,
    sources,
    evaluations,
    user_id: userId  // This will be null for unauthenticated users
  };

  let result;
  try {
    if (debateId) {
      result = await supabase
        .from('new_stored_debates')
        .update(debateData)
        .eq('id', debateId)
        .select('id')
        .single();
    } else {
      result = await supabase
        .from('new_stored_debates')
        .insert([debateData])
        .select('id')
        .single();
    }

    if (result.error) throw result.error;
    
    return result.data.id;
  } catch (error) {
    console.error('Error sending debate:', error);
    return false;
  }
};

export const flagDebate = async (debateId, flagReason) => {
  try {
    // Get the current user's ID
    const { data: { session } } = await supabase.auth.getSession();
    const userId = session?.user?.id;

    // Update the debate with flag status and reason
    const { error } = await supabase
      .from('new_stored_debates')
      .update({ 
        flagged: true,
        flag_reason: flagReason
      })
      .eq('id', debateId);

    if (error) {
      console.error('Error flagging debate:', error);
      throw error;
    }
    return true;
  } catch (error) {
    console.error('Error in flagDebate:', error);
    return false;
  }
};

export const sendDebate = async (debateId, mode, messages, topic, position, role, persona, summary, examArg, sources, evaluations) => {
  let userId = null;
  
  const { data: { session } } = await supabase.auth.getSession();
  if (session?.user) {
    userId = session.user.id;
  }

  const debateData = {
    mode,
    messages,
    topic,
    position,
    role,
    persona,
    summary,
    examArg,
    sources,
    evaluations,
    user_id: userId  // This will be null for unauthenticated users
  };

  let result;
  try {
    if (debateId) {
      result = await supabase
        .from('new_stored_debates')
        .update(debateData)
        .eq('id', debateId)
        .select('id')
        .single();
    } else {
      result = await supabase
        .from('new_stored_debates')
        .insert([debateData])
        .select('id')
        .single();
    }

    if (result.error) throw result.error;
    
    return result.data.id;
  } catch (error) {
    console.error('Error sending debate:', error);
    return false;
  }
};

export const sendBpDebate = async (debateId, mode, messages, motion, position, summary, sources, evaluations) => {
  let userId = null;
  
  const { data: { session } } = await supabase.auth.getSession();
  if (session?.user) {
    userId = session.user.id;
  }

  const debateData = {
    mode,
    messages,
    motion,
    position,
    summary,
    sources,
    evaluations,
    user_id: userId  // This will be null for unauthenticated users
  };

  let result;
  try {
    if (debateId) {
      result = await supabase
        .from('new_stored_debates')
        .update(debateData)
        .eq('id', debateId)
        .select('id')
        .single();
    } else {
      result = await supabase
        .from('new_stored_debates')
        .insert([debateData])
        .select('id')
        .single();
    }

    if (result.error) throw result.error;
    
    return result.data.id;
  } catch (error) {
    console.error('Error sending debate:', error);
    return false;
  }
};

export const handleLogin = async (email, password) => {
  try {
    const { data: authData, error: authError } = await supabase.auth.signInWithPassword({
      email,
      password
    });

    if (authError) {
      console.error('Auth error:', authError);
      return false;
    }

    // console.log('User logged in successfully:', authData.user);

    // Fetch the user's profile data
    const { data: profileData, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', authData.user.id)
      .single();

    if (profileError) {
      console.error('Error fetching profile:', profileError.message);
      return false;
    }

    // console.log('User profile fetched:', profileData);

    // Return both auth and profile data
    return { auth: authData, profile: profileData };
  } catch (error) {
    console.error('Error in handleLogin:', error);
    return false;
  }
}

export const handleSignOut = async () => {
  try {
    // console.log('Attempting to sign out user');

    // Sign out the user using Supabase Auth
    const { error } = await supabase.auth.signOut();

    if (error) {
      throw error;
    }

    // console.log('User signed out successfully');
    return true;
  } catch (error) {
    console.error('Error signing out:', error.message);
    return false;
  }
}

export const handleSignUp = async (email, username, password) => {
  try {
    // console.log('Attempting to sign up user:', email);

    // Sign up the user using Supabase Auth
    const { data, error } = await supabase.auth.signUp({
      email,
      password,
      options: {
        data: {
          username // Store username in user metadata
        }
      }
    });

    if (error) {
      throw error;
    }

    // console.log('User signed up successfully:', data);

    // The profile should be automatically created by the trigger we set up earlier
    // But if you need to do anything extra with the profile, you can do it here

    return data;
  } catch (error) {
    console.error('Error signing up:', error.message);
    return false;
  }
}

export const getCurrentUserAndProfile = async () => {
  // Get the current session
  const { data: { session } } = await supabase.auth.getSession()

  if (!session) {
    return null
  }

  // We have a session, so we can get the user
  const { user } = session

  // Now fetch the user's profile
  // console.log(user.id)
  // console.log(getUserId())

  const { data: profile, error } = await supabase
    .from('profiles')
    .select('*')
    .eq('id', user.id)
    .single()

  if (error) {
    console.error('Error fetching profile:', error)
    return { user, profile: null }
  }

  return { user, profile }
}


export const getProfileData = async () => {
  try {
    // console.log("Starting getProfileData")

    const sessionResult = await supabase.auth.getSession()
    // console.log("Session result:", sessionResult)

    const { data: { session } } = sessionResult
    
    if (!session) {
      console.log("No session found")
      return null
    }

    const { user } = session
    // console.log("User ID from session:", user.id)
    // console.log("User ID from getUserId():", getUserId())

    // Fetch profile data
    // console.log("Fetching profile data")
    const profileResult = await supabase
      .from('profiles')
      .select('*')
      .eq('id', user.id)
      .single()
    // console.log("Profile result:", profileResult)

    if (profileResult.error) {
      console.error("Profile error:", profileResult.error)
      throw profileResult.error
    }

    // Fetch debate count
    // console.log("Fetching debate count")
    const debateResult = await supabase
      .from('stored_debates')
      .select('id', { count: 'exact' })
      .eq('user_id', user.id)
    // console.log("Debate result:", debateResult)

    if (debateResult.error) {
      console.error("Debate error:", debateResult.error)
      throw debateResult.error
    }

    const returnValue = {
      ...profileResult.data,
      email: user.email,
      debateCount: debateResult.count
    }
    // console.log("Returning:", returnValue)
    return returnValue

  } catch (error) {
    console.error('Caught error in getProfileData:', error)
    return null
  }
}

export const updateUsername = async (newUsername) => {
  try {
    const { data: { session } } = await supabase.auth.getSession()
    
    if (!session) {
      return false
    }

    const { user } = session

    const { data, error } = await supabase
      .from('profiles')
      .update({ username: newUsername })
      .eq('id', user.id)

    if (error) throw error

    return true
  } catch (error) {
    console.error('Error updating username:', error)
    return false
  }
}

export const handleGoogleSignIn = async () => {
  try {
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: `${window.location.origin}/`
      }
    });

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error signing in/up with Google:', error.message);
    return false;
  }
}

export const handleUsernameSubmit = async (userId, username) => {
  try {
    // First, let's check if the profile already exists
    const { data: existingProfile, error: profileError } = await supabase
      .from('profiles')
      .select('*')
      .eq('id', userId)
      .single();

    if (profileError && profileError.code !== 'PGRST116') {
      // PGRST116 means no rows returned, which is fine if the profile doesn't exist yet
      console.error('Error checking existing profile:', profileError);
      throw profileError;
    }

    let result;
    if (existingProfile) {
      // Update existing profile
      result = await supabase
        .from('profiles')
        .update({ username: username })
        .eq('id', userId);
    } else {
      // Insert new profile
      result = await supabase
        .from('profiles')
        .insert([{ id: userId, username: username }]);
    }

    if (result.error) {
      console.error('Error updating/inserting profile:', result.error);
      console.error('Attempted operation:', existingProfile ? 'update' : 'insert');
      console.error('User ID:', userId);
      console.error('Username:', username);
      throw result.error;
    }

    return true;
  } catch (error) {
    console.error('Error in handleUsernameSubmit:', error);
    return false;
  }
}

export const checkUserProfile = async (userId) => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('username')
      .eq('id', userId)
      .single();

    if (error) throw error;

    return data;
  } catch (error) {
    console.error('Error checking user profile:', error);
    return null;
  }
}

/** export const updateProfilePicture = async (file) => {
  try {
    const { data: { user } } = await supabase.auth.getUser();
    if (!user) throw new Error('No user found');

    // Create a unique file name that includes the user ID in the path
    const fileExt = file.name.split('.').pop();
    const fileName = `${Date.now()}.${fileExt}`;
    // Include user ID in the path
    const filePath = `${user.id}/${fileName}`;

    // Delete old avatar if exists
    const { data: profile } = await supabase
      .from('profiles')
      .select('avatar_url')
      .eq('id', user.id)
      .single();

    if (profile?.avatar_url) {
      const oldPath = profile.avatar_url.split('avatars/')[1];
      if (oldPath) {
        await supabase.storage
          .from('avatars')
          .remove([oldPath]);
      }
    }

    // Upload new avatar
    const { error: uploadError, data: uploadData } = await supabase.storage
      .from('avatars')
      .upload(filePath, file, {
        cacheControl: '3600',
        upsert: true
      });

    if (uploadError) {
      console.error('Upload error:', uploadError);
      throw uploadError;
    }

    // Get the public URL
    const { data: { publicUrl } } = supabase.storage
      .from('avatars')
      .getPublicUrl(filePath);

    // Update profile with new avatar URL
    const { error: updateError } = await supabase
      .from('profiles')
      .update({ 
        avatar_url: publicUrl,
        updated_at: new Date().toISOString()
      })
      .eq('id', user.id);

    if (updateError) {
      console.error('Profile update error:', updateError);
      throw updateError;
    }

    return true;
  } catch (error) {
    console.error('Error in updateProfilePicture:', error);
    return false;
  }
}; **/

/** const handleImageUpload = async (event) => {
  try {
    const file = event.target.files[0];
    if (!file) {
      console.log('No file selected');
      return;
    }

    console.log('File selected:', file.name, file.type, file.size);

    // Validate file type
    if (!file.type.startsWith('image/')) {
      setError('Please upload an image file');
      return;
    }

    // Validate file size (5MB)
    if (file.size > 5 * 1024 * 1024) {
      setError('Image size should be less than 5MB');
      return;
    }

    setUploading(true);
    setError('');

    console.log('Starting upload...');
    const success = await updateProfilePicture(file);
    console.log('Upload result:', success);

    if (success) {
      console.log('Refreshing profile data...');
      await fetchProfileData();
      setError('');
    } else {
      setError('Failed to upload image. Please try again.');
    }
  } catch (error) {
    console.error('Error in handleImageUpload:', error);
    setError('An error occurred while uploading the image.');
  } finally {
    setUploading(false);
  }
}; **/

export const updateProfilePicture = () => {

}

export const updateBio = async (bio) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return false;

    const { error } = await supabase
      .from('profiles')
      .update({ bio, updated_at: new Date().toISOString() })
      .eq('id', session.user.id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error updating bio:', error);
    return false;
  }
};

export const getUsernameExists = async (username) => {
  try {
    // Query the profiles table to check if username exists
    const { data, error } = await supabase
      .from('profiles')
      .select('id')
      .ilike('username', username) // Using ilike for case-insensitive comparison
      .single();

    if (error && error.code !== 'PGRST116') {
      // PGRST116 means no rows found, which is what we want to check
      console.error('Error checking username existence:', error);
      throw error;
    }

    // If data exists, the username is taken
    return !!data;

  } catch (error) {
    console.error('Error in getUsernameExists:', error);
    return false;
  }
};


export const getEmailExists = async (email) => {
  try {
    const { data, error } = await supabase
      .from('profiles')
      .select('id')
      .eq('email', email)
      .single();

    if (error && error.code !== 'PGRST116') {
      // PGRST116 means no rows found, which is what we want to check
      console.error('Error checking email existence:', error);
      throw error;
    }

    // If data exists, the email is taken
    return !!data;
  } catch (error) {
    console.error('Error in getEmailExists:', error);
    return false;
  }
};

export const searchUser = async (query) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return null;

    const currentUserId = session.user.id;

    const { data, error } = await supabase
      .from('profiles')
      .select('id, username, email, updated_at, bio, avatar_url')
      .ilike('username', `%${query}%`)
      .neq('id', currentUserId) // Exclude current user
      .limit(10);

    if (error) {
      console.error('Error searching users:', error);
      return null;
    }

    // Add default avatar for all users
    return data.map(user => ({
      ...user,
      avatar_url: "/api/placeholder/40/40" // Default profile picture
    }));
  } catch (error) {
    console.error('Error in searchUser:', error);
    return null;
  }
};

export const addFriend = async (targetUserId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;
    
    // Check if there's already a friend request or block
    const { data: existingRelation, error: checkError } = await supabase
      .from('inter_user_relations')
      .select('*')
      .or(`and(user_id_from.eq.${currentUserId},user_id_to.eq.${targetUserId}),and(user_id_from.eq.${targetUserId},user_id_to.eq.${currentUserId})`)
      .in('status', ['friend', 'blocked', 'invisible']);

    if (checkError) {
      console.error('Error checking relation:', checkError);
      return { error: 'Failed to check existing relation' };
    }

    if (existingRelation?.length > 0) {
      return { error: 'Relation already exists' };
    }

    // Create friend request
    const { error: insertError } = await supabase
      .from('inter_user_relations')
      .insert([{
        user_id_from: currentUserId,
        user_id_to: targetUserId,
        status: 'friend',
        has_been_viewed: false,
        created_at: new Date().toISOString()
      }]);

    if (insertError) {
      console.error('Error creating friend request:', insertError);
      return { error: 'Failed to send friend request' };
    }

    return { success: true };
  } catch (error) {
    console.error('Error in addFriend:', error);
    return { error: 'Unexpected error occurred' };
  }
};

export const acceptFriendRequest = async (requesterId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;

    // Create the reverse friendship entry
    const { error: acceptError } = await supabase
      .from('inter_user_relations')
      .insert([{
        user_id_from: currentUserId,
        user_id_to: requesterId,
        status: 'friend',
        has_been_viewed: true,
        created_at: new Date().toISOString()
      }]);

    if (acceptError) {
      console.error('Error accepting friend request:', acceptError);
      return { error: 'Failed to accept friend request' };
    }

    return { success: true };
  } catch (error) {
    console.error('Error in acceptFriendRequest:', error);
    return { error: 'Unexpected error occurred' };
  }
};

export const removeFriend = async (targetUserId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;

    // Delete both friendship entries
    const { error: deleteError } = await supabase
      .from('inter_user_relations')
      .delete()
      .or(`and(user_id_from.eq.${currentUserId},user_id_to.eq.${targetUserId},status.eq.friend),and(user_id_from.eq.${targetUserId},user_id_to.eq.${currentUserId},status.eq.friend)`);

    if (deleteError) {
      console.error('Error removing friend:', deleteError);
      return { error: 'Failed to remove friend' };
    }

    return { success: true };
  } catch (error) {
    console.error('Error in removeFriend:', error);
    return { error: 'Unexpected error occurred' };
  }
};

export const blockUser = async (targetUserId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;

    // First, remove any existing friend relationships
    await removeFriend(targetUserId);

    // Update or insert the block relationship
    const { error: blockError } = await supabase
      .from('inter_user_relations')
      .upsert([
        {
          user_id_from: currentUserId,
          user_id_to: targetUserId,
          status: 'blocked',
          has_been_viewed: true,
          created_at: new Date().toISOString()
        },
        {
          user_id_from: targetUserId,
          user_id_to: currentUserId,
          status: 'invisible',
          has_been_viewed: true,
          created_at: new Date().toISOString()
        }
      ]);

    if (blockError) {
      console.error('Error blocking user:', blockError);
      return { error: 'Failed to block user' };
    }

    return { success: true };
  } catch (error) {
    console.error('Error in blockUser:', error);
    return { error: 'Unexpected error occurred' };
  }
};

export const getFriendRequests = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;

    // First get users who have sent friend requests to current user
    const { data: requests, error: requestError } = await supabase
      .from('inter_user_relations')
      .select(`
        user_id_from,
        created_at,
        has_been_viewed,
        profiles!inter_user_relations_user_id_from_fkey (
          id,
          username,
          email,
          updated_at,
          bio,
          avatar_url
        )
      `)
      .eq('user_id_to', currentUserId)
      .eq('status', 'friend');

    if (requestError) {
      console.error('Error fetching friend requests:', requestError);
      return { error: 'Failed to fetch friend requests' };
    }

    // Get all users that the current user has already sent requests to
    const { data: sentRequests } = await supabase
      .from('inter_user_relations')
      .select('user_id_to')
      .eq('user_id_from', currentUserId)
      .eq('status', 'friend');

    // Filter out mutual connections
    const sentRequestIds = new Set(sentRequests?.map(req => req.user_id_to) || []);
    const pendingRequests = requests.filter(req => !sentRequestIds.has(req.user_id_from));

    // Transform the data to ensure avatar_url is set
    const transformedRequests = pendingRequests.map(request => ({
      ...request,
      profiles: {
        ...request.profiles,
        avatar_url: request.profiles.avatar_url || "/api/placeholder/40/40"
      }
    }));

    return { data: transformedRequests };
  } catch (error) {
    console.error('Error in getFriendRequests:', error);
    return { error: 'Unexpected error occurred' };
  }
};

export const getFriends = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;

    // Get all outgoing friend relationships
    const { data: outgoingFriends, error: outgoingError } = await supabase
      .from('inter_user_relations')
      .select(`
        user_id_to,
        profiles!inter_user_relations_user_id_to_fkey (
          id,
          username,
          email,
          updated_at,
          bio,
          avatar_url
        )
      `)
      .eq('user_id_from', currentUserId)
      .eq('status', 'friend');

    if (outgoingError) {
      console.error('Error fetching outgoing friends:', outgoingError);
      return { error: 'Failed to fetch friends' };
    }

    // Get all incoming friend relationships
    const { data: incomingFriends, error: incomingError } = await supabase
      .from('inter_user_relations')
      .select('user_id_from')
      .eq('user_id_to', currentUserId)
      .eq('status', 'friend');

    if (incomingError) {
      console.error('Error fetching incoming friends:', incomingError);
      return { error: 'Failed to fetch friends' };
    }

    // Find mutual friends (users who appear in both incoming and outgoing)
    const incomingIds = new Set(incomingFriends.map(f => f.user_id_from));
    const mutualFriends = outgoingFriends.filter(f => incomingIds.has(f.user_id_to));

    // Transform the data to ensure avatar_url is set and structure is correct
    const friends = mutualFriends.map(relation => ({
      ...relation.profiles,
      avatar_url: relation.profiles.avatar_url || "/api/placeholder/40/40"
    }));

    return { data: friends };
  } catch (error) {
    console.error('Error in getFriends:', error);
    return { error: 'Unexpected error occurred' };
  }
};

export const cancelFriendRequest = async (targetUserId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const currentUserId = session.user.id;

    // Delete the friend request
    const { error: deleteError } = await supabase
      .from('inter_user_relations')
      .delete()
      .eq('user_id_from', currentUserId)
      .eq('user_id_to', targetUserId)
      .eq('status', 'friend');

    if (deleteError) {
      console.error('Error canceling friend request:', deleteError);
      return { error: 'Failed to cancel friend request' };
    }

    return { success: true };
  } catch (error) {
    console.error('Error in cancelFriendRequest:', error);
    return { error: 'Unexpected error occurred' };
  }
};

// Add this to check if a friend request already exists
export const checkFriendRequestStatus = async (targetUserId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { exists: false };

    const currentUserId = session.user.id;

    const { data, error } = await supabase
      .from('inter_user_relations')
      .select('*')
      .eq('user_id_from', currentUserId)
      .eq('user_id_to', targetUserId)
      .eq('status', 'friend')
      .single();

    if (error && error.code !== 'PGRST116') { // PGRST116 means no rows found
      console.error('Error checking friend request:', error);
      return { exists: false };
    }

    return { exists: !!data };
  } catch (error) {
    console.error('Error in checkFriendRequestStatus:', error);
    return { exists: false };
  }
};

// In queries.js
export const deleteAccount = async () => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const userId = session.user.id;

    // Delete all user data in this order:
    // 1. Delete friend relationships
    await supabase
      .from('inter_user_relations')
      .delete()
      .or(`user_id_from.eq.${userId},user_id_to.eq.${userId}`);

    // 2. Delete stored debates
    await supabase
      .from('new_stored_debates')
      .delete()
      .eq('user_id', userId);

    // 3. Delete profile
    await supabase
      .from('profiles')
      .delete()
      .eq('id', userId);

    // 4. Delete user authentication
    const { error: authError } = await supabase.auth.admin.deleteUser(userId);
    if (authError) throw authError;

    // 5. Sign out the user
    await supabase.auth.signOut();

    return { success: true };
  } catch (error) {
    console.error('Error deleting account:', error);
    return { error: 'Failed to delete account' };
  }
};

export const deleteDebate = async (debateId) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const { error } = await supabase
      .from('new_stored_debates')
      .delete()
      .eq('id', debateId);

    if (error) throw error;
    return { success: true };
  } catch (error) {
    return { error: 'Failed to delete debate' };
  }
};

