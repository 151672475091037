import React, { useState, useEffect } from 'react';
import { X, Check } from 'lucide-react';
import styles from '../styles/DebateArena.module.css';
import { submitDebateReport } from '../queries/roomQueries';

const REPORT_REASONS = [
  { id: 'inappropriate', label: 'inappropriate content or hate speech' },
  { id: 'harassment', label: 'harassment or personal attacks' },
  { id: 'spam', label: 'spam or irrelevant content' },
  { id: 'cheating', label: 'using ai or external help' },
  { id: 'abandonment', label: 'abandoned debate/not participating' },
  { id: 'other', label: 'other' }
];

const modalStyles = {
  modalOverlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2000,
  },
  reportModal: {
    background: '#1a1a1a',
    padding: '1.5rem',
    borderRadius: '8px',
    width: '90%',
    maxWidth: '600px',
    position: 'relative',
  },
  reportTitle: {
    color: '#fff',
    fontSize: '1rem',
    margin: '0 0 1.25rem 0',
    textAlign: 'center',
  },
  opponentName: {
    color: '#a33',
    fontWeight: '500',
  },
  reportReasons: {
    display: 'flex',
    flexDirection: 'column',
    gap: '0.75rem',
    marginBottom: '1rem',
  },
  checkboxLabel: {
    display: 'flex',
    alignItems: 'center',
    gap: '0.75rem',
    color: '#999',
    fontSize: '0.875rem',
    cursor: 'pointer',
    padding: '0.5rem',
    borderRadius: '4px',
    transition: 'background-color 0.2s ease',
  },
  reportComment: {
    width: '100%',
    minHeight: '120px',
    background: '#111',
    border: '1px solid #333',
    borderRadius: '4px',
    padding: '0.75rem',
    color: '#fff',
    fontFamily: 'Manrope, sans-serif',
    fontSize: '0.875rem',
    marginBottom: '1rem',
    resize: 'vertical',
  },
  reportActions: {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: '0.5rem',
  },
  submitReport: {
    background: '#a33',
    color: 'white',
    border: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    fontSize: '0.875rem',
    cursor: 'pointer',
    transition: 'opacity 0.2s',
  },
  submitReportDisabled: {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
  closeButton: {
    position: 'absolute',
    top: '0.75rem',
    right: '0.75rem',
    background: 'none',
    border: 'none',
    color: '#666',
    cursor: 'pointer',
    padding: '0.25rem',
  },
  successContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '2rem',
    textAlign: 'center',
  },
  successIcon: {
    color: '#4CAF50',
    marginBottom: '1rem',
  },
  successTitle: {
    color: '#fff',
    fontSize: '1.25rem',
    margin: '0 0 0.5rem 0',
  },
  successText: {
    color: '#999',
    fontSize: '0.875rem',
    margin: 0,
  },
  errorMessage: {
    color: '#ff4444',
    backgroundColor: 'rgba(255, 68, 68, 0.1)',
    padding: '0.75rem',
    borderRadius: '4px',
    fontSize: '0.875rem',
    marginBottom: '1rem',
    textAlign: 'center',
  },
  cancelButton: {
    background: '#333',
    color: 'white',
    border: 'none',
    padding: '0.5rem 1rem',
    borderRadius: '4px',
    fontSize: '0.875rem',
    cursor: 'pointer',
  }
};

const ReportOpponentModal = ({ 
  isOpen, 
  onClose, 
  opponentName, 
  opponentId,
  debateRoomId
}) => {
  const [selectedReasons, setSelectedReasons] = useState([]);
  const [comment, setComment] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitStatus, setSubmitStatus] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isOpen) {
      setSelectedReasons([]);
      setComment('');
      setSubmitStatus(null);
      setErrorMessage('');
    }
  }, [isOpen]);

  if (!isOpen) return null;

  const handleReasonToggle = (reasonId) => {
    setSelectedReasons(prev => 
      prev.includes(reasonId)
        ? prev.filter(id => id !== reasonId)
        : [...prev, reasonId]
    );
  };

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setErrorMessage('');
      setSubmitStatus(null);

      const { data, error } = await submitDebateReport(
        debateRoomId,
        opponentId,
        selectedReasons,
        comment
      );

      if (error) {
        setSubmitStatus('error');
        setErrorMessage(error);
        return;
      }

      setSubmitStatus('success');
      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (err) {
      setSubmitStatus('error');
      setErrorMessage('An unexpected error occurred. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  if (submitStatus === 'success') {
    return (
      <div style={modalStyles.modalOverlay}>
        <div style={modalStyles.reportModal}>
          <div style={modalStyles.successContainer}>
            <Check size={48} style={modalStyles.successIcon} />
            <h3 style={modalStyles.successTitle}>Report Submitted</h3>
            <p style={modalStyles.successText}>
              Thank you for your report. We will review it shortly.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div style={modalStyles.modalOverlay} onClick={onClose}>
      <div style={modalStyles.reportModal} onClick={e => e.stopPropagation()}>
        <button style={modalStyles.closeButton} onClick={onClose}>
          <X size={20} />
        </button>
        
        <h3 style={modalStyles.reportTitle}>
          report <span style={modalStyles.opponentName}>{opponentName}</span>
        </h3>

        {submitStatus === 'error' && (
          <div style={modalStyles.errorMessage}>
            {errorMessage}
          </div>
        )}

        <div style={modalStyles.reportReasons}>
          {REPORT_REASONS.map(reason => (
            <label 
              key={reason.id} 
              style={{
                ...modalStyles.checkboxLabel,
                backgroundColor: selectedReasons.includes(reason.id) 
                  ? 'rgba(170, 51, 51, 0.1)' 
                  : 'transparent'
              }}
            >
              <input
                type="checkbox"
                checked={selectedReasons.includes(reason.id)}
                onChange={() => handleReasonToggle(reason.id)}
                className={styles["custom-checkbox"]}
              />
              <span>{reason.label}</span>
            </label>
          ))}
        </div>

        <textarea
          style={modalStyles.reportComment}
          placeholder="add any additional details..."
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          disabled={isSubmitting}
        />

        <div style={modalStyles.reportActions}>
          <button 
            style={modalStyles.cancelButton}
            onClick={onClose}
            disabled={isSubmitting}
          >
            cancel
          </button>
          <button 
            style={{
              ...modalStyles.submitReport,
              ...(selectedReasons.length === 0 || isSubmitting ? modalStyles.submitReportDisabled : {})
            }}
            onClick={handleSubmit}
            disabled={selectedReasons.length === 0 || isSubmitting}
          >
            {isSubmitting ? 'submitting...' : 'submit report'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReportOpponentModal;