import React, { useState, useEffect, useRef } from "react";
import { Loader } from "lucide-react";
import { useNavigate } from "react-router-dom";
import TopMenuBar from "../components/TopMenuBar";
import styles from "../styles/SetupDebateRoom.module.css";
import { createDebateRoom } from "../queries/roomQueries";
import { generateDebateTopic } from "../utils/api";
import { getCurrentUserAndProfile, handleSignOut } from "../queries/Queries";

const SetupDebateRoom = () => {
  const [topic, setTopic] = useState("");
  const [side, setSide] = useState("Random");
  const [isCreating, setIsCreating] = useState(false);
  const [error, setError] = useState(null);
  const [userAndProfile, setUserAndProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const textareaRef = useRef(null); 

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto"; // Reset height
      textareaRef.current.style.height = `${Math.min(
        textareaRef.current.scrollHeight,
        150
      )}px`; // Adjust height dynamically (max 3 lines)
    }
  }, [topic]); // Run this effect whenever `topic` changes

  useEffect(() => {
    const initialize = async () => {
      setIsLoading(true);
      try {
        const userResult = await getCurrentUserAndProfile();
        if (!userResult) {
          navigate("/login");
          return;
        }
        setUserAndProfile(userResult);
      } catch (err) {
        console.error("Error initializing page:", err);
        navigate("/login");
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  }, [navigate]);

  const handleSignOutClick = async () => {
    try {
      const success = await handleSignOut();
      if (success) {
        setUserAndProfile(null);
        navigate("/");
      }
    } catch (error) {
      console.error("Error signing out:", error);
      alert("Failed to sign out. Please try again.");
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const handleAutoSelectTopic = async () => {
    try {
      setError(null);
      const newTopic = await generateDebateTopic();
      // Remove quotes and capitalize the first letter
      const formattedTopic = capitalizeFirstLetter(
        newTopic.replace(/["]+/g, "").toLowerCase()
      );
      setTopic(formattedTopic);
    } catch (error) {
      console.error("Error generating topic:", error);
      setTopic("Universal basic income");
      setError("Failed to generate topic. Using default.");
    }
  };

  const handleTopicChange = (e) => {
    setError(null);
    const cursorPosition = e.target.selectionStart;
    const text = e.target.value;

    // Only capitalize if this isn't a deletion at the start of the text
    if (text.length >= topic.length || cursorPosition > 0) {
      const newText = capitalizeFirstLetter(text);
      setTopic(newText);

      // Maintain cursor position on next render
      setTimeout(() => {
        e.target.setSelectionRange(cursorPosition, cursorPosition);
      }, 0);
    } else {
      // Handle deletion at the start
      setTopic(text);
    }
  };

  const getRandomSide = () => (Math.random() < 0.5 ? "For" : "Against");

  const handleCreateRoom = async () => {
    if (!topic.trim()) {
      setError("Please enter a topic to start the debate.");
      return;
    }

    setIsCreating(true);
    setError(null);

    try {
      const finalSide = side === "Random" ? getRandomSide() : side;
      const { data, error: roomError } = await createDebateRoom(
        topic,
        finalSide
      );

      if (roomError) {
        setError(roomError);
        return;
      }

      if (data) {
        navigate(`/debate/${data.id}`);
      } else {
        setError("Failed to create room. Please try again.");
      }
    } catch (error) {
      console.error("Error creating room:", error);
      setError("An unexpected error occurred. Please try again.");
    } finally {
      setIsCreating(false);
    }
  };

  if (isLoading) {
    return (
      <div className={styles.pageContainer}>
        <TopMenuBar
          userAndProfile={userAndProfile}
          onSignOut={handleSignOutClick}
        />
        <div className={styles.loading}>Loading...</div>
      </div>
    );
  }

  return (
    <div>
      <TopMenuBar
        userAndProfile={userAndProfile}
        onSignOut={handleSignOutClick}
      />
      <div className={styles.setupPanelWrapper}>
        <div className={styles.setupPanel}>
          <div className={styles.panelContent}>
            <h2 className={styles.setupHeading}>Create Debate Room</h2>

            {error && <div className={styles.errorMessage}>{error}</div>}

            <div className={styles.section}>
          <h3>topic</h3>
          <div className={styles.topicInputContainer}>
            <textarea
              ref={textareaRef} // Attach the ref
              value={topic}
              onChange={(e) => {
                const target = e.target;
                target.style.height = "auto"; // Reset height
                target.style.height = `${Math.min(
                  target.scrollHeight,
                  150
                )}px`; // Adjust height dynamically
                setTopic(target.value.toLowerCase());
              }}
              placeholder="enter topic (a binary question / stance)"
              className={styles.textarea}
              rows={1}
            />
            <span className={styles.autoSelect} onClick={handleAutoSelectTopic}>
              auto
            </span>
          </div>
        </div>

            <hr className={styles.divider} />

            <div className={styles.section}>
              <div className={styles.formatInfo}>
                <p>Standard Format: 5 rounds of back-and-forth debate</p>
                <p>
                  Each participant will have 3 minutes per round to present
                  their arguments
                </p>
              </div>
            </div>

            <hr className={styles.divider} />

            <div className={styles.section}>
              <h3>Choose Your Side</h3>
              <div className={styles.sideButtons}>
                <button
                  className={`${styles.sideButton} ${styles.forButton} ${
                    side === "For" ? styles.active : ""
                  }`}
                  onClick={() => setSide("For")}
                  disabled={isCreating}
                >
                  for
                </button>
                <button
                  className={`${styles.sideButton} ${styles.randomButton} ${
                    side === "Random" ? styles.active : ""
                  }`}
                  onClick={() => setSide("Random")}
                  disabled={isCreating}
                >
                  random
                </button>
                <button
                  className={`${styles.sideButton} ${styles.againstButton} ${
                    side === "Against" ? styles.active : ""
                  }`}
                  onClick={() => setSide("Against")}
                  disabled={isCreating}
                >
                  against
                </button>
              </div>
            </div>

            <div className={styles.startButtonContainer}>
              <button
                className={styles.startButton}
                onClick={handleCreateRoom}
                disabled={!topic.trim() || isCreating}
              >
                {isCreating ? (
                  <>
                    <Loader className={styles.spinner} size={16} />
                    creating room...
                  </>
                ) : (
                  "create room"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupDebateRoom;
