import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Flag, MessageSquare } from "lucide-react";
import annotationPlugin from "chartjs-plugin-annotation";
import styles from "../styles/EvaluationReportCard.module.css";
import {
  calculateCumulativeScores,
  getDisplayScore,
} from "../utils/scoreUtils";
import { generateDebateSummary, generateDebateRoomSummary } from "../utils/api";
import ReactMarkdown from "react-markdown";
import { useNavigate } from "react-router-dom";
import { getCurrentUserAndProfile, flagDebate, sendFeedbackMsg } from "../queries/Queries";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);

const EvaluationReportCard = ({
  isDbRoom,
  fromStoredDebate,
  evaluations,
  topic,
  personaName,
  onClose,
  onHide,
  messages,
  userSide,
  summary,
  setSummary,
  mode,
  id,
}) => {
  const [userAndProfile, setUserAndProfile] = useState(null);
  const navigate = useNavigate();
  const [showFlagModal, setShowFlagModal] = useState(false);
  const [flagReason, setFlagReason] = useState("");
  const [showFeedbackModal, setShowFeedbackModal] = useState(false);
  const [feedbackMessage, setFeedbackMessage] = useState("");

  const handleFeedbackSubmit = async () => {
    const success = await sendFeedbackMsg(feedbackMessage);
    if (success) {
      alert("Feedback sent successfully! Thank you for your input.");
      setShowFeedbackModal(false);
      setFeedbackMessage("");
    } else {
      alert("Failed to send feedback. Please try again.");
    }
  };

  const handleFlagSubmit = async () => {
    const success = await flagDebate(id, flagReason);
    if (success) {
      alert("Debate flagged successfully! Thank you for your report.");
      setShowFlagModal(false);
    }
  };
  // room.eval can be imported, setShowEval should be directly a part of it, summary and setSummary should be in DebateArena copied from AI.js

  const handleNewDebate = () => {
    // if(userAndProfile){
    if (isDbRoom) {
      navigate("/challenge");
    } else {
      navigate("/ai");
    }
    window.location.reload();
    // }
    // else{
    //   console.log("here and fromStoredDebate: " + fromStoredDebate)
    //   fromStoredDebate ? navigate('/signup') : navigate('/ai')
    // }
  };

  useEffect(() => {
    const fetchUserAndProfile = async () => {
      const result = await getCurrentUserAndProfile();
      setUserAndProfile(result);
    };
    fetchUserAndProfile();
  }, []);

  useEffect(() => {
    if (!summary && messages.length !== 0 && userAndProfile) {
      generateSummary();
    }
  }, [userAndProfile]);

  const generateSummary = async () => {
    const roundByRoundData = evaluations
      .map((e, index) => {
        const cumulativeScores = calculateCumulativeScores(
          evaluations.slice(0, index + 1)
        );
        const roundScore = getDisplayScore(
          cumulativeScores[cumulativeScores.length - 1]
        );
        return `round ${index + 1}: ${e.side} - score: ${roundScore.toFixed(
          3
        )} - ${e.summary.toLowerCase()}`;
      })
      .join("\n");

    const debateTranscript = messages
      .filter((msg) => msg.role !== "system")
      .map((msg) => {
        // Determine the speaker identifier (use side for PvP, otherwise use role for AI)
        const speaker = (msg.side || msg.role).toUpperCase();
        return `${speaker}: ${msg.content.toLowerCase()}`;
      })
      .join("\n\n");

    try {
      let generatedSummary;
      if (!isDbRoom) {
        generatedSummary = await generateDebateSummary(
          topic.toLowerCase(),
          roundByRoundData,
          debateTranscript,
          userSide.toLowerCase()
        );
      } else {
        generatedSummary = await generateDebateRoomSummary(
          topic.toLowerCase(),
          roundByRoundData,
          debateTranscript
        );
      }
      setSummary(generatedSummary.toLowerCase());
    } catch (error) {
      console.error("Failed to generate debate summary:", error);
      setSummary("failed to generate debate summary. please try again later.");
    }
  };

  const cumulativeScores = calculateCumulativeScores(evaluations);
  const displayScores = cumulativeScores.map(getDisplayScore);

  const maxScore = Math.max(...displayScores.map(Math.abs));
  const axisMax = Math.ceil(maxScore * 1.2);

  const chartData = {
    labels: evaluations.map((_, index) => `${index + 1}`),
    datasets: [
      {
        label: "score",
        data: displayScores,
        borderColor: "#808080",
        borderWidth: 2,
        tension: 0.1,
        fill: true,
        backgroundColor: (context) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) return null;
  
          // Create gradient
          const gradient = ctx.createLinearGradient(0, chartArea.bottom, 0, chartArea.top);
          gradient.addColorStop(0.5, 'rgba(255, 69, 0, 0.2)');  // Red for negative
          gradient.addColorStop(0.5, 'rgba(76, 175, 80, 0.2)');  // Green for positive
          return gradient;
        },
        pointBackgroundColor: displayScores.map(score => 
          score > 0 ? "#4CAF50" : 
          score < 0 ? "#FF4500" : 
          "#808080"
        ),
        pointBorderColor: displayScores.map(score => 
          score > 0 ? "#4CAF50" : 
          score < 0 ? "#FF4500" : 
          "#808080"
        ),
        pointRadius: 3,
        pointHoverRadius: 4,
        pointBorderWidth: 1,
      }
    ],
  };
  
  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    scales: {
      y: {
        beginAtZero: true,
        suggestedMin: -axisMax,
        suggestedMax: axisMax,
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      },
      x: {
        ticks: {
          display: false
        },
        grid: {
          display: false
        },
        border: {
          display: false
        }
      }
    },
    plugins: {
      legend: {
        display: false,
      },
      annotation: {
        annotations: {
          zeroLine: {
            type: 'line',
            yMin: 0,
            yMax: 0,
            borderColor: 'rgba(255, 255, 255, 0.3)',
            borderWidth: 1,
            borderDash: [5, 5],
          },
          forLabel: {
            type: "label",
            yValue: axisMax,
            yAdjust: 10,
            backgroundColor: "transparent",
            content: ["for"],
            color: "#4CAF50",
          },
          againstLabel: {
            type: "label",
            yValue: -axisMax,
            yAdjust: -10,
            backgroundColor: "transparent",
            content: ["against"],
            color: "#FF4500",
          }
        }
      }
    }
  };

  return (
    <div className={styles.evaluationReportCard}>
      <div className={styles.header}>
        <button className={styles.button} onClick={onHide}>
          hide
        </button>
        <h3 className={styles.title}>
          {messages.length === 0 ? (
            <span>seems like nobody showed up!</span>
          ) : (
            <>
              <span className={styles.highlight}>
                {cumulativeScores[cumulativeScores.length - 1] === 0
                  ? ""
                  : "winner:"}
              </span>{" "}
              <span>
                {cumulativeScores[cumulativeScores.length - 1] === 0
                  ? "it was a tie!"
                  : (cumulativeScores[cumulativeScores.length - 1] > 0 &&
                      userSide.toLowerCase() === "for") ||
                    (cumulativeScores[cumulativeScores.length - 1] < 0 &&
                      userSide.toLowerCase() === "against")
                  ? userAndProfile?.profile?.username || "you"
                  : personaName || "ai"}
              </span>
            </>
          )}
        </h3>
        <button className={styles.button} onClick={onClose}>
          new debate
        </button>
      </div>

      <div className={styles.contentSection}>
        {messages.length > 0 && (
          <>
            <h4 className={styles.topic}>
              <span className={styles.highlight}>topic:</span>{" "}
              <span>{topic.toLowerCase()}</span>
            </h4>
            <div className={styles.chartContainer}>
              <Line data={chartData} options={chartOptions} />
            </div>
            <div className={styles.summaryContainer}>
              <h4>analysis report</h4>
              {userAndProfile ? (
                <ReactMarkdown
                  components={{
                    p: ({ node, ...props }) => (
                      <p className={styles.paragraph} {...props} />
                    ),
                    ul: ({ node, ...props }) => (
                      <ul className={styles.list} {...props} />
                    ),
                    li: ({ node, ...props }) => (
                      <li className={styles.listItem} {...props} />
                    ),
                  }}
                >
                  {summary}
                </ReactMarkdown>
              ) : (
                <div className={styles.signupPrompt}>
                  <button
                    onClick={() => navigate("/signup")}
                    className={styles.startButton}
                  >
                    sign up to access
                  </button>
                  <p>
                    already have an account?{" "}
                    <span
                      onClick={() => navigate("/login")}
                      className={styles.loginLink}
                    >
                      login
                    </span>
                  </p>
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <div className={styles.fixedBottom}>
        <div className={styles.actionButtons}>
          <div className={styles.actionItem}>
            <button
              className={`${styles.actionButton} ${styles.feedback}`}
              onClick={() => setShowFeedbackModal(true)}
            >
              <MessageSquare size={14} />
              give us your feedback!
            </button>
          </div>
          <div className={styles.actionItem}>
            <button
              className={`${styles.actionButton} ${styles.flag}`}
              onClick={() => setShowFlagModal(true)}
            >
              <Flag size={14} />
              flag this debate
            </button>
          </div>
        </div>
      </div>

      {showFeedbackModal && (
        <div className={styles.modalOverlay}>
          <div
            className={styles.flagModal} // Reusing the same styles as flag modal
            onClick={(e) => e.stopPropagation()}
          >
            <h3>share your feedback</h3>
            <textarea
              className={styles.flagTextarea} // Reusing styles for consistency
              value={feedbackMessage}
              onChange={(e) => setFeedbackMessage(e.target.value)}
              placeholder="tell us what you think"
              autoFocus
            />
            <div className={styles.modalButtons}>
              <button
                className={`${styles.modalButton} ${styles.cancelButton}`}
                onClick={() => {
                  setShowFeedbackModal(false);
                  setFeedbackMessage("");
                }}
              >
                Cancel
              </button>
              <button
                className={`${styles.modalButton} ${styles.submitButton}`}
                onClick={handleFeedbackSubmit}
                disabled={!feedbackMessage.trim()}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}

      {showFlagModal && (
        <div className={styles.modalOverlay}>
          <div
            className={styles.flagModal}
            onClick={(e) => e.stopPropagation()}
          >
            <h3>what's concerning about this debate?</h3>
            <textarea
              className={styles.flagTextarea}
              value={flagReason}
              onChange={(e) => setFlagReason(e.target.value)}
              placeholder="please describe your concern"
              autoFocus
            />
            <div className={styles.modalButtons}>
              <button
                className={`${styles.modalButton} ${styles.cancelButton}`}
                onClick={() => {
                  setShowFlagModal(false);
                  setFlagReason("");
                }}
              >
                cancel
              </button>
              <button
                className={`${styles.modalButton} ${styles.submitButton}`}
                onClick={handleFlagSubmit}
                disabled={!flagReason.trim()}
              >
                submit
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default EvaluationReportCard;