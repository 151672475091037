import { supabase } from "./Queries";
import { evaluateArgument } from "../utils/api";

export const fetchActiveRooms = async () => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { userRooms: [], otherRooms: [] };

    // First handle expired rooms
    await handleRoomExpiration();

    // Fetch all active rooms including profile data
    const { data: rooms, error } = await supabase
      .from("debate_rooms")
      .select(
        `
        *,
        creator:creator_id(username),
        opponent:opponent_id(username)
      `
      )
      .eq("is_active", true)
      .order("created_at", { ascending: false });

    if (error) throw error;

    const userRooms = [];
    const otherRooms = [];

    rooms.forEach((room) => {
      const transformedRoom = {
        id: room.id,
        topic: room.topic,
        createdBy: room.creator?.username || "Unknown",
        expiresIn: getTimeRemaining(room.expire_at),
        creatorSide: room.creator_side,
        status: room.status,
        isCreator: session.user.id === room.creator_id,
        opponent: room.opponent?.username || null,
        creator_id: room.creator_id,
        creator_ready: room.creator_ready,
        opponent_ready: room.opponent_ready,
        ready_phase_started: room.ready_phase_started,
        ready_deadline: room.ready_deadline,
      };

      if (
        room.creator_id === session.user.id ||
        room.opponent_id === session.user.id
      ) {
        userRooms.push(transformedRoom);
      } else if (room.status === "waiting" && !room.opponent_id) {
        otherRooms.push(transformedRoom);
      }
    });

    return { userRooms, otherRooms };
  } catch (error) {
    console.error("Error fetching active rooms:", error);
    return { error: error.message };
  }
};

export const createDebateRoom = async (topic, creatorSide) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    const expireAt = new Date();
    expireAt.setHours(expireAt.getHours() + 3);

    const { data, error } = await supabase
      .from("debate_rooms")
      .insert({
        topic,
        creator_id: session.user.id,
        creator_side: creatorSide,
        expire_at: expireAt.toISOString(),
        is_active: true,
        status: "waiting",
      })
      .select(
        `
        *,
        creator:creator_id(username),
        opponent:opponent_id(username)
      `
      )
      .single();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error("Error creating debate room:", error);
    return { error: error.message };
  }
};

export const joinDebateRoom = async (roomId) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    // First get the room with a lock
    const { data: room, error: fetchError } = await supabase
      .from("debate_rooms")
      .select("*")
      .eq("id", roomId)
      .eq("is_active", true)
      .eq("status", "waiting")
      .is("opponent_id", null)
      .single();

    if (fetchError) {
      if (fetchError.code === "PGRST116") {
        return { error: "Room is no longer available" };
      }
      throw fetchError;
    }

    // Additional validation
    if (room.creator_id === session.user.id) {
      return { error: "Cannot join your own room" };
    }

    const readyDeadline = new Date();
    readyDeadline.setMinutes(readyDeadline.getMinutes() + 2);

    // Update the room
    const { data: updatedRoom, error: updateError } = await supabase.rpc(
      "join_debate_room",
      {
        room_id: roomId,
        joiner_id: session.user.id,
        ready_deadline: readyDeadline.toISOString(),
      }
    );

    if (updateError) throw updateError;
    if (!updatedRoom) return { error: "Failed to join room" };

    return { data: updatedRoom };
  } catch (error) {
    console.error("Error joining room:", error);
    return { error: error.message || "Failed to join room" };
  }
};

export const updateReadyState = async (roomId, isCreator) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    // Single update call for ready state
    const { data, error } = await supabase
      .from("debate_rooms")
      .update(isCreator ? { creator_ready: true } : { opponent_ready: true })
      .eq("id", roomId)
      .select()
      .single();

    if (error) {
      console.error("Update error:", error);
      return { error: "Failed to update ready state" };
    }

    // Let the subscription handle the state transition
    return { data };
  } catch (error) {
    console.error("Error updating ready state:", error);
    return { error: "An unexpected error occurred" };
  }
};

export const handleRoomExpiration = async () => {
  try {
    const now = new Date().toISOString();

    const { data, error } = await supabase
      .from("debate_rooms")
      .update({
        is_active: false,
        status: "expired",
      })
      .eq("is_active", true)
      .or(`status.eq.waiting,status.eq.ready_phase`)
      .lt("expire_at", now)
      .select();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error("Error handling room expiration:", error);
    return { error: error.message };
  }
};

export const subscribeToRoomUpdates = (roomId, callback) => {
  return supabase
    .channel(`room:${roomId}`)
    .on(
      "postgres_changes",
      {
        event: "*",
        schema: "public",
        table: "debate_rooms",
        filter: `id=eq.${roomId}`,
      },
      callback
    )
    .subscribe();
};

const getTimeRemaining = (expireAt) => {
  const now = new Date();
  const expiry = new Date(expireAt);
  const diffMs = expiry - now;

  const hours = Math.floor(diffMs / (1000 * 60 * 60));
  const minutes = Math.round((diffMs % (1000 * 60 * 60)) / (1000 * 60));

  if (hours <= 0 && minutes <= 0) return "Expired";
  return `${hours}h ${minutes}m`;
};

export const joinDebateRoomWithLink = async (roomId) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    // First check if the room exists and is available
    const { data: room, error: fetchError } = await supabase
      .from("debate_rooms")
      .select("*")
      .eq("id", roomId)
      .eq("is_active", true)
      .single();

    if (fetchError) {
      if (fetchError.code === "PGRST116") {
        return { error: "Room not found" };
      }
      throw fetchError;
    }

    // Check if room already has an opponent
    if (room.opponent_id) {
      return { error: "Room is already full" };
    }

    // Check if user is trying to join their own room
    if (room.creator_id === session.user.id) {
      return { error: "Cannot join your own room" };
    }

    // Set ready deadline
    const readyDeadline = new Date();
    readyDeadline.setMinutes(readyDeadline.getMinutes() + 2);

    // Update the room with the new opponent
    const { error: updateError } = await supabase.rpc("join_debate_room", {
      room_id: roomId,
      joiner_id: session.user.id,
      ready_deadline: readyDeadline.toISOString(),
    });

    if (updateError) throw updateError;

    // Fetch the updated room with expanded user information
    const { data: updatedRoom, error: roomError } = await supabase
      .from("debate_rooms")
      .select(
        `
        *,
        creator:creator_id(username),
        opponent:opponent_id(username)
      `
      )
      .eq("id", roomId)
      .single();

    if (roomError) throw roomError;
    if (!updatedRoom) return { error: "Failed to join room" };

    return { data: updatedRoom };
  } catch (error) {
    console.error("Error joining room with link:", error);
    return { error: error.message || "Failed to join room" };
  }
};

export const updateRoomToInProgress = async (roomId) => {
  try {
    console.log("Starting debate room update...", roomId);

    const turnDeadline = new Date();
    turnDeadline.setSeconds(turnDeadline.getSeconds() + 60);

    // Single RPC call without verification
    const { data, error } = await supabase.rpc("start_debate", {
      room_id: roomId,
      turn_deadline: turnDeadline.toISOString(),
    });

    if (error) {
      console.error("Error from start_debate RPC:", error);
      throw error;
    }

    console.log("Debate start successful:", data);
    return { data };
  } catch (error) {
    console.error("Error updating room to in progress:", error);
    return { error: error.message };
  }
};

export const submitDebateReport = async (
  debateRoomId, 
  reportedUserId,
  reasons,
  comment
) => {
  try {
    const { data: { session } } = await supabase.auth.getSession();
    if (!session) return { error: 'Not authenticated' };

    const submitterId = session.user.id;

    // Create the report object with boolean flags for each reason
    const reportData = {
      debate_room_id: debateRoomId,
      submitter_id: submitterId,
      reported_user_id: reportedUserId,
      submitted_at: new Date().toISOString(),
      comment: comment,
      // Reason flags
      reason_inappropriate: reasons.includes('inappropriate'),
      reason_harassment: reasons.includes('harassment'),
      reason_spam: reasons.includes('spam'),
      reason_cheating: reasons.includes('cheating'),
      reason_abandonment: reasons.includes('abandonment'),
      reason_other: reasons.includes('other')
    };

    const { data, error } = await supabase
      .from('debate_reports')
      .insert(reportData)
      .select()
      .single();

    if (error) {
      if (error.code === '23505') { // Unique violation error code
        return { error: 'You have already submitted a report for this debate' };
      }
      throw error;
    }

    return { data };
  } catch (error) {
    console.error('Error submitting report:', error);
    return { error: error.message };
  }
};

export const submitDebateMessage = async (roomId, messageContent, nextTurnDeadline) => {
  try {
    const { data: roomData } = await supabase
      .from('debate_rooms')
      .select('messages, topic, current_turn_side, current_turn')
      .eq('id', roomId)
      .single();

    if (!roomData) throw new Error('Room not found');

    const previousMessages = roomData.messages?.slice(-10).map(msg => msg.content) || [];

    // Get evaluation
    const evaluation = await evaluateArgument(
      roomData.topic,
      'Standard',
      previousMessages,
      null,
      messageContent,
      roomData.current_turn_side
    );

    // Structure the evaluation object properly
    const evaluationObject = {
      turn: roomData.current_turn,
      side: roomData.current_turn_side,
      score: evaluation.score,
      summary: evaluation.summary,
      timestamp: new Date().toISOString()
    };

    const { data, error } = await supabase.rpc('submit_debate_message', {
      room_id: roomId,
      message_content: messageContent,
      next_turn_deadline: nextTurnDeadline,
      evaluation: evaluationObject
    });

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error('Error submitting message:', error);
    throw error;
  }
};

export const endDebateEarly = async (roomId, forfeitedById) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    // Update the room status to complete and include the forfeited_by field
    const { data, error } = await supabase
      .from("debate_rooms")
      .update({
        status: "completed",
        is_active: false,
        forfeited_by: forfeitedById // Add the ID of the user who forfeited
      })
      .eq("id", roomId)
      .select(
        `
        *,
        creator:creator_id(username),
        opponent:opponent_id(username)
      `
      )
      .single();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error("Error ending debate:", error);
    return { error: error.message };
  }
};

export const updateDebateRoomSummaryAndWinner = async (roomId, summary, winner) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    const { data, error } = await supabase
      .from("debate_rooms")
      .update({ 
        summary,
        winner  // Add winner to the update
      })
      .eq("id", roomId)
      .select()
      .single();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error("Error updating debate room summary:", error);
    return { error: error.message };
  }
};

export const deleteDebateRoom = async (roomId) => {
  try {
    const {
      data: { session },
    } = await supabase.auth.getSession();
    if (!session) return { error: "Not authenticated" };

    const { data, error } = await supabase
      .from("debate_rooms")
      .delete()
      .eq("id", roomId)
      .select()
      .single();

    if (error) throw error;
    return { data };
  } catch (error) {
    console.error("Error deleting debate room:", error);
    return { error: error.message };
  }
};



